<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="{ required: !_isReadonly ? _isMandatory : false }"
  >
    <SelectField
      :value="value"
      :is-mandatory="!_isReadonly ? _isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :error="errors[0]"
      :options="_options"
      :new-option="allowToAddNewOptions"
      :is-clearable="false"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { axiosCrypto } from "@/api/axios.js";
import { user, workspace } from "@/api/factory.js";

export default {
  name: "SingleSelectFieldWrapper",

  components: { ValidationProvider, SelectField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "0",
    },

    parentControl: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: Number,
      default: 0,
    },

    rowIndex: {
      type: Number,
      required: true,
    },

    listOptions: {
      type: Array,
      default: () => [],
    },

    filterBy: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: [],
      readonlySettings: [],
      mandatorySettings: [],
    };
  },

  computed: {
    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    _isMandatory() {
      if (this.isMandatory) {
        return true;
      } else {
        return this.field.settings.validation.fieldRule === "REQUIRED";
      }
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    allowToAddNewOptions() {
      return this.field.settings.specific.allowToAddNewOptions;
    },

    _options() {
      let options = JSON.stringify(this.options);
      let listOptions = JSON.stringify(this.listOptions);
      if (options === listOptions) {
        return this.options;
      } else if (options !== listOptions && this.listOptions.length) {
        return this.listOptions;
      }
      return this.options;
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.filterBy) {
          if (this.filterBy.length) {
            if (this.field.settings.specific.masterFormId) {
              this.getMasterEntriesFilter();
            } else if (this.field.settings.specific.repositoryId) {
              this.getRepositoryDataFilter();
            }
          }
        }
      },
    },

    field: {
      immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.filterBy.length) {
            if (this.field.settings.specific.masterFormId) {
              this.getMasterEntriesFilter(this.field.filterBy);
            } else if (this.field.settings.specific.repositoryId) {
              this.getRepositoryDataFilter(this.field.filterBy);
            }
          }
        }
      },
    },
  },

  created() {
    if (!this.field.filterBy) {
      const optionsType = this.field.settings.specific.optionsType;
      if (optionsType === "CUSTOM") {
        this.options = this.getCustomOptions();
        console.log(this.options);
      } else if (
        this.formId &&
        optionsType === "EXISTING" &&
        this.formSettings.publish.publishOption === "PUBLISHED"
      ) {
        this.getOptionFromEntries(this.field);
      } else if (optionsType === "PREDEFINED") {
        if (this.field.settings.specific.predefinedTable === "User") {
          this.getUsers();
        } else if (
          this.field.settings.specific.predefinedTable === "Workspace"
        ) {
          this.getWorkspace();
        }
      } else if (optionsType === "MASTER") {
        this.getMasterEntries(
          this.field.settings.specific.masterFormId,
          this.field.settings.specific.masterFormColumn
        );
      } else if (optionsType === "REPOSITORY") {
        this.getRepositoryData(
          this.field.settings.specific.repositoryId,
          this.field.settings.specific.repositoryField
        );
      }
    }

    this.readonlySettings = this.field.settings.validation.readonlySettings;

    if (this.readonlySettings && this.readonlySettings.length) {
      this.setReadOnly(this.value, "immediate");
    }

    this.mandatorySettings = this.field.settings.validation.mandatorySettings;
    if (this.mandatorySettings && this.mandatorySettings.length) {
      this.setMandatory(this.value, "immediate");
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
      this.setReadOnly(value);
      this.setMandatory(value);

      if (this.parentControl) {
        this.$emit("filterBy", this.field, this.rowIndex);
      }
    },

    getCustomOptions() {
      const separator =
        this.field.settings.specific.separateOptionsUsing === "NEWLINE"
          ? "\n"
          : ",";
      return this.field.settings.specific.customOptions
        .split(separator)
        .map((option) => ({
          id: this.$nano.id(),
          label: option.trim(),
          value: option.trim(),
        }));
    },

    async getOptionFromEntries(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${this.formId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.id,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getUsers() {
      const { error, payload } = await user.getUserList();
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (!payload) {
        return;
      }
      this.options = payload.map((user) => ({
        id: this.$nano.id(),
        label: user.value || user.loginName,
        value: user.value || user.loginName,
      }));
    },

    async getWorkspace() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workspace.getWorkspaceList();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.options = payload.map((workspace) => ({
        id: this.$nano.id(),
        label: workspace.value,
        value: workspace.value,
      }));
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          this.$emit("updateOptions", this.field, this.rowIndex, this.options);
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getMasterEntriesFilter(filter) {
      let filterBy = [];

      if (filter) {
        filter.forEach((item) => {
          filterBy.push({ ...item, value: item.value.toString() });
        });
      } else {
        this.filterBy.forEach((item) => {
          filterBy.push({ ...item, value: item.value.toString() });
        });
      }

      try {
        const response = await axiosCrypto.post(
          `/form/${this.field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: this.field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          //if (!this.value) {
          if (this.options.length === 1) {
            //this.$emit("input", this.options[0].value);
            this.handleInput(this.options[0].value);
          }
          this.$emit("updateOptions", this.field, this.rowIndex, this.options);
          //}
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
          this.handleInput("");
          this.$emit("updateOptions", this.field, this.rowIndex, this.options);
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getRepositoryData(repositoryId, field) {
      try {
        const response = await axiosCrypto.post(
          `/repository/${repositoryId}/uniqueColumnValues`,
          JSON.stringify({
            column: field,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          this.$emit("updateOptions", this.field, this.rowIndex, this.options);
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getRepositoryDataFilter(filter) {
      let filterBy = [];
      if (filter) {
        filter.forEach((item) => {
          filterBy.push({ ...item, value: item.value.toString() });
        });
      } else {
        this.filterBy.forEach((item) => {
          filterBy.push({ ...item, value: item.value.toString() });
        });
      }

      try {
        const response = await axiosCrypto.post(
          `/repository/${this.field.settings.specific.repositoryId}/uniqueColumnValues`,
          JSON.stringify({
            column: this.field.settings.specific.repositoryField,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          if (this.options.length === 1) {
            // this.$emit("input", this.options[0].value);
            this.handleInput(this.options[0].value);
          }
          this.$emit("updateOptions", this.field, this.rowIndex, this.options);
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
          this.handleInput("");
          this.$emit("updateOptions", this.field, this.rowIndex, this.options);
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    setReadOnly(value, immediate) {
      // console.log(this.readonlySettings, value);
      if (this.readonlySettings) {
        if (this.readonlySettings.length) {
          let controls = this.readonlySettings.find(
            (control) => control.value.trim() === value.trim()
          );
          // console.log(controls, value);
          if (controls) {
            this.$emit(
              "readonlyControls",
              controls.controls,
              this.readonlySettings,
              immediate,
              this.field.id,
              this.rowIndex
            );
          } else {
            this.$emit(
              "readonlyControls",
              [],
              this.readonlySettings,
              immediate,
              this.field.id,
              this.rowIndex
            );
          }
        }
      }
    },

    setMandatory(value, immediate) {
      // console.log(this.mandatorySettings, value);
      if (this.mandatorySettings) {
        if (this.mandatorySettings.length) {
          let controls = this.mandatorySettings.find(
            (control) => control.value.trim() === value.trim()
          );
          // console.log(controls, value);
          if (controls) {
            this.$emit(
              "mandatoryControls",
              controls.controls,
              this.mandatorySettings,
              immediate,
              this.field.id,
              this.rowIndex
            );
          } else {
            this.$emit(
              "mandatoryControls",
              [],
              this.mandatorySettings,
              immediate,
              this.field.id,
              this.rowIndex
            );
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
