const CaptureRoutes = [
  {
    path: "/capture/",
    component: () =>
      import(/* webpackChunkName: "capture" */ "@/views/capture/Capture.vue"),
    children: [
      {
        path: "overview",
        name: "capture-overview",
        meta: { menu: "capture-overview" },
        component: () =>
          import(
            /* webpackChunkName: "capture-overview" */ "@/views/capture/views/overview/CaptureOverview.vue"
          ),
      },
      {
        path: "scan-import",
        name: "capture-scan-import",
        meta: { menu: "capture-scan-import" },
        component: () =>
          import(
            /* webpackChunkName: "capture-scan-import" */ "@/views/capture/views/scan-import/CaptureScanImport.vue"
          ),
      },
      {
        path: "file-view",
        name: "capture-file-view",
        meta: { menu: "capture-file-view" },
        component: () =>
          import(
            /* webpackChunkName: "capture-file-view" */ "@/views/capture/views/file-view/CaptureFileView.vue"
          ),
      },
    ],
  },
];

export default CaptureRoutes;
