<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="validationRules"
    :class="size"
  >
    <NestedListField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      :nested-list="nestedList"
      :class="size"
      :tooltip="tooltip"
      :is-readonly="_isReadonly"
      :is-mandatory="isMandatory"
      :nested-list-field-type="nestedListFieldType"
      :nested-list-items-per-line="nestedListItemsPerLine"
      :nested-list-type-settings="nestedListTypeSettings"
      :error="errors[0]"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import NestedListField from "@/components/common/form/nested-list-field/nested-list-field/NestedListField.vue";

export default {
  name: "NestedListFieldWrapper",

  components: { ValidationProvider, NestedListField },

  props: {
    value: {
      type: Array,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this.isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      return rules;
    },

    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    nestedList() {
      return this.field.settings.specific.nestedList;
    },

    nestedListFieldType() {
      return this.field.settings.specific.nestedListFieldType;
    },

    nestedListTypeSettings() {
      return this.field.settings.specific.nestedListTypeSettings;
    },

    nestedListItemsPerLine() {
      return this.field.settings.specific.nestedListItemsPerLine;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
