<template>
  <div id="text-field">
    <div class="row">
      <div class="col">
        <span
          v-if="!openLabelInput && label"
          class="label"
          @click="handleOpenInput"
        >
          <FormFieldLabel
            v-if="label"
            :label="label"
            :is-mandatory="isMandatory"
            :tooltip="tooltip"
          />
        </span>

        <input
          v-if="openLabelInput"
          ref="focusMe"
          :value="label"
          type="text"
          autofocus
          class="label1"
          :class="!labelError ? 'q-mb-sm' : ''"
          @input="handleLabelInput"
          @mouseleave="handleFocusOutLabel(label)"
        />

        <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />
      </div>
      <div class="col-auto">
        <template v-if="qrValue && !isReadonly">
          <div
            class="cursor-pointer"
            :class="[label ? '' : 'q-mb-sm']"
            @click.stop="scanQRCode"
          >
            <BaseIcon
              v-tooltip.top="'scan QR'"
              color="secondary"
              name="mdi-qrcode-scan"
            ></BaseIcon>
          </div>
        </template>
      </div>
    </div>

    <FormFieldWrapper
      :is-focused="isFocused"
      :is-clearable="_isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      :highlight="highlight"
      @clear="handleClear"
    >
      <input
        type="text"
        :value="value"
        :autofocus="autoFocus"
        :placeholder="placeholder"
        class="q-px-sm text"
        @focus="isFocused = true"
        @blur="
          {
            isFocused = false;
            handleBlur();
          }
        "
        @input.stop="handleInput"
        @click="$emit('click')"
        @keypress.enter="$emit('enter')"
        @focusout="handleFocusout"
        @mouseleave="handleFocusout"
      />

      <template v-if="isSearchField" #search>
        <BaseSeparator is-vertical />
        <div class="search-btn" @click="updateFormSearch">
          <BaseIcon name="eva-search"></BaseIcon>
        </div>
      </template>
    </FormFieldWrapper>

    <q-menu
      v-model="optionPicker"
      :target="$refs.wrapper"
      fit
      no-focus
      no-refocus
      no-parent-event
      transition-show="scale"
      transition-hide="scale"
    >
      <OptionPicker
        :value="addressValue"
        :options="addressList"
        @select="handleSelect"
      />
    </q-menu>

    <template v-if="highlight === 'orange1'">
      <BaseButton
        label="Confirm"
        color="orange"
        class="q-mt-md"
        @click="$emit('verify')"
      />
    </template>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import OptionPicker from "@/components/common/form/select-field/OptionsPicker.vue";
import axios from "axios";

export default {
  name: "TextField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    OptionPicker,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    autoFocus: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    isSearchField: {
      type: Boolean,
      default: false,
    },

    qrValue: {
      type: Boolean,
      default: false,
    },

    isAddressField: {
      type: Boolean,
      default: false,
    },

    addressMatchingFields: {
      type: Array,
      default: () => [],
    },

    addressValue: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFocused: false,
      openLabelInput: false,
      labelError: "",
      optionPicker: false,
      addressList: [],
      // position: 0,
    };
  },

  computed: {
    _isClearable() {
      if (!this.isClearable) {
        return false;
      }

      return !!this.value;
    },
  },

  methods: {
    async handleInput(evt) {
      this.$emit("input", evt.target.value);

      if (this.isAddressField) {
        if (evt.target.value.length < 1) {
          this.addressList = [];
          return;
        }

        const endpoint = "https://photon.komoot.io/api";

        try {
          const response = await axios.get(endpoint, {
            params: {
              q: evt.target.value,
              limit: 100,
            },
          });
          const hasMatchingSelectField = this.addressMatchingFields.some(
            (item) =>
              item.selectFieldColumn && item.selectFieldColumn.length > 0
          );
          this.addressList = response.data.features.map((item) => {
            const area = item.properties.name || "";
            const city = item.properties.county || "";
            const state = item.properties.state || "";
            const country = item.properties.country || "";
            const pincode = item.properties.postcode || "";

            const label = [area, city, state, country, pincode]
              .filter(Boolean)
              .join(", ");
            if (hasMatchingSelectField) {
              return {
                id: this.$nano.id(),
                label: label,
                value: {
                  area: area,
                  city: city,
                  state: state,
                  country: country,
                  pincode: pincode,
                },
              };
            } else {
              return {
                id: this.$nano.id(),
                label: label,
                value: label,
              };
            }
          });

          if (this.addressList.length) {
            this.optionPicker = true;
          }
        } catch (error) {
          console.error(
            "Error fetching addresses:",
            error.response ? error.response.data : error.message
          );
        }
      }
    },

    handleClear() {
      this.$emit("input", "");
    },

    handleFocusOutLabel(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    handleFocusout(evt) {
      this.$emit("focusOut", evt.target.value);
    },

    handleBlur() {
      this.$emit("onBlur", this.value);
    },

    updateFormSearch() {
      this.$emit("updateFormSearch", this.value);
    },

    scanQRCode() {
      this.$emit("scanQRCode");
    },

    handleSelect(value) {
      this.$emit("input", "", value);
      this.optionPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#text-field {
  input.text {
    min-height: 46px;
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }

  .search-btn {
    background-color: var(--body-bg-color);
    cursor: pointer;
    padding: 12px;
  }
}
</style>
