<template>
  <div id="options-picker">
    <BaseScrollbar height="256px" class="q-py-sm">
      <template v-if="options.length">
        <div
          v-if="selectFilter"
          class="option selectAll"
          :class="{ 'is-selected': isSelected('Select Listed Fields') }"
          @click="handleSelectAllFilter('Select Listed Fields')"
        >
          <BaseIcon
            :name="icon('Select Listed Fields')"
            inherit-color
            class="icon"
          />

          <div class="label">Select Listed Fields</div>
        </div>
        <div
          v-for="option in options"
          :key="option.id"
          class="option"
          :class="{
            'is-selected': isSelected(option.value),
            'is-readonly': option.readonly,
          }"
          @click="handleClick(option.value)"
        >
          <!-- 
         :class="{
            'is-selected': isSelected(option.value),
          }"'is-disabled': option.disabled, -->
          <BaseIcon :name="icon(option.value)" inherit-color class="icon" />

          <BaseIcon
            v-if="option.icon"
            :name="option.icon"
            :color="option.color"
            class="icon"
          />

          <div class="label">{{ option.label }}</div>
        </div>
      </template>

      <div v-else class="no-option">
        <div class="q-mb-sm">No options found</div>
        <div v-if="newOption">Press "Enter" to add new option</div>
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
export default {
  name: "OptionsPicker",

  props: {
    value: {
      type: [String, Number, Array],
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    isMultiple: {
      type: Boolean,
      default: false,
    },

    newOption: {
      type: Boolean,
      default: false,
    },

    selectFilter: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    isSelected(optionValue) {
      return this.isMultiple
        ? !!this.value.find((_option) => _option === optionValue)
        : this.value === optionValue;
    },

    icon(optionValue) {
      const isSelected = this.isSelected(optionValue);

      if (this.isMultiple) {
        return isSelected
          ? "mdi-checkbox-marked"
          : "mdi-checkbox-blank-outline";
      } else {
        return isSelected ? "eva-radio-button-on" : "eva-radio-button-off";
      }
    },

    handleClick(optionValue) {
      this.$emit("select", optionValue);
    },

    handleSelectAllFilter() {
      this.$emit("selectAllFilter");
    },
  },
};
</script>

<style lang="scss" scoped>
#options-picker {
  .option {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    transition: background-color 0.25s ease;

    .icon,
    .label {
      transition: all 0.25s ease;
    }

    .icon {
      color: var(--icon-color-inverted);
      margin-right: 16px;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--hover-bg-color);

      .icon,
      .label {
        color: var(--secondary);
      }
    }

    &.is-selected {
      .icon,
      .label {
        color: var(--secondary);
      }

      .label {
        font-weight: 500;
      }
    }

    &.is-readonly {
      cursor: not-allowed;
    }
  }

  .no-option {
    margin: 16px 16px 8px;
    text-align: center;
  }

  .selectAll {
    border-bottom: 1px solid var(--primary);
  }
}
</style>
