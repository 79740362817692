<template>
  <div id="country-code">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <FormFieldWrapper
      ref="wrapper"
      :is-focused="_isFocused"
      :is-clearable="_isClearable && !isDropdownOpen"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      :hide-border="hideBorder"
      :highlight="highlight"
      @clear="handleClear"
    >
      <template #default>
        <!-- Selected Item -->
        <div class="dropdown-selected row" @click="showDropdown">
          <template v-if="!searching">
            <span
              v-if="selectedCountryCode"
              :class="`fi fi-${selectedCountryCode.toLowerCase()}`"
            ></span
            ><span v-if="selectedCountryCode" class="col q-ml-sm">
              {{ "+" + callingCode }}</span
            ><span v-else class="col">
              <input type="text" :placeholder="_placeholder" class="col" />
            </span>
          </template>
          <template v-else>
            <input
              ref="search"
              v-model="searchQuery"
              type="text"
              :placeholder="_placeholder"
              class="col"
              @input="handleSearch"
            />
          </template>
          <BaseIcon
            class="dropdown-icon col-auto q-pr-sm"
            name="eva-chevron-down"
          />
        </div>

        <!-- Dropdown Menu -->
        <div v-if="isDropdownOpen" ref="dropdownMenu" class="dropdown-menu">
          <BaseScrollbar height="256px" class="q-py-sm" @scroll="handleScroll">
            <div
              v-for="country in filteredCountries"
              :key="country.alpha2Code"
              class="dropdown-item"
              @click="selectCountry(country)"
            >
              <span :class="`fi fi-${country.alpha2Code.toLowerCase()}`"></span>
              {{ country.name }} {{ "(" + country.callingCode + ")" }}
            </div>

            <!-- Option to Add New Country -->
            <div
              v-if="searchQuery && !filteredCountries.length"
              class="dropdown-item no-results"
            >
              No results for "{{ searchQuery }}"
            </div>
          </BaseScrollbar>
        </div>
      </template>
    </FormFieldWrapper>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import { debounce } from "lodash-es";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
export default {
  name: "CountryCodeField",

  components: { FormFieldLabel, FormFieldWrapper, FormFieldError },

  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    actionIcon: {
      type: String,
      default: "",
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    error: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    hideBorder: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    code: {
      type: [String, Number],
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isFocused: false,
      isDropdownOpen: false,
      selectedCountryCode: "",
      selectedCountryName: "",
      searchQuery: "",
      countries: [],
      callingCode: 0,
      searching: false,
      openLabelInput: false,
      labelError: "",
      dropdownEvent: false,
    };
  },

  computed: {
    _placeholder() {
      if (this.value) {
        return "";
      }

      return this.placeholder || "Select";
    },

    filteredCountries() {
      if (!this.searchQuery) {
        return this.countries;
      }
      return this.countries.filter(
        (country) =>
          country.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          country.callingCode
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
      );
    },

    _isFocused() {
      return this.isFocused || this.isDropdownOpen;
    },

    _isClearable() {
      if (!this.isClearable) {
        return false;
      }

      return !!this.value;
    },
  },
  watch: {
    code: {
      immediate: true,
      async handler() {
        if (this.code) {
          try {
            const response = await fetch("https://restcountries.com/v2/all");
            const data = await response.json();
            this.countries = data.map((country) => ({
              name: country.name,
              alpha2Code: country.alpha2Code,
              callingCode: country.callingCodes
                ? country.callingCodes[0]
                : "N/A",
            }));
          } catch (error) {
            console.error("Error fetching country data:", error);
          }
          //console.log(this.countries, "dates");
          let filterData = this.countries.filter(
            (item) => item.callingCode === String(this.code)
          );
          //console.log(filterData, "dates");
          if (filterData) {
            this.selectedCountryCode = filterData[0].alpha2Code;
            this.selectedCountryName = filterData[0].name;
            this.callingCode = filterData[0].callingCode;
          }
        } else {
          this.selectedCountryCode = "";
          this.selectedCountryName = "";
          this.callingCode = 0;
        }
      },
    },
    selectedCountryCode: {
      immediate: true,
      async handler() {
        if (this.selectedCountryCode !== " ") {
          this.searching = false;
          this.searchQuery = "";
        }
      },
    },
  },

  async created() {
    try {
      const response = await fetch("https://restcountries.com/v2/all");
      const data = await response.json();
      this.countries = data.map((country) => ({
        name: country.name,
        alpha2Code: country.alpha2Code,
        callingCode: country.callingCodes ? country.callingCodes[0] : "N/A",
      }));
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  },

  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },

  methods: {
    showDropdown() {
      this.searching = true;
      this.isDropdownOpen = true;
      this.dropdownEvent = true;
    },
    selectCountry(country) {
      this.searching = false;
      this.searchQuery = "";
      this.selectedCountryCode = country.alpha2Code;
      this.selectedCountryName = country.name;
      this.isDropdownOpen = false;
      this.callingCode = country.callingCode;
      this.$emit("input", this.callingCode);
    },
    handleSearch() {
      // The computed property `filteredCountries` will react to changes in `searchQuery`
    },
    handleScroll: debounce(function (e) {
      const scrollHeight = e.target.scrollHeight;
      const scrollTop = e.target.scrollTop;
      const clientHeight = e.target.clientHeight;

      const hasReachedBottom = scrollHeight - scrollTop === clientHeight;

      if (hasReachedBottom) {
        this.$emit("loadMore");
      }
    }, 300),

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    handleClear() {
      this.$emit("input", "");
    },
    handleOutsideClick(event) {
      const dropdown = this.$refs.dropdownMenu;
      const search = this.$refs.search;
      if (!this.dropdownEvent) {
        if (search && dropdown) {
          if (
            !search.contains(event.target) &&
            !dropdown.contains(event.target)
          ) {
            this.searching = false;
            this.isDropdownOpen = false;
          }
        }
      } else {
        this.dropdownEvent = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~flag-icons/css/flag-icons.min.css";

#country-code {
  .dropdown-selected {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 46px;
    border-radius: 4px;
    background-color: #fff;
    padding: 10px 0px 10px 10px;
  }

  .dropdown-icon {
    margin-left: auto;
  }

  .dropdown-menu {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }

  .dropdown-items {
    overflow-y: auto;
  }

  .search-input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 5px;
    box-sizing: border-box;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #f0f0f0;
  }

  .dropdown-item span {
    margin-right: 8px;
    font-size: 1.5em;
  }

  .no-results {
    font-style: italic;
    color: #999;
    padding: 10px;
  }
  .ellipsis {
    display: inline-block;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    position: absolute;
    background: white;
    z-index: 1;
  }
  .required {
    color: red;
    margin-left: 3px;
  }
}
</style>
