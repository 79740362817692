import axios from "axios";
import store from "../store/index.js";
import { encrypt, decrypt } from "../helpers/crypto";

// axios instance w/o encryption/decryption

const _axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

// axios instance with encryption/decryption

const axiosCrypto = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

// intercept the request to set token, to encrypt the data
// and to log the request

axiosCrypto.interceptors.request.use(
  (config) => {
    const request = {
      type: "Request",
      url: config.baseURL + config.url,
      method: config.method,
      contentType: config.headers["Content-Type"],
      payload: config.data,
    };

    //config.headers.Authorization = store.state.identity.token;
    config.headers.Token = store.state.identity.token;
    config.data = encrypt(
      config.data,
      store.state.identity.key,
      store.state.identity.iv
    );

    //request.token = config.headers.Authorization;
    request.token = config.headers.Token;
    request.encryptedPayload = config.data;
    if (config.url !== "/other/notification/all") {
      console.table(request);
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// intercept the response to decrypt the data
// and to log the response

axiosCrypto.interceptors.response.use(
  (config) => {
    const response = {
      type: "Response",
      url: config.config.baseURL + config.config.url,
      data: config.data,
      status: config.status,
    };

    config.data = decrypt(
      config.data,
      store.state.identity.key,
      store.state.identity.iv
    );

    response.decryptedData = config.data;
    if (config.config.url !== "/other/notification/all") {
      console.table(response);
    }

    return config;
  },
  (error) => {
    const data = decrypt(
      error.response?.data,
      store.state.identity.key,
      store.state.identity.iv
    );
    console.log(data);
    error.response.data = data;
    return error;
  }
);

export { _axios as axios, axiosCrypto };
