<template>
  <button
    id="button"
    v-ripple.white
    :disabled="isLoading || disabled"
    :class="[_class, { hover: isFlat, 'shadow-2': !isFlat }]"
    v-on="$listeners"
  >
    <q-spinner v-if="isLoading" color="white" size="24px" class="q-mx-lg" />

    <template v-else>
      <!-- icon -->

      <BaseIcon v-if="icon" :name="icon" inherit-color class="q-mr-sm" />

      <!-- ... -->

      <!-- label -->

      <div class="label" :class="transform">{{ label }}</div>

      <!-- ... -->

      <!-- slot -->

      <slot name="default"></slot>

      <!-- ... -->
    </template>
  </button>
</template>

<script>
import { mapState } from "vuex";

import colorValidator from "@/helpers/color-validator.js";

export default {
  name: "BaseButton",

  props: {
    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: "",
    },

    color: {
      type: String,
      default: "primary",
      validator: colorValidator,
    },

    isFlat: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  computed: {
    ...mapState(["darkTheme"]),

    _class() {
      if (this.isFlat) {
        return this.darkTheme ? `text-${this.color}-5` : `text-${this.color}`;
      }

      return this.darkTheme
        ? `text-white bg-${this.color}-5`
        : `text-white bg-${this.color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
#button {
  border: none;
  outline: none;
  min-height: 36px;
  max-height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  // position: relative;
  background-color: transparent;

  .label {
    font-weight: 600;
    &.transform {
      text-transform: capitalize;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.hover:hover {
    background-color: var(--hover-bg-color);
  }
}
</style>
