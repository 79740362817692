<template>
  <div id="or-divider">
    <div class="left"></div>
    <div class="label">{{ label }}</div>
    <div class="right"></div>
  </div>
</template>

<script>
export default {
  name: "OrDivider",

  props: {
    label: {
      type: String,
      default: "( OR )",
    },
  },
};
</script>

<style lang="scss" scoped>
#or-divider {
  margin: 16px 0px;
  position: relative;
  display: flex;
  align-items: center;

  .left,
  .right {
    flex: 1;
    border-bottom: 1px dashed var(--border-color);
  }

  .label {
    @extend .text-sm;
    color: var(--icon-color-inverted);
    padding: 0px 16px;
  }
}
</style>
