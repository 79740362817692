import CryptoJs from "crypto-js";

/**
 * Function to test encryption & decryption
 *
 * @param data
 * @param key
 * @param iv
 * @param type
 *
 * @returns data
 */
// const crypto = require("crypto");
export const cryptoSanityTest = (data, key, iv, type = "OBJECT") => {
  const _data = type === "OBJECT" ? JSON.stringify(data) : data;
  const _key = CryptoJs.enc.Base64.parse(key);
  const _iv = CryptoJs.enc.Base64.parse(iv);

  const encrypted = CryptoJs.AES.encrypt(_data, _key, {
    iv: _iv,
  }).toString();

  const decrypted = CryptoJs.AES.decrypt(encrypted, _key, {
    iv: _iv,
  }).toString(CryptoJs.enc.Utf8);

  return type === "OBJECT" ? JSON.parse(decrypted) : decrypted;
};

/**
 * Function to encrypt data
 *
 * @param data
 * @param key
 * @param iv
 *
 * @returns encrypted data
 */

export const encrypt = (data, key, iv) => {
  const _key = CryptoJs.enc.Base64.parse(key);
  const _iv = CryptoJs.enc.Base64.parse(iv);

  const encrypted = CryptoJs.AES.encrypt(data, _key, {
    iv: _iv,
  }).toString();
  return encrypted;
};

/**
 * Function to decrypt data
 *
 * @param data
 * @param key
 * @param iv
 *
 * @returns decrypted data
 */

export const decrypt = (data, key, iv) => {
  const _key = CryptoJs.enc.Base64.parse(key);
  const _iv = CryptoJs.enc.Base64.parse(iv);

  const decrypted = CryptoJs.AES.decrypt(data, _key, {
    iv: _iv,
  }).toString(CryptoJs.enc.Utf8);

  // let data1 = "PbIV8LNvc/sJqYQA56e6";
  // const _key1 = CryptoJs.enc.Base64.parse(
  //   "KwE4M/8S4w3JIGQFkVkxI9eL2Gc6BWEWZX/WHLo+9+E="
  // );
  // const _iv1 = CryptoJs.enc.Base64.parse("jInyLkQns0YdsXIke8uy7g==");
  // const decrypted1 = CryptoJs.AES.decrypt(data1, _key1, {
  //   iv: _iv1,
  // }).toString(CryptoJs.enc.Utf8);
  // console.log(decrypted1);

  // const decipher = crypto.createDecipheriv(
  //   "aes-256-cbc",
  //   "0xC333543511870E74E47B1450FED737BD1ECF54833CAC06F6469EBDCB67D988D9",
  //   "0x941780E7937604AB25FA32592775DEDB"
  // );
  // const decryptedResponseStream = data1.pipe(decipher);
  // let decryptedData = decipher.update(data1, "base64", "utf8");
  // decryptedData += decipher.final("utf8");
  // console.log("decrypt:" + decryptedData);

  // decryptedResponseStream.on("data", (chunk) => {
  //   console.log(chunk);
  // });

  return decrypted;
};
