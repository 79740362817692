<template>
  <div>
    <PopupField
      :value="value"
      :secondary-panels="secondaryPanels"
      :is-readonly="isReadonly"
      :popup-trigger-type="field.settings.specific.popupTriggerType"
      :secondary-panel="field.settings.specific.secondaryPanel"
      :form-settings="formSettings"
      :mapped-field-id="field.settings.specific.mappedFieldId"
      :mapped-column-id="field.settings.specific.mappedColumnId"
      @input="handleInput"
    />
  </div>
</template>

<script>
import PopupField from "@/components/common/form/popup-field/PopupField.vue";

export default {
  name: "PopupFieldWrapper",

  components: { PopupField },

  props: {
    value: {
      type: Object,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
