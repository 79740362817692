var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({class:{
    'is-readonly': _vm.isReadonly,
    'is-disabled': _vm.isDisabled,
    'hide-border': _vm.hideBorder,
    'is-focused': _vm.isFocused,
    'has-error': _vm.hasError,
  },style:(_vm.highlight === 'orange' || _vm.highlight === 'red'
      ? [
          { 'border-left': '3px solid ' + _vm.highlight },
          { width: '100%' },
          { float: 'left' },
          { 'margin-right': '5px' },
        ]
      : _vm.highlight === 'green'
      ? [{ 'border-left': '3px solid ' + _vm.highlight }]
      : ''),attrs:{"id":"field-wrapper"}},_vm.$listeners),[_c('div',{attrs:{"id":"field"}},[_vm._t("default")],2),_vm._t("action"),(_vm.actionIcon)?_c('ActionButton',{attrs:{"icon":_vm.actionIcon},on:{"click":function($event){return _vm.$emit('action')}}}):_vm._e(),(_vm._isClearable)?_c('ClearButton',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('clear')}}}):_vm._e(),_vm._t("search")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }