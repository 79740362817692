export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(btoa(reader.result));
    reader.onerror = () => reject("error converting file");

    reader.readAsBinaryString(file);
  });
}
