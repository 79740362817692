<template>
  <div id="nested-list">
    <div class="row">
      <!-- breadcrumbs -->

      <Breadcrumbs
        :breadcrumbs="breadcrumbs"
        class="col"
        @click="handleBreadcrumbClick"
        @reset="resetList"
      />

      <!-- ... -->

      <div class="toggle-entries" @click="toggleEntries">
        {{ showEntries ? "Hide" : "Show" }} Entries
      </div>
    </div>

    <!-- nested list -->

    <div v-if="drilledList.length" class="row q-col-gutter-sm">
      <div
        v-for="item in drilledList"
        :key="item.id"
        :class="itemsPerLine"
        @click="drillDown(item)"
      >
        <div class="nested-list-item">
          {{ item.label }}
        </div>
      </div>
    </div>

    <!-- ... -->

    <!-- field -->

    <div v-else>
      <ValidationObserver ref="nestedList">
        <component
          :is="field"
          v-model="fieldModel"
          :field="nestedListTypeSettings"
        />
      </ValidationObserver>

      <div class="row justify-end q-mt-lg">
        <BaseButton label="add" color="secondary" @click="addEntry" />
      </div>
    </div>

    <!-- ... -->
  </div>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import { isEmpty } from "lodash-es";
import { ValidationObserver } from "vee-validate";
import ShortTextFieldWrapper from "@/components/common/render-form/components/fields/ShortTextFieldWrapper.vue";
import NumberFieldWrapper from "@/components/common/render-form/components/fields/NumberFieldWrapper.vue";
import CounterFieldWrapper from "@/components/common/render-form/components/fields/CounterFieldWrapper.vue";
import DateFieldWrapper from "@/components/common/render-form/components/fields/DateFieldWrapper.vue";
import TimeFieldWrapper from "@/components/common/render-form/components/fields/TimeFieldWrapper.vue";
import DateTimeFieldWrapper from "@/components/common/render-form/components/fields/DateTimeFieldWrapper.vue";

export default {
  name: "NestedList",

  components: {
    Breadcrumbs,
    ValidationObserver,
    ShortTextFieldWrapper,
    NumberFieldWrapper,
    CounterFieldWrapper,
    DateFieldWrapper,
    TimeFieldWrapper,
    DateTimeFieldWrapper,
  },

  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },

    nestedList: {
      type: Array,
      default: () => [],
    },

    nestedListItemsPerLine: {
      type: Array,
      default: () => [],
    },

    nestedListFieldType: {
      type: String,
      default: "SHORT_TEXT",
    },

    nestedListTypeSettings: {
      type: Object,
      required: true,
    },

    showEntries: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      breadcrumbs: [],
      activeItem: "",
      drilledList: [],
      fields: {
        SHORT_TEXT: "ShortTextFieldWrapper",
        NUMBER: "NumberFieldWrapper",
        COUNTER: "CounterFieldWrapper",
        DATE: "DateFieldWrapper",
        TIME: "TimeFieldWrapper",
        DATE_TIME: "DateTimeFieldWrapper",
      },
      fieldModel: "",
    };
  },

  computed: {
    itemsPerLine() {
      if (isEmpty(this.drilledList) || isEmpty(this.nestedListItemsPerLine)) {
        return "col";
      }

      const activeLevel = this.drilledList[0].level;
      const itemsPerLine = this.nestedListItemsPerLine.find(
        (level) => level.value === activeLevel
      ).itemsPerLine;

      if (!itemsPerLine) {
        return "col";
      }

      return itemsPerLine;
    },

    field() {
      return this.fields[this.nestedListFieldType];
    },
  },

  watch: {
    nestedList: {
      immediate: true,
      deep: true,
      handler() {
        this.drilledList = this.nestedList;
      },
    },
  },

  created() {
    this.setFieldModel();
  },

  methods: {
    setFieldModel() {
      const defaultValue =
        this.nestedListTypeSettings?.settings.specific.customDefaultValue;

      this.fieldModel = defaultValue
        ? defaultValue
        : this.nestedListFieldType === "COUNTER"
        ? 0
        : "";
    },

    getChildren(items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === this.activeItem.id) {
          return items[i].children;
        }

        const _items = this.getChildren(items[i].children);
        if (_items) {
          return _items;
        }
      }
    },

    getSiblings(items) {
      if (items.find((item) => item.id === this.activeItem.id)) {
        return items;
      }

      for (let i = 0; i < items.length; i++) {
        const _items = this.getSiblings(items[i].children);
        if (_items) {
          return _items;
        }
      }
    },

    getParent(items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].children.find((item) => item.id === this.activeItem.id)) {
          return items[i];
        }

        const _item = this.getParent(items[i].children);
        if (_item) {
          return _item;
        }
      }

      return "";
    },

    resetList() {
      this.drilledList = this.nestedList;
      this.activeItem = "";
      this.breadcrumbs = [];
      this.setFieldModel();
    },

    drillDown(item) {
      this.breadcrumbs.push(item);
      this.activeItem = item;
      this.drilledList = this.getChildren(this.nestedList);
    },

    handleBreadcrumbClick(breadcrumb) {
      const breadcrumbIdx = this.breadcrumbs.findIndex(
        (_breadcrumb) => _breadcrumb.id === breadcrumb.id
      );

      this.breadcrumbs.splice(breadcrumbIdx + 1);
      this.activeItem = breadcrumb;
      this.drilledList = this.getChildren(this.nestedList);
    },

    async addEntry() {
      const isValid = await this.$refs.nestedList.validate();

      if (!isValid) {
        return;
      }

      const label = this.breadcrumbs.reduce((acc, cur) => {
        if (!acc) return acc + cur.label;
        else return `${acc} → ${cur.label}`;
      }, "");

      this.$emit("addEntry", {
        id: this.$nano.id(),
        label: label,
        value: this.fieldModel,
      });

      this.resetList();
    },

    toggleEntries() {
      this.$emit("toggleEntries", !this.showEntries);
    },
  },
};
</script>

<style lang="scss" scoped>
#nested-list {
  .nested-list-item {
    border-radius: 4px;
    padding: 16px;
    font-weight: 500;
    text-transform: capitalize;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    user-select: none;
    height: 100%;

    &:hover {
      cursor: pointer;
      color: white;
      background-color: var(--secondary);
    }
  }

  .back-btn {
    display: flex;
    align-items: center;
    margin-left: -4px;
    margin-bottom: 16px;
    user-select: none;

    .icon {
      color: var(--icon-color);
    }

    &:hover {
      cursor: pointer;
      color: var(--secondary);

      .icon {
        color: currentColor;
      }
    }
  }

  .toggle-entries {
    font-weight: 500;
    text-decoration: underline;
    color: var(--icon-color);

    &:hover {
      cursor: pointer;
      color: var(--secondary);
    }
  }
}
</style>

<style lang="scss">
.theme-light {
  .nested-list-item {
    background-color: rgba(0, 188, 212, 0.15);
  }
}

.theme-dark {
  .nested-list-item {
    background-color: rgba(38, 197, 218, 0.15);
  }
}
</style>
