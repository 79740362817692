import { axiosCrypto } from "./axios";

export default {
  async getRepository(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/repository/${payload}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching repository";
    }

    return _response;
  },

  async getRepositories(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/repository/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching repositories";
    }

    return _response;
  },

  async getRepositoryList(criteria = "", value = "") {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/repository/list",
        JSON.stringify({ criteria, value })
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching repositories";
    }

    return _response;
  },

  async getUniqueColumnValues(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/repository/uniqueColumnValues",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching data";
    }

    return _response;
  },

  async createRepository(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/repository",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);

      if (e.response?.status === 406) {
        _response.error = "repository with the given name already exists";
      } else {
        _response.error = "error creating repository";
      }
    }

    return _response;
  },

  async updateRepository(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/repository/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);

      if (e.response?.status === 404) {
        _response.error = "repository with the given id is not found";
      } else if (e.response?.status === 406) {
        _response.error = "repository with the given name already exists";
      } else {
        _response.error = "error updating repository";
      }
    }

    return _response;
  },

  async getRepositoryHistory(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/repository/history/${payload}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching repository history";
    }

    return _response;
  },

  async getEntities(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/file/browse",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }
      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching folders/files";
    }

    return _response;
  },

  async getRepositoryField(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/repository/controlList",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching repositories fields";
    }

    return _response;
  },

  async getFileData(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/file/indexValues",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching file data";
    }

    return _response;
  },

  async getDynamicFields() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post("/repository/dynamicFields");
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching dynamic fields";
    }

    return _response;
  },

  async fileDelete(repositoryId, id) {
    const _response = {
      payload: "",
      error: "",
    };

    let fileIds = [];
    if (Array.isArray(id)) {
      id.forEach((fileId) => {
        fileIds.push(fileId);
      });
    } else {
      fileIds.push(id);
    }

    let input = {
      ids: fileIds,
    };

    try {
      const response = await axiosCrypto.post(
        `/menu/file/delete/${repositoryId}/1`,
        JSON.stringify(input)
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      _response.error = "error deleting file";
    }

    return _response;
  },

  async getFileComments(repositoryId, itemId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/file/comments/${repositoryId}/${itemId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }
      if (data) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching file comments";
    }

    return _response;
  },

  async insertFileComments(repositoryId, itemId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/file/comments/${repositoryId}/${itemId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error saving file comments";
    }

    return _response;
  },

  async getOcrCredit(id) {
    const _response = {
      payload: "",
      error: "",
    };
    try {
      const response = await axiosCrypto.get(`/tenant/OCRcredit/${id}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching OCR Credit";
    }

    return _response;
  },

  async setViewColumns(payload) {
    const _response = {
      payload: "",
      error: "",
    };
    try {
      const response = await axiosCrypto.post(
        "/uploadAndIndex/setFieldList",
        JSON.stringify(payload)
      );
      const { status, data } = response;
      if (status !== 202) {
        throw response;
      }
      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching data";
    }
    return _response;
  },

  async getRepositorySecurity(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/security/repositorySecurity/${id}`
      );
      const { status, data } = response;

      if (status === 204) {
        _response.payload = null;
      } else if (status === 200) {
        _response.payload = JSON.parse(data);
      } else {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching folder security";
    }

    return _response;
  },

  async insertRepositorySecurity(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/security/repositorySecurity",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error inserting folder security";
    }

    return _response;
  },

  async updateRepositorySecurity(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/security/repositorySecurity/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
      // _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error updating folder security";
    }

    return _response;
  },

  async deleteRepositorySecurity(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.delete(
        `/security/repositorySecurity/${id}`
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
      // _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error deleting folder security";
    }

    return _response;
  },

  async getDocumentSecurity(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/security/documentSecurity/${id}`
      );
      const { status, data } = response;

      if (status === 204) {
        _response.payload = null;
      } else if (status === 200) {
        _response.payload = JSON.parse(data);
      } else {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching document security";
    }

    return _response;
  },

  async insertDocumentSecurity(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/security/documentSecurity",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
      // _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error inserting document security";
    }

    return _response;
  },

  async updateDocumentSecurity(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/security/documentSecurity/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
      // _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error updating document security";
    }

    return _response;
  },

  async fileCheckOut(repositoryId, itemId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/file/checkout/${repositoryId}/${itemId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error in checkout document";
    }

    return _response;
  },

  async getRetentions(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/file/retention/all`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 204) {
        _response.payload = null;
      } else if (status === 200) {
        _response.payload = JSON.parse(data);
      } else {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching retention";
    }

    return _response;
  },

  async getRetention(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/file/retention/${id}`);
      const { status, data } = response;

      if (status === 204) {
        _response.payload = null;
      } else if (status === 200) {
        _response.payload = JSON.parse(data);
      } else {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching retention";
    }

    return _response;
  },

  async addRetention(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/file/retention",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
      // _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error inserting retention";
    }

    return _response;
  },

  async updateRetention(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/file/retention/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
      // _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error updating retention";
    }

    return _response;
  },

  async saveMetaData(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/file/metadata`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
      // _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error on save metadata";
    }

    return _response;
  },

  async fileControlDeleteFile(repositoryId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/menu/file/delete/${repositoryId}/1`,
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      _response.error = "error deleting file";
    }

    return _response;
  },

  async getSummary(rId, itemId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/file/summary/${rId}/${itemId}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching document extraction";
    }

    return _response;
  },

  async getRepositoryOverView(rId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/overview/OCRCounts/${rId}`,
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      _response.error = "error in repository overview";
    }

    return _response;
  },

  async getOcrType(rId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/overview/repository/${rId}`,
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      _response.error = "error in get OCR Type";
    }

    return _response;
  },

  async getRecendActivity(rId, cnt, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/overview/repositoryActivity/${rId}/${cnt}`,
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      _response.error = "error in recent activity";
    }

    return _response;
  },

  // upload from another device

  async getQrcode(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/file/qrFileUpload`,
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      _response.error = "error in get Qr code";
    }

    return _response;
  },

  async getQrFileUploadById(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/file/qrFileUploadById/${id}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching mobile upload";
    }

    return _response;
  },

  async updateJson(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/file/qrFileUpload/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
      // _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error updating file";
    }

    return _response;
  },
};
