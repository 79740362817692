const WorkspaceRoutes = [
  {
    path: "/workspaces/",
    meta: { menu: "workspaces" },
    component: () =>
      import(
        /* webpackChunkName: "workspaces" */ "@/views/workspaces/Workspaces.vue"
      ),
    children: [
      {
        path: "overview",
        name: "workspaces-overview",
        meta: { menu: "workspaces", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "workspaces-overview" */ "@/views/workspaces/views/WorkspacesOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "workspaces-browse",
        meta: { menu: "workspaces", breadcrumb: "browse" },
        props: { mode: "BROWSE" },
        component: () =>
          import(
            /* webpackChunkName: "workspaces-browse" */ "@/views/workspaces/views/workpaces-browse-and-trash/WorkspacesBrowseAndTrash.vue"
          ),
      },
      {
        path: "reports",
        name: "workspaces-reports",
        meta: { menu: "workspaces", breadcrumb: "reports" },
        component: () =>
          import(
            /* webpackChunkName: "workspaces-reports" */ "@/views/workspaces/views/WorkspacesReports.vue"
          ),
      },
      {
        path: "security",
        name: "workspaces-security",
        meta: { menu: "workspaces", breadcrumb: "security" },
        component: () =>
          import(
            /* webpackChunkName: "workspaces-security" */ "@/views/workspaces/views/WorkspacesSecurity.vue"
          ),
      },
      {
        path: "trash",
        name: "workspaces-trash",
        meta: { menu: "workspaces", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "workspaces-trash" */ "@/views/workspaces/views/workpaces-browse-and-trash/WorkspacesBrowseAndTrash.vue"
          ),
      },
    ],
  },
  {
    path: "/workspaces/builder/:id?",
    name: "workspace-builder",
    meta: { menu: "workspaces" },
    props: (route) => ({ workspaceId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "workspace-builder" */ "@/views/workspaces/views/workspace-builder/WorkspaceBuilder.vue"
      ),
  },
];

export default WorkspaceRoutes;
