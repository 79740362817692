<template>
  <div>
    <div class="row items-center justify-center">
      <BaseActionButton
        is-flat
        :color="error ? 'red' : value ? 'secondary' : 'gray'"
        :icon="value ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
        no-border
        @click="handleInput"
      />
    </div>

    <FormFieldError v-if="error" :error="error" class="text-center" />
  </div>
</template>

<script>
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "CheckboxField",

  components: { FormFieldError },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },
  },

  methods: {
    handleInput() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
