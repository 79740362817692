<script>
import { clone } from "lodash-es";
import ShortTextFieldWrapper from "./ShortTextFieldWrapper.vue";
import NumberFieldWrapper from "./NumberFieldWrapper.vue";
import DateFieldWrapper from "./DateFieldWrapper.vue";
import TimeFieldWrapper from "./TimeFieldWrapper.vue";
import SingleSelectFieldWrapper from "./SingleSelectFieldWrapper.vue";

export default {
  name: "RenderContent",

  props: {
    value: {
      type: Object,
      required: true,
    },

    content: {
      type: String,
      required: true,
    },

    fibFields: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fieldContent: "",
      fieldsModel: {},
    };
  },

  watch: {
    content: {
      immediate: true,
      handler: "parseContent",
    },
  },

  methods: {
    getField(field) {
      switch (field.type) {
        case "SHORT_TEXT":
          return `<ShortTextFieldWrapper v-model="fieldsModel['${field.id}']" v-bind:field="getField('${field.id}')" />`;
        case "NUMBER":
          return `<NumberFieldWrapper v-model="fieldsModel['${field.id}']" v-bind:field="getField('${field.id}')" />`;
        case "DATE":
          return `<DateFieldWrapper v-model="fieldsModel['${field.id}']" v-bind:field="getField('${field.id}')" />`;
        case "TIME":
          return `<TimeFieldWrapper v-model="fieldsModel['${field.id}']" v-bind:field="getField('${field.id}')"  />`;
        case "SINGLE_SELECT":
          return `<SingleSelectFieldWrapper v-model="fieldsModel['${field.id}']" v-bind:field="getField('${field.id}')"  />`;
      }
    },

    parseContent() {
      const parser = new DOMParser();
      const html = parser.parseFromString(this.content, "text/html");
      const placeholderNodes = Array.from(
        html.querySelectorAll("fieldplaceholder")
      );

      const placeholders = placeholderNodes.map((placeholder) => ({
        id: placeholder.attributes.fieldid.value,
        type: placeholder.attributes.fieldtype.value,
      }));

      this.fieldsModel = {};
      this.fieldContent = clone(this.content);

      for (let placeholder of placeholders) {
        this.$set(this.fieldsModel, placeholder.id, "");

        const _placeholder = `<fieldplaceholder fieldid="${placeholder.id}" fieldtype="${placeholder.type}"></fieldplaceholder>`;
        const field = this.getField(placeholder);

        this.fieldContent = this.fieldContent.replace(_placeholder, field);
      }
    },
  },

  render(createElement) {
    const fieldsModel = this.fieldsModel;
    const fields = this.fibFields;

    const component = {
      name: "Content",

      template: `<div class="fill-ups">${this.fieldContent}</div>`,

      components: {
        ShortTextFieldWrapper,
        NumberFieldWrapper,
        DateFieldWrapper,
        TimeFieldWrapper,
        SingleSelectFieldWrapper,
      },

      data() {
        return {
          fieldsModel,
          fields,
        };
      },

      watch: {
        fieldsModel: {
          deep: true,
          handler() {
            this.$parent.$emit("input", this.fieldsModel);
          },
        },
      },

      methods: {
        getField(fieldId) {
          return this.fields.find((field) => field.id === fieldId);
        },
      },
    };

    return createElement(component);
  },
};
</script>

<style lang="scss">
.fill-ups p {
  line-height: 1.75;
}
</style>
