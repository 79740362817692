import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

/**
 * Function to check whether the given string is a valid time string,
 * this function uses the day.js library to parse the string
 *
 * @param time The string to check
 * @returns True if the string is a valid time string else return false
 *
 * eg. 12:20 PM (valid) / 25:10 AM (invalid)
 */

export function isValidTime(time) {
  if (!time) {
    return true;
  }

  let format = time.split(" ");

  if (format.length === 2) {
    const regEx = /[0-9]{2}[:][0-9]{2} ([AP][M])$/g; // should be in the format (hh:mm aa)
    const isValidFormat = regEx.test(time);

    if (!isValidFormat) {
      console.error(`${time} - time should be in "HH:MM AA" format`);
      return false;
    }

    const isValid = dayjs(time, "hh:mm A", true).isValid(); // should be a valid time

    if (!isValid) {
      console.error(`${time} - invalid time`);
      return false;
    }
  } else {
    const regEx = /^(?:[01]\d|2[0-3]):[0-5]\d$/; // should be in the format (hh:mm)
    const isValidFormat = regEx.test(time);

    if (!isValidFormat) {
      console.error(`${time} - time should be in "HH:MM" format`);
      return false;
    }

    const isValid = dayjs(time, "HH:mm", true).isValid(); // should be a valid time

    if (!isValid) {
      console.error(`${time} - invalid time`);
      return false;
    }
  }

  return true;
}
