import { axiosCrypto } from "./axios";

export default {
  async getRequestCountByUserId(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/overview/workflow/ticketCountByUserId/${id}`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching request count";
    }

    return _response;
  },

  async getUserOverallCount() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/overview/allworkflowticketCount`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching user overall count";
    }

    return _response;
  },

  async getUserOverallInbox() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/overview/currentWorkflowInbox`);
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow count";
    }

    return _response;
  },

  async getRepositoryFieldwiseTicketCount(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/overview/workflow/repositoryfieldwiseTicketCount/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error =
        "error fetching document wise ticket count for each department";
    }

    return _response;
  },

  async getDocumentBasedTicketReport(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/overview/workflow/documentBasedTicketReport/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching document wise ticket details";
    }

    return _response;
  },

  async getDocumentWiseTicketCount(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/overview/workflow/documentWiseTicketCount/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching document wise ticket counts report";
    }

    return _response;
  },
};
