<template>
  <ValidationProvider v-slot="{ errors }" :rules="validationRules">
    <NumberField
      :value="_value"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :error="errors[0]"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import NumberField from "./NumberField.vue";

export default {
  name: "NumberFieldWrapper",

  components: { ValidationProvider, NumberField },

  props: {
    value: {
      type: [String, Number],
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this.isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      const contentRule = this.field.settings.validation.contentRule;
      if (contentRule === "INTEGER") {
        rules.integer = true;
      }

      const minimum = this.field.settings.validation.minimum;
      if (minimum) {
        rules.min_value = minimum;
      }

      const maximum = this.field.settings.validation.maximum;
      if (maximum) {
        rules.max_value = maximum;
      }

      return rules;
    },

    isReadonly() {
      return this.field.settings.general.visibility === "READ_ONLY";
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    _value() {
      if (this.value) {
        return this.value;
      }

      return this.field.settings.specific.customDefaultValue;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
