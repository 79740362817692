<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="{ required: !isReadonly ? isMandatory : false }"
  >
    <SelectField
      :value="_value"
      :is-mandatory="!isReadonly ? isMandatory : false"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :error="errors[0]"
      :options="options"
      :new-option="allowToAddNewOptions"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SelectField from "./SelectField.vue";

export default {
  name: "SingleSelectFieldWrapper",

  components: { ValidationProvider, SelectField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      options: [],
    };
  },

  computed: {
    isReadonly() {
      return this.field.settings.general.visibility === "READ_ONLY";
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    _value() {
      if (this.value) {
        return this.value;
      }

      return this.field.settings.specific.customDefaultValue;
    },

    allowToAddNewOptions() {
      return this.field.settings.specific.allowToAddNewOptions;
    },
  },

  created() {
    const optionsType = this.field.settings.specific.optionsType;

    if (optionsType === "CUSTOM") {
      this.options = this.getCustomOptions();
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    getCustomOptions() {
      const separator =
        this.field.settings.specific.separateOptionsUsing === "NEWLINE"
          ? "\n"
          : ",";

      return this.field.settings.specific.customOptions
        .split(separator)
        .map((option) => ({
          id: this.$nano.id(),
          label: option.trim(),
          value: option.trim(),
        }));
    },
  },
};
</script>

<style lang="scss" scoped></style>
