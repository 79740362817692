<template>
  <ValidationProvider v-slot="{ errors }" :rules="validationRules">
    <TextField
      :value="_value"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :error="errors[0]"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextField from "./TextField.vue";

export default {
  name: "ShortTextFieldWrapper",

  components: { ValidationProvider, TextField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this.isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      if (this.contentRule === "ALPHA") {
        rules.alpha = true;
      }

      if (this.contentRule === "ALPHA_SPACES") {
        rules.alphaSpaces = true;
      }

      if (this.contentRule === "ALPHA_DASH") {
        rules.alphaDash = true;
      }

      if (this.contentRule === "ALPHA_NUMERIC") {
        rules.alphaNum = true;
      }

      if (this.contentRule === "EMAIL") {
        rules.email = true;
      }

      const minCharacters = this.field.settings.validation.minimum;
      if (minCharacters) {
        rules.min = minCharacters;
      }

      const maxCharacters = this.field.settings.validation.maximum;
      if (maxCharacters) {
        rules.max = maxCharacters;
      }

      return rules;
    },

    isReadonly() {
      return this.field.settings.general.visibility === "READ_ONLY";
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    contentRule() {
      return this.field.settings.validation.contentRule;
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    _value() {
      if (this.value) {
        return this.value;
      }

      if (this.field.settings.specific.defaultValue === "USER_EMAIL") {
        return this.$store.state.session.email;
      }

      if (this.field.settings.specific.defaultValue === "USER_NAME") {
        let name = "";
        if (this.$store.state.session.firstName) {
          name = this.$store.state.session.firstName;
        }
        if (this.$store.state.session.lastName) {
          name += " " + this.$store.state.session.lastName;
        }
        return name;
      }

      return this.field.settings.specific.customDefaultValue;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
