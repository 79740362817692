<template>
  <div
    id="inline-text-field"
    :class="{
      'is-focused': isFocused,
      'has-error': error,
      'is-readonly': isReadonly,
      'is-disabled': isDisabled,
    }"
  >
    <div class="col">
      <input
        type="text"
        :value="value"
        :placeholder="placeholder"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @input.stop="handleInput"
      />
    </div>

    <BaseIcon
      v-if="error"
      v-tooltip:red.top="error"
      color="red"
      name="mdi-information-outline"
      class="cursor-pointer q-ml-sm"
    />
  </div>
</template>

<script>
export default {
  name: "TextField",

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFocused: false,
    };
  },

  computed: {
    placeholder() {
      return this.label || "Text";
    },
  },

  methods: {
    handleInput(evt) {
      this.$emit("input", evt.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
#inline-text-field {
  display: inline-flex;
  align-items: center;
  width: 200px;
  margin-left: 8px;
  margin-right: 8px;
  border-bottom: 1px solid var(--border-color);

  input {
    color: var(--secondary);
  }

  &.has-error {
    border-bottom: 1px solid var(--red);

    input,
    input::placeholder {
      color: var(--red);
    }
  }

  &.is-focused {
    border-bottom: 1px solid var(--secondary);
  }
}
</style>
