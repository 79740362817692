<template>
  <q-dialog :value="value" :position="position" maximized @input="close">
    <div id="sheet" :style="{ width: _width }">
      <!-- header -->

      <div class="header">
        <div class="title-1">
          <slot name="title"></slot>
        </div>

        <q-space />

        <slot name="actions"></slot>

        <BaseActionButton
          v-if="showMaximizeBtn"
          is-flat
          :icon="hasFullWidth ? 'eva-collapse-outline' : 'eva-expand-outline'"
          class="q-mr-sm"
          @click="toggleSheetWidth"
        />

        <BaseCloseButton @click="close" />
      </div>

      <div v-if="upload" class="upload">
        <slot name="upload"></slot>
      </div>

      <div v-if="subActions" class="subActions">
        <slot name="subActions"></slot>
      </div>

      <!-- ... -->

      <!-- content -->

      <BaseScrollbar
        v-if="!customHeight"
        :height="scrollBarHeight"
        :class="{ 'q-pb-xl': !noPadding }"
      >
        <BaseLoadingBarSheet
          v-if="loadingBar && !hasFullWidth && width !== '100vw'"
        />

        <BaseLoadingBarSheet v-if="loadingBarSave" />
        <slot name="default"></slot>
      </BaseScrollbar>
      <div v-if="customHeight">
        <BaseLoadingBarSheet
          v-if="loadingBar && !hasFullWidth && width !== '100vw'"
        />
        <BaseLoadingBarSheet v-if="loadingBarSave" />
        <slot name="default"></slot>
      </div>
      <!-- ... -->

      <!-- footer -->

      <div v-if="hasFooter" class="footer">
        <slot name="footer"></slot>
      </div>

      <!-- ... -->
    </div>
  </q-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sheet",

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    hasFooter: {
      type: Boolean,
      default: false,
    },

    noPadding: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: "360px",
    },

    position: {
      type: String,
      default: "right",
    },

    showMaximizeBtn: {
      type: Boolean,
      default: false,
    },

    customHeight: {
      type: String,
      default: "",
    },

    subActions: {
      type: Boolean,
      default: false,
    },

    subActionsHeight: {
      type: Number,
      default: 37,
    },

    upload: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hasFullWidth: false,
    };
  },

  computed: {
    ...mapState(["loadingBar", "loadingBarSave"]),

    scrollBarHeight() {
      let negativeHeight = this.hasFooter ? 112 : 56;
      if (this.subActions) {
        negativeHeight += this.subActionsHeight;
      }
      if (this.upload) {
        negativeHeight += 102;
      }
      return `calc(100vh - ${negativeHeight}px)`;
    },

    _width() {
      return this.hasFullWidth ? "100vw" : this.width;
    },
  },

  methods: {
    close() {
      this.$emit("input", false);
    },

    toggleSheetWidth() {
      this.hasFullWidth = !this.hasFullWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
#sheet {
  height: 100%;
  background-color: var(--component-bg-color);

  .header {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    height: 56px;
    border-bottom: 1px solid var(--divider-color);
  }

  .subActions {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    border-bottom: 1px solid var(--divider-color);
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 16px;
    height: 56px;
    border-top: 1px solid var(--divider-color);
  }
}
</style>
