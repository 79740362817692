<template>
  <div
    id="field-wrapper"
    :class="{
      'is-readonly': isReadonly,
      'is-disabled': isDisabled,
      'hide-border': hideBorder,
      'is-focused': isFocused,
      'has-error': hasError,
    }"
    :style="
      highlight === 'orange' || highlight === 'red'
        ? [
            { 'border-left': '3px solid ' + highlight },
            { width: '100%' },
            { float: 'left' },
            { 'margin-right': '5px' },
          ]
        : highlight === 'green'
        ? [{ 'border-left': '3px solid ' + highlight }]
        : ''
    "
    v-on="$listeners"
  >
    <!-- field -->

    <div id="field">
      <slot name="default"></slot>
    </div>

    <!-- ... -->

    <slot name="action"></slot>

    <!-- action -->

    <ActionButton
      v-if="actionIcon"
      :icon="actionIcon"
      @click="$emit('action')"
    />

    <!-- ... -->

    <!-- clear btn -->

    <ClearButton v-if="_isClearable" @click.stop="$emit('clear')" />

    <!-- ... -->

    <!-- search -->

    <slot name="search"></slot>

    <!-- ... -->
  </div>
</template>

<script>
import ActionButton from "./components/ActionButton.vue";
import ClearButton from "./components/ClearButton.vue";

export default {
  name: "FormFieldWrapper",

  components: { ActionButton, ClearButton },

  props: {
    actionIcon: {
      type: String,
      default: "",
    },

    isFocused: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    hideBorder: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },
  },

  computed: {
    _isClearable() {
      return !this.isDisabled && !this.isReadonly && this.isClearable;
    },
  },
};
</script>

<style lang="scss" scoped>
#field-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;

  #field {
    flex: 1;
  }

  &.hide-border {
    border: 1px solid transparent;
  }

  &.is-focused {
    border: 1px solid var(--secondary);
  }

  &.has-error {
    border: 1px solid var(--red);
  }
}
</style>
