import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

/**
 * Function to check whether the given string is a valid datetime string,
 * this function uses the day.js library to parse the string
 *
 * @param datetime The string to check
 * @returns True if the string is a valid datetime string else return false
 *
 * eg. 12:20 PM (valid) / 25:10 AM (invalid)
 */

export function isValidDatetime(datetime, showErrorMsg = true) {
  if (!datetime) {
    return true;
  }

  let format = datetime.split(" ");

  if (format.length === 3) {
    const regEx =
      /[0-9]{4}[-][0-9]{2}[-][0-9]{2} [0-9]{2}[:][0-9]{2} ([AP][M])$/g; // should be in the format (yyyy-mm-dd hh:mm aa)
    const isValidFormat = regEx.test(datetime);

    if (!isValidFormat) {
      if (showErrorMsg) {
        console.error(
          `${datetime} - datetime should be in "YYYY-MM-DD HH:MM AA" format`
        );
      }

      return false;
    }

    const isValid = dayjs(datetime, "YYYY-MM-DD hh:mm A", true).isValid(); // should be a valid datetime

    if (!isValid) {
      console.error(`${datetime} - invalid datetime`);
      return false;
    }
  } else {
    const regEx = /[0-9]{4}[-][0-9]{2}[-][0-9]{2} [0-9]{2}[:][0-9]{2}$/g; // should be in the format (yyyy-mm-dd hh:mm)
    const isValidFormat = regEx.test(datetime);

    if (!isValidFormat) {
      if (showErrorMsg) {
        console.error(
          `${datetime} - datetime should be in "YYYY-MM-DD HH:MM" format`
        );
      }

      return false;
    }

    const isValid = dayjs(datetime, "YYYY-MM-DD HH:mm", true).isValid(); // should be a valid datetime

    if (!isValid) {
      console.error(`${datetime} - invalid datetime`);
      return false;
    }
  }

  return true;
}
