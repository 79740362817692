<template>
  <div
    id="inline-select-field"
    :class="{
      'is-focused': isFocused,
      'has-error': error,
      'is-readonly': isReadonly,
      'is-disabled': isDisabled,
    }"
    @click="toggleOptionPicker"
  >
    <div class="row items-center col">
      <!-- field -->

      <div class="select">
        <div v-if="!_value && placeholder" class="placeholder">
          {{ placeholder }}
        </div>

        <div v-if="_value" class="value">
          {{ _value }}
        </div>

        <input
          v-if="isSearchable"
          ref="searchQuery"
          v-model="searchQuery"
          type="text"
          class="q-ml-xs"
          @focus="isFocused = true"
          @blur="isFocused = false"
          @keypress.enter="handleKeypress"
        />
      </div>

      <BaseIcon name="eva-chevron-down" class="cursor-pointer" />

      <!-- ... -->

      <!-- option picker -->

      <q-menu
        v-model="optionPicker"
        fit
        no-focus
        no-refocus
        no-parent-event
        transition-show="scale"
        transition-hide="scale"
      >
        <OptionPicker
          :value="value"
          :options="_options"
          :new-option="newOption"
          @select="handleSelect"
        />
      </q-menu>

      <!-- ... -->

      <BaseIcon
        v-if="error"
        v-tooltip:red.top="error"
        color="red"
        name="mdi-information-outline"
        class="cursor-pointer q-ml-sm"
      />
    </div>
  </div>
</template>

<script>
import OptionPicker from "@/components/common/form/select-field/OptionsPicker.vue";
import { lowerCase } from "lodash-es";

export default {
  name: "SelectField",

  components: { OptionPicker },

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    options: {
      type: Array,
      default: () => [],
    },

    newOption: {
      type: Boolean,
      default: true,
    },

    isSearchable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isFocused: false,
      optionPicker: false,
      searchQuery: "",
    };
  },

  computed: {
    placeholder() {
      return this.label || "Select";
    },

    _value() {
      if (!this.value || !this.options.length) {
        return "";
      }

      if (this.newOption) {
        return this.value;
      }

      return this.options.find((option) => option.value === this.value).label;
    },

    _options() {
      if (!this.searchQuery) {
        return this.options;
      }

      return this.options.filter((option) =>
        lowerCase(option.label).includes(lowerCase(this.searchQuery))
      );
    },
  },

  watch: {
    optionPicker() {
      if (!this.optionPicker) {
        this.searchQuery = "";
      }
    },
  },

  methods: {
    toggleOptionPicker() {
      this.optionPicker = !this.optionPicker;
    },

    handleSelect(optionValue) {
      this.$emit("input", optionValue);
      this.optionPicker = false;
    },

    handleKeypress() {
      if (!this._options.length && this.newOption) {
        this.handleSelect(this.searchQuery);
      } else {
        this.handleSelect(this._options[0].value);
      }

      this.searchQuery = "";
      this.$refs.searchQuery.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
#inline-select-field {
  display: inline-flex;
  width: 200px;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  border-bottom: 1px solid var(--border-color);

  .select {
    flex: 1;
    display: flex;
    align-items: center;

    .value {
      white-space: nowrap;
      font-weight: 500;
      color: var(--secondary);
    }
  }

  &.has-error {
    border-bottom: 1px solid var(--red);

    input,
    input::placeholder {
      color: var(--red);
    }
  }

  &.is-focused {
    border-bottom: 1px solid var(--secondary);
  }
}
</style>
