<template>
  <div id="tabs">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      class="tab"
      :class="{ 'is-active shadow-2': value === tab.value }"
      :style="{
        backgroundColor:
          value === tab.value ? `var(--${color})` : 'var(--body-bg-color)',
      }"
      @click="handleClick(tab.value)"
    >
      {{ tab.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",

  props: {
    tabs: {
      type: Array,
      required: true,
    },

    value: {
      type: [String, Number],
      required: true,
    },

    color: {
      type: String,
      default: "primary",
    },
  },

  methods: {
    handleClick(value) {
      if (value === this.value) {
        return;
      }

      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
#tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--divider-color);

  .tab {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    color: var(--icon-color);
    text-transform: capitalize;
    border-radius: 4px 16px 0px 0px;
    margin-right: 4px;

    &:hover {
      cursor: pointer;
      color: var(--secondary);
    }

    &.is-active {
      color: white;
      font-weight: 500;
    }
  }
}
</style>
