<template>
  <q-dialog :value="value" @input="close">
    <div
      id="modal"
      :style="{ width }"
      :class="externalAccess ? 'external-access' : ''"
    >
      <!-- header -->

      <div v-if="isHeader" class="header">
        <div class="title-1 ellipsis">
          <slot name="title"></slot>
        </div>

        <q-space />
        <div class="action q-mr-sm">
          <slot name="action"></slot>
        </div>

        <BaseCloseButton v-if="!externalAccess" @click="close" />
      </div>

      <!-- ... -->

      <!-- content -->

      <BaseScrollbar
        :height="scrollBarHeight"
        :class="!noPadding ? 'q-pa-md' : 'q-pl-md q-pr-md'"
      >
        <BaseSheetLoading v-if="loadingBarSave" />

        <slot name="default"></slot>
      </BaseScrollbar>

      <!-- ... -->

      <!-- footer -->

      <div v-if="hasFooter && !loadingBarSave" class="footer">
        <slot name="footer"></slot>
      </div>

      <!-- ... -->
    </div>
  </q-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Modal",

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    hasFooter: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: "320px",
    },

    height: {
      type: String,
      default: "80vh",
    },

    noPadding: {
      type: Boolean,
      default: false,
    },

    isHeader: {
      type: Boolean,
      default: true,
    },

    externalAccess: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["loadingBarSave"]),

    scrollBarHeight() {
      if (this.height === "auto") {
        return this.height;
      }

      const negativeHeight = this.hasFooter ? 138 : 69;
      return `calc(${this.height} - ${negativeHeight}px)`;
    },
  },

  methods: {
    close() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#modal {
  background-color: var(--component-bg-color);
  border-radius: 4px;

  &:not(.external-access) {
    max-width: 80vw;
    max-height: 80vh;
  }

  &.external-access {
    max-width: 100vw !important;
    height: 94% !important;
  }

  .header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--divider-color);
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid var(--divider-color);
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }
}
</style>
