<template>
  <Modal
    :value="value"
    has-footer
    width="452px"
    height="auto"
    @input="closeModal"
  >
    <!-- title -->

    <template #title>Draw Signature</template>

    <!-- ... -->

    <template #default>
      <!-- signature pad -->

      <div id="signature-pad-wrapper">
        <VueSignaturePad
          ref="signaturePad"
          width="420px"
          height="180px"
          :options="signaturePadOptions"
          class="signature-pad"
        />

        <!-- ... -->

        <!-- signature pad actions -->

        <div class="signature-pad-actions">
          <BaseActionButton
            v-tooltip.left="'undo'"
            is-flat
            icon="eva-undo-outline"
            class="q-mr-sm"
            @click="undoSignature"
          />

          <BaseActionButton
            v-tooltip.bottom="'clear'"
            is-flat
            icon="eva-trash-2-outline"
            @click="clearSignature"
          />
        </div>

        <!-- ... -->
      </div>
    </template>

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" @click="save" />
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/common/popup/Modal.vue";

export default {
  name: "SignaturePad",

  components: { Modal },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      signaturePadOptions: {
        onBegin: () => {
          this.$refs.signaturePad.resizeCanvas();
        },
      },
    };
  },

  methods: {
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
    },

    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },

    closeModal() {
      this.$emit("input", false);
    },

    save() {
      this.closeModal();

      if (this.$refs.signaturePad.isEmpty()) {
        this.$emit("save", "");
        return;
      }

      const signature = this.$refs.signaturePad.saveSignature().data;
      this.$emit("save", signature);
    },
  },
};
</script>

<style lang="scss" scoped>
#signature-pad-wrapper {
  .signature-pad {
    border: 1px solid var(--divider-color);
    border-radius: 4px;
  }

  .signature-pad-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
}
</style>
