const FormRoutes = [
  {
    path: "/forms/",
    meta: { menu: "forms" },
    component: () =>
      import(/* webpackChunkName: "forms" */ "@/views/forms/Forms.vue"),
    children: [
      {
        path: "overview",
        name: "forms-overview",
        meta: { menu: "forms", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "forms-overview" */ "@/views/forms/views/FormsOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "forms-browse",
        meta: { menu: "forms", breadcrumb: "browse" },
        props: { mode: "BROWSE" },
        component: () =>
          import(
            /* webpackChunkName: "forms-browse" */ "@/views/forms/views/forms-browse-and-trash/FormsBrowseAndTrash.vue"
          ),
      },
      {
        path: "trash",
        name: "forms-trash",
        meta: { menu: "forms", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "forms-trash" */ "@/views/forms/views/forms-browse-and-trash/FormsBrowseAndTrash.vue"
          ),
      },
    ],
  },

  {
    path: "/forms/builder/:id?",
    name: "form-builder",
    meta: { menu: "forms" },
    props: (route) => ({ formId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "form-builder" */ "@/views/forms/views/form-builder-wrapper/FormBuilderWrapper.vue"
      ),
  },

  {
    path: "/form/entries/:id/browse",
    name: "form-entries-browse",
    meta: { menu: "forms" },
    props: (route) => ({ formId: route.params.id, mode: "BROWSE" }),
    component: () =>
      import(
        /* webpackChunkName: "form-entries" */ "@/views/forms/views/form-entries/FormEntries.vue"
      ),
  },

  {
    path: "/form/entries/:id/trash",
    name: "form-entries-trash",
    meta: { menu: "forms" },
    props: (route) => ({ formId: route.params.id, mode: "TRASH" }),
    component: () =>
      import(
        /* webpackChunkName: "form-entries" */ "@/views/forms/views/form-entries/FormEntries.vue"
      ),
  },

  {
    path: "/form/:tid/:id",
    name: "form-view",
    props: (route) => ({ tenantId: route.params.tid, formId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "form-view" */ "@/views/forms/views/FormView.vue"
      ),
  },

  {
    path: "/form/entries/:tid/:id/:entryId",
    name: "form-entries-view",
    props: (route) => ({
      tenantId: route.params.tid,
      formId: route.params.id,
      entryId: route.params.entryId,
    }),
    component: () =>
      import(
        /* webpackChunkName: "form-entries" */ "@/views/forms/views/form-entries/FormEntriesView.vue"
      ),
  },
];

export default FormRoutes;
