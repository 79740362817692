<template>
  <div class="col-12">
    <ParagraphRenderer :content="field.settings.specific.textContent" />
  </div>
</template>

<script>
import ParagraphRenderer from "@/components/common/form/paragraph-field/render-content.js";

export default {
  name: "ParagraphFieldWrapper",

  components: { ParagraphRenderer },

  props: {
    field: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
