import { render, staticRenderFns } from "./DateField.vue?vue&type=template&id=f46a5d46&scoped=true&"
import script from "./DateField.vue?vue&type=script&lang=js&"
export * from "./DateField.vue?vue&type=script&lang=js&"
import style0 from "./DateField.vue?vue&type=style&index=0&id=f46a5d46&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f46a5d46",
  null
  
)

export default component.exports
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QDate from 'quasar/src/components/date/QDate.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QChip,QAvatar,QMenu,QDate});
