import { render, staticRenderFns } from "./NestedList.vue?vue&type=template&id=38781786&scoped=true&"
import script from "./NestedList.vue?vue&type=script&lang=js&"
export * from "./NestedList.vue?vue&type=script&lang=js&"
import style0 from "./NestedList.vue?vue&type=style&index=0&id=38781786&prod&lang=scss&scoped=true&"
import style1 from "./NestedList.vue?vue&type=style&index=1&id=38781786&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38781786",
  null
  
)

export default component.exports