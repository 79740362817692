<template>
  <ValidationProvider v-slot="{ errors }" :rules="validationRules">
    <NumberField
      v-if="contentRule === 'DECIMAL' || contentRule === 'INTEGER'"
      :value="value1"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :is-clearable="false"
      :error="errors[0]"
      @input="handleInput"
    />

    <TextField
      v-if="contentRule === 'COMMAS' || contentRule === 'BOTH'"
      :value="value1"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :is-clearable="false"
      :error="errors[0]"
      @input="handleInput"
      @focusOut="handleFocusOut"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import { axiosCrypto } from "@/api/axios.js";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "NumberFieldWrapper",

  components: { ValidationProvider, NumberField, TextField },

  props: {
    value: {
      type: [String, Number],
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: Number,
      default: 0,
    },

    filterBy: {
      type: Array,
      default: () => [],
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value1: "",
    };
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this._isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      const contentRule = this.field.settings.validation.contentRule;
      if (contentRule === "INTEGER") {
        rules.integer = true;
      }

      if (contentRule === "COMMAS") {
        rules.commas = true;
      }

      if (contentRule === "BOTH") {
        rules.both = true;
      }

      const minimum = this.field.settings.validation.minimum;
      if (minimum) {
        rules.min_value = minimum;
      }

      const maximum = this.field.settings.validation.maximum;
      if (maximum) {
        rules.max_value = maximum;
      }

      return rules;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    _isMandatory() {
      if (this.isMandatory) {
        return true;
      } else {
        return this.field.settings.validation.fieldRule === "REQUIRED";
      }
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    contentRule() {
      return this.field.settings.validation.contentRule;
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.filterBy) {
          if (this.filterBy.length) {
            if (this.field.settings.specific.masterFormId) {
              this.getMasterEntriesFilter();
            }
          }
        }
      },
    },

    value: {
      immediate: true,
      handler() {
        this.value1 = this.value;
      },
    },
  },

  created() {},

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    async getMasterEntriesFilter() {
      let filterBy = [];
      this.filterBy.forEach((item) => {
        filterBy.push({ ...item, value: item.value.toString() });
      });

      try {
        const response = await axiosCrypto.post(
          `/form/${this.field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: this.field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.handleInput(options[0]);
        } else {
          this.handleInput("");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    numberValidation() {
      if (this.contentRule === "COMMAS" || this.contentRule === "BOTH") {
        // let amount = this.value;
        // let value = String(amount)
        //   .replace(new RegExp(",", "g"), "")
        //   .toString()
        //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // // console.log(value);

        // return value;
        let amount = this.value.split(".");
        let value = amount[0]
          .replace(new RegExp(",", "g"), "")
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // if()
        if (amount[1]) {
          let value1 = value.replace(new RegExp(",", "g"), "");
          let format = `${Number(value1)}.${amount[1]}`;
          // console.log(format);
          let price = Number(format).toFixed(2);
          let price1 = price
            .replace(new RegExp(",", "g"), "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return price1;
        }
        return value;
      }

      return this.value;
    },

    handleFocusOut() {
      this.value1 = this.numberValidation();
    },
  },
};
</script>

<style lang="scss" scoped></style>
