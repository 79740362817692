const DashboardRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { menu: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/views/dashboard/Dashboard.vue"
      ),
    children: [],
  },
];

export default DashboardRoutes;
