export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },

  render() {
    return <div domPropsInnerHTML={this.content}></div>;
  },
};
