import { axios, axiosCrypto } from "./axios";

export default {
  async getDatasetByQuery(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/common/getDatasetByQuery",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching data by query";
    }

    return _response;
  },

  async insertNotification(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/other/notification",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error inserting notification";
    }

    return _response;
  },

  async getNotifications(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/other/notification/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      //_response.error = "error fetching notifications";
    }

    return _response;
  },

  async deleteNotification(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/other/notification/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error deleting notifications";
    }

    return _response;
  },

  async clearAllNotifications(id, notifyStatus) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/other/notification/clearAll/${id}/${notifyStatus}`
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error clearing notifications";
    }

    return _response;
  },

  async getTimeZones() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const { status, data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/common/systemTimeZone`,
        method: "GET",
        headers: {
          Token: "email",
        },
      });

      if (status !== 200) {
        throw "invalid status code";
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching time zones";
    }

    return _response;
  },
};
