<template>
  <div id="field-label" :class="{ 'no-margin': noMargin }">
    <div
      v-if="!openLabelInput"
      class="label"
      :class="transform"
      @click="handleOpenInput"
    >
      {{ label }}
    </div>
    <div class="row items-center">
      <div class="col-12">
        <input
          v-if="openLabelInput"
          ref="focusMe"
          :value="label"
          type="text"
          autofocus
          class="label"
          @input="handleLabelInput"
          @mouseleave="handleFocusOut(label)"
        />
      </div>
      <div class="col-12">
        <FormFieldError v-if="labelError" :error="labelError" />
      </div>
    </div>

    <!-- <div v-if="!isMandatory" class="optional">(optional)</div> -->

    <div v-if="isMandatory" class="required">*</div>

    <BaseIcon
      v-if="tooltip"
      v-tooltip.top="tooltip"
      inherit-color
      name="eva-question-mark-circle"
      class="info-icon"
    />
  </div>
</template>

<script>
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "FormFieldLabel",

  components: {
    FormFieldError,
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    noMargin: {
      type: Boolean,
      default: false,
    },

    transform: {
      type: String,
      default: "transform",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      openLabelInput: false,
      labelError: "",
    };
  },

  watch: {
    // openLabelInput() {
    //   if (this.openLabelInput) {
    //     // this.$refs.focusMe.focus();
    //     console.log(this.$refs.focusMe);
    //     // this.$refs.focusMe.click();
    //     this.$nextTick(function () {
    //       this.$refs.focusMe.click();
    //     });
    //   }
    // },
  },

  methods: {
    handleFocusOut(label) {
      if (!label) {
        this.labelError = "Label should not be empty";
        return;
      }
      this.openLabelInput = false;
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    handleLabelInput(evt) {
      if (!evt.target.value) {
        this.labelError = "Label should not be empty";
      } else {
        this.labelError = "";
      }
      this.$emit("update:label", evt.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
#field-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 1.25rem;

  &.no-margin {
    margin: 0px;
  }

  .label {
    color: var(--icon-color);
    font-weight: 500;
    &.transform {
      text-transform: capitalize;
    }
  }

  .label:hover {
    cursor: auto;
  }

  .optional {
    color: var(--icon-color-inverted);
    margin-left: 3px;
  }

  .required {
    color: red;
    margin-left: 3px;
  }

  .info-icon {
    margin-left: 8px;
    cursor: pointer;
    color: var(--icon-color-inverted);
  }
}
</style>
