import { IMask } from "vue-imask";

export default {
  mask: "H{:}M A",
  blocks: {
    H: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    M: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      maxLength: 2,
    },
    A: {
      mask: IMask.MaskedEnum,
      enum: ["AM", "am", "PM", "pm", "aM", "Am", "pM", "Pm"],
    },
  },
};
