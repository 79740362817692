import { render, staticRenderFns } from "./LoadingBar.vue?vue&type=template&id=1484b6b1&scoped=true&"
import script from "./LoadingBar.vue?vue&type=script&lang=js&"
export * from "./LoadingBar.vue?vue&type=script&lang=js&"
import style0 from "./LoadingBar.vue?vue&type=style&index=0&id=1484b6b1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1484b6b1",
  null
  
)

export default component.exports
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLinearProgress,QSpinner});
