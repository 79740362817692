import { axiosCrypto } from "./axios";

export default {
  async getConnectedApps() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/integration/getAccess`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching integrated apps";
    }

    return _response;
  },

  async createConnectApps(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/integration/accessCode`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error connecting apps";
    }

    return _response;
  },

  async createJiraConnection(type, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/integration/jira/${type}/getCurrentUser`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      if (e.response.status === 400) {
        _response.error = "Jira SignIn Required";
      } else {
        _response.error = "error connecting jira";
      }
    }

    return _response;
  },

  async createJiraIssue(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/integration/jira/createIssue`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 201) {
        _response.payload = data;
      } else if (status === 200) {
        _response.payload = data;
      } else {
        throw response;
      }
    } catch (e) {
      console.error(e);
      //_response.error = "error creating issue in jira";
      _response.error = e.response.data;
    }

    return _response;
  },

  async insertJiraIssueComments(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/integration/jira/createIssueComment`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      //_response.error = "error creating comment in jira";
      _response.error = e.response.data;
    }

    return _response;
  },

  async getJiraConnection(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/integration/cloudIntegration/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching connection entries";
    }

    return _response;
  },

  async updateJiraConnection(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/integration/cloudIntegration/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error updating connection";
    }

    return _response;
  },

  async getJiraUsers(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/integration/jira/1/getUsers",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching jira users";
    }

    return _response;
  },

  async getJiraIssueList(workflowId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/integration/jiraCreateIssue/list/${workflowId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching jira issue list";
    }

    return _response;
  },

  async getSMTPConnection(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/MailSettings/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching SMTP connection";
    }

    return _response;
  },

  async createSMTPConnection(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/MailSettings`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error creating SMTP";
    }

    return _response;
  },

  async updateSMTPConnection(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/MailSettings/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error updating connection";
    }

    return _response;
  },

  async verifyMailCredentials(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/MailSettings/verifyMailCredentialsold`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error verification emails";
    }

    return _response;
  },
};
