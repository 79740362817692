import { axiosCrypto } from "./axios";

export default {
  // *** ARMOURGROUP
  async getData(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/client/armgroup/searchlist",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching data";
    }

    return _response;
  },

  async getDataSync(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      //getdatabasehublinkcall
      const response = await axiosCrypto.post(
        "/connector/getClaimantInfo",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      if (e.response.status === 404) {
        _response.error = "Connection Interrupted";
      } else {
        _response.error = "error fetching data";
      }
    }

    return _response;
  },

  async createContract(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/client/armgroup/contract",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      //_response.payload = JSON.parse(data);
      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error creating contract";
    }

    return _response;
  },

  async getClaimHistory(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/client/armgroup/claimStatuslist",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching claim history";
    }

    return _response;
  },

  async armgroupMLValidation(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/client/armgroupmlvalidation/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error in getting validation data";
    }

    return _response;
  },

  async insertArmgroupMLValidation(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/client/armgroupmlvalidation",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error in saving validation data";
    }

    return _response;
  },

  async updateArmgroupMLValidation(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        "/client/armgroupmlvalidation",
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 202) {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error in updating validation data";
    }

    return _response;
  },

  async updateDataValidation(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/client/armgroupmlvalidation/${payload.id}`,
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 202) {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error updating validations";
    }

    return _response;
  },

  async getPaymentTable(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/client/armgroupPaymentTable/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching payment records";
    }

    return _response;
  },

  async savePaymentTable1(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/client/armgroupPaymentTable",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      //_response.payload = JSON.parse(data);
      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error saving payment records";
    }

    return _response;
  },

  async updatePaymentTable1(processId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/client/armgroupPaymentTable/${processId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      //_response.payload = JSON.parse(data);
      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error updating payment records";
    }

    return _response;
  },

  async savePaymentTable(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/client/armgroupBenefitPayment",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      //_response.payload = JSON.parse(data);
      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error saving payment records";
    }

    return _response;
  },

  // ARMGROUP ***

  // *** SOBHA
  async documentNumberGenerate(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/file/documentNoAutoGenerate",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error generating document number";
    }

    return _response;
  },

  async saveClaimStatus(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/client/armgroupClaimStatusUpdate",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200 && status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error saving claim status";
    }

    return _response;
  },
  // SOBHA ***
};
