const CustomRoutes = [
  {
    path: "/custom/form",
    name: "custom-form",
    component: () =>
      import(
        /* webpackChunkName: "custom-form" */ "@/views/custom/CustomForm.vue"
      ),
  },
];

export default CustomRoutes;
