import { axios, axiosCrypto } from "./axios";
import { setToLocalStorage } from "../helpers/local-storage.js";
import store from "../store/index.js";

export default {
  async login(payload, tenantId) {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status, data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/authentication/login`,
        method: "POST",
        data: JSON.stringify(payload),
        headers: {
          Token: tenantId ? "tenantId " + tenantId : "email " + payload.email,
        },
      });

      if (status !== 200) {
        throw "invalid status code";
      }

      const json = Buffer.from(data, "base64").toString("utf8"); // base64 to JSON
      const identity = JSON.parse(json); // JSON to identity object

      setToLocalStorage(identity, "identity");
      store.commit("setIdentity", identity);
      response.data = "Success";

      const { error } = await this.getSession();
      response.error = error;
    } catch (e) {
      console.error(e);
      if (typeof e.response.data === "string") {
        response.error = e.response.data;
      } else if (e.response.status === 300) {
        response.data = e.response.data;
        response.status = e.response.status;
        console.log(response);
      } else {
        response.error = "error logging in";
      }

      if (e.response.status === 404) {
        response.error = "user account not found";
      }
    }

    return response;
  },

  async getSession() {
    const response = {
      data: "",
      error: "",
    };

    try {
      let { status, data } = await axiosCrypto.get(
        "/authentication/userSession"
      );

      if (status !== 200) {
        throw "invalid status code";
      }
      data = JSON.parse(data);
      setToLocalStorage(data, "session");
      store.commit("setSession", data);
    } catch (e) {
      console.error(e);
      response.error = "error fetching session";
    }

    return response;
  },

  async authentication(payload) {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status, data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/portal/validateOTP`,
        method: "POST",
        data: JSON.stringify(payload),
        headers: {
          Token: "tenantId " + payload.tenantId,
        },
      });

      if (status !== 201 && status !== 200) {
        throw "invalid status code";
      }

      if (payload.otp) {
        const json = Buffer.from(data, "base64").toString("utf8"); // base64 to JSON
        const identity = JSON.parse(json); // JSON to identity object

        setToLocalStorage(identity, "identity");
        store.commit("setIdentity", identity);
        response.data = "Success";

        const { error } = await this.getSession();
        response.error = error;
      }
    } catch (e) {
      console.error(e);
      response.error = "error logging in";
      if (e.response.status === 404) {
        response.error = e.response.data;
      }
    }

    return response;
  },

  async socialLogin(payload, tenantId) {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status, data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/authentication/socialLogin`,
        method: "POST",
        headers: {
          Token: tenantId ? "tenantId " + tenantId : "email " + payload.email,
        },
        data: JSON.stringify(payload),
      });

      if (status !== 200) {
        throw "invalid status code";
      }

      const json = Buffer.from(data, "base64").toString("utf8"); // base64 to JSON
      const identity = JSON.parse(json); // JSON to identity object

      setToLocalStorage(identity, "identity");
      store.commit("setIdentity", identity);
      response.data = "Success";

      const { error } = await this.getSession();
      response.error = error;
    } catch (e) {
      console.error(e);
      if (e.response.status === 404) {
        response.error = "user account not found";
      } else if (e.response.status === 300) {
        response.data = e.response.data;
        response.status = e.response.status;
        console.log(response);
      } else {
        response.error = "error logging in";
      }
    }

    return response;
  },

  async portalLogin(payload) {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status, data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/portal/validateMaster`,
        method: "POST",
        data: JSON.stringify(payload),
        headers: {
          Token: "tenantId " + payload.tenantId,
        },
      });

      if (status === 200) {
        const json = Buffer.from(data, "base64").toString("utf8"); // base64 to JSON
        const identity = JSON.parse(json); // JSON to identity object

        setToLocalStorage(identity, "identity");
        store.commit("setIdentity", identity);
        response.data = "Success";

        const { error } = await this.getSession();
        response.error = error;
      } else if (status !== 201) {
        throw "invalid status code";
      }
    } catch (e) {
      console.error(e);

      response.error = "error logging in";

      if (e.response.status === 404) {
        response.error = e.response.data;
      }
    }

    return response;
  },

  async testDBConnection(payload) {
    const response = {
      data: "",
      error: "",
    };

    try {
      let { status } = await axiosCrypto.post(
        "/authentication/testConnection",
        JSON.stringify(payload)
      );

      if (status !== 200) {
        throw "invalid status code";
      }
    } catch (e) {
      console.error(e);
      response.error = "error fetching connection";
    }

    return response;
  },

  async updateDBConnection(payload) {
    const response = {
      data: "",
      error: "",
    };

    try {
      let { status } = await axiosCrypto.post(
        "/authentication/updateConnection",
        JSON.stringify(payload)
      );

      if (status !== 200) {
        throw "invalid status code";
      }
    } catch (e) {
      console.error(e);
      response.error = "error saving connection";
    }

    return response;
  },

  async validatePassword(payload) {
    const response = {
      data: "",
      error: "",
    };

    try {
      let { status } = await axiosCrypto.post(
        "/authentication/validatePassword",
        JSON.stringify(payload)
      );

      if (status !== 200) {
        throw "invalid status code";
      }
    } catch (e) {
      console.error(e);
      response.error = "invalid password";
    }

    return response;
  },

  async emailValidate(tenantId, payload) {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status, data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/activeDirectory/verifyUser/${tenantId}`,
        method: "POST",
        data: JSON.stringify(payload),
      });

      if (status !== 200) {
        throw "invalid status code";
      }

      response.data = data;
    } catch (e) {
      console.error(e);

      response.error = "error logging in";

      if (e.response.status === 404) {
        response.error = "user account not found";
      }
    }

    return response;
  },

  async signUp(payload) {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status, data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/tenant/signup`,
        method: "POST",
        data: JSON.stringify(payload),
      });

      if (status !== 201 && status !== 200) {
        throw "invalid status code";
      }

      if (data) {
        const json = Buffer.from(data, "base64").toString("utf8"); // base64 to JSON
        const identity = JSON.parse(json); // JSON to identity object
        console.log(identity, "identity");

        setToLocalStorage(identity, "identity");
        store.commit("setIdentity", identity);
        const { error } = await this.getSession();
        response.error = error;
        response.data = "Success";
      } else {
        response.error = "error in verify mail";
      }
    } catch (e) {
      console.error(e);
      response.error = "error in verify mail";
      if (e.response.status === 404) {
        response.error = e.response.data;
      }
    }

    return response;
  },

  async verifyMailOTP(payload) {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status, data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/tenant/validateOTP`,
        method: "POST",
        data: JSON.stringify(payload),
      });

      if (status !== 201 && status !== 200) {
        throw "invalid status code";
      }

      if (data == "success") {
        response.data = "Success";
      } else {
        response.error = "error in verify mail";
      }
    } catch (e) {
      console.error(e);
      response.error = "error in verify mail";
      if (e.response.status === 404) {
        response.error = e.response.data;
      }
    }

    return response;
  },

  async sendMailOTP(payload) {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status, data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/tenant/checkAuthenticate`,
        method: "POST",
        data: JSON.stringify(payload),
      });

      if (status !== 201 && status !== 200 && status !== 400) {
        throw "invalid status code";
      }

      if (data == "OTP sent succeeded" || data == "success") {
        response.data = "success";
      } else {
        response.error = "error in verify mail";
      }
    } catch (e) {
      response.error = e.response.data;
    }

    return response;
  },

  async externalLogin(tenantId, userId) {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status, data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/authentication/loginById/${tenantId}/WEB/${userId}`,
        method: "GET",
      });

      if (status !== 200) {
        throw "invalid status code";
      }
      const json = Buffer.from(data, "base64").toString("utf8"); // base64 to JSON
      const identity = JSON.parse(json); // JSON to identity object

      setToLocalStorage(identity, "identity");
      store.commit("setIdentity", identity);
      response.data = "Success";

      const { error } = await this.getSession();
      response.error = error;
    } catch (e) {
      console.error(e);
      response.error = "error in external login";
    }

    return response;
  },
};
