<template>
  <StateWrapper
    icon="mdi-cellphone-arrow-down"
    title="We are sorry, your screen size is too small"
    description="Application requires a minimum screen size of 1280 x 720 to work smoothly. Please download our Mobile app for smaller devices, or install our PWA via the browser or visit us on a device with a larger screen."
    action="download mobile app"
    action-icon="eva-download-outline"
  />
</template>

<script>
import StateWrapper from "./StateWrapper.vue";

export default {
  name: "UnsupportedDevice",

  components: { StateWrapper },

  props: {
    feature: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
