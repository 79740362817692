<template>
  <div id="mobile-authentication-field">
    <div id="text-field">
      <FormFieldLabel
        v-if="label"
        :label="label"
        is-mandatory
        :tooltip="tooltip"
      />

      <div class="input-group">
        <FormFieldWrapper
          :is-focused="isFocused"
          :is-clearable="_isClearable"
          :is-readonly="isReadonly"
          :is-disabled="isDisabled"
          :has-error="!!error"
          @clear="handleClear"
        >
          <input
            type="number"
            :value="value"
            :autofocus="autoFocus"
            :placeholder="placeholder"
            class="q-px-sm"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @input.stop="handleInput"
            @keypress.enter="$emit('enter')"
          />
        </FormFieldWrapper>

        <div class="input-group-btn">
          <BaseButton
            label="Generate OTP"
            color="gray"
            @click="
              otpVerify = true;
              clockTick = true;
            "
          />
        </div>
      </div>
      <FormFieldError v-if="error" :error="error" />
    </div>
    <OTPField
      v-if="otpVerify"
      otp-type="mobile"
      :otp-verify="otpVerify"
      :clock-tick="clockTick"
    ></OTPField>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import OTPField from "@/components/common/form/otp-field/OTPField.vue";

export default {
  name: "MobileAuthentication",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    OTPField,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    autoFocus: {
      type: Boolean,
      default: false,
    },

    otpVerify: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isFocused: false,
      clockTick: false,
    };
  },

  computed: {
    _isClearable() {
      if (!this.isClearable) {
        return false;
      }

      return !!this.value;
    },
  },

  methods: {
    handleInput(evt) {
      this.$emit("input", evt.target.value);
    },

    handleClear() {
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped>
#mobile-authentication-field {
  #field-wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  input {
    min-height: 46px;
  }

  .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
  }

  .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
  }

  .input-group-btn {
    display: table-cell;
    #button {
      min-height: 47px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
</style>
