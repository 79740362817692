<template>
  <NodeViewWrapper class="field-placeholder-wrapper">
    <div id="field-placeholder">
      <div class="field">
        {{ node.attrs.fieldType }}
      </div>

      <BaseIcon name="eva-close" color="red" class="icon" @click="deleteNode" />
    </div>
  </NodeViewWrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";

export default {
  name: "FieldPlaceholder",

  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,
};
</script>

<style lang="scss" scoped>
.field-placeholder-wrapper {
  display: inline-block;
  margin: 0px 4px;
}

#field-placeholder {
  display: flex;
  align-items: center;
  width: 120px;
  border-bottom: 1px solid var(--border-color);

  .field {
    flex: 1;
    color: var(--secondary);
    font-weight: 500;
    letter-spacing: 0.05em;
  }

  .icon {
    display: none;
  }

  &:hover {
    .icon {
      display: initial;
      cursor: pointer;
    }
  }
}
</style>
