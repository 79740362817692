<template>
  <div id="nested-list-table">
    <table>
      <thead>
        <tr>
          <th>Option</th>
          <th>Value</th>
          <th class="action"></th>
        </tr>
      </thead>

      <tbody>
        <template v-if="entries.length">
          <tr v-for="entry in entries" :key="entry.id">
            <td>
              <div class="q-ml-xs">{{ entry.label }}</div>
            </td>
            <td>
              <component :is="field" v-model="entry.value" />
            </td>
            <td>
              <div class="row items-center justify-center">
                <BaseActionButton
                  is-flat
                  color="red"
                  icon="eva-trash-2-outline"
                  no-border
                  @click="removeEntry(entry.id)"
                />
              </div>
            </td>
          </tr>

          <tr v-if="['NUMBER', 'COUNTER'].includes(nestedListFieldType)">
            <td></td>
            <td>
              <NumberField :value="getColumnSum" is-readonly />
            </td>
            <td></td>
          </tr>
        </template>

        <tr v-else>
          <td colspan="3" class="text-center">No entries found</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TextField from "@/components/common/form/text-field/TextField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";

export default {
  name: "NestedListTable",

  components: {
    TextField,
    NumberField,
    CounterField,
    DateField,
    TimeField,
    DateTimeField,
  },

  props: {
    entries: {
      type: Array,
      default: () => [],
    },

    nestedListFieldType: {
      type: String,
      default: "SHORT_TEXT",
    },
  },

  data() {
    return {
      fields: {
        SHORT_TEXT: "TextField",
        NUMBER: "NumberField",
        COUNTER: "CounterField",
        DATE: "DateField",
        TIME: "TimeField",
        DATE_TIME: "DateTimeField",
      },
    };
  },

  computed: {
    field() {
      return this.fields[this.nestedListFieldType];
    },

    getColumnSum() {
      return this.entries.reduce((acc, cur) => acc + cur.value, 0);
    },
  },

  methods: {
    removeEntry(entryId) {
      this.$emit("removeEntry", entryId);
    },
  },
};
</script>

<style lang="scss" scoped>
#nested-list-table {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 24px;

    tr {
      height: 48px;
    }

    th {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;
      color: var(--secondary);

      &.action {
        width: 48px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
    }

    td {
      padding: 4px;
    }
  }
}
</style>
