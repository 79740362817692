<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="validationRules"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <TextField
      :value="value1"
      :label="field.settings.general.hideLabel ? '' : field.label"
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :tooltip="tooltip"
      :error="errors[0]"
      :highlight="highlight"
      @input="handleInput"
      @verify="$emit('verify')"
      @focusOut="handleFocusOut"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "CurrencyFieldWrapper",

  components: { ValidationProvider, TextField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    filter: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      position: 0,
      value1: "",
    };
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this.contentRule === "BOTH") {
        rules.currency = true;
      } else if (this.contentRule === "COMMAS") {
        rules.commas = true;
      } else if (this.contentRule === "DECIMAL") {
        rules.decimal = true;
      } else {
        rules.integer = true;
      }

      return rules;
    },

    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    contentRule() {
      return this.field.settings.validation.contentRule;
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    _value1() {
      if (this.contentRule === "COMMAS" || this.contentRule === "BOTH") {
        let amount = this.value.split(".");
        let value = amount[0]
          .replace(new RegExp(",", "g"), "")
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // if()
        // console.log(value);
        if (amount[1]) {
          let value1 = value.replace(new RegExp(",", "g"), "");
          let format = `${Number(value1)}.${amount[1]}`;
          // console.log(format);
          let price = Number(format).toFixed(2);
          let price1 = price
            .replace(new RegExp(",", "g"), "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return price1;
        }
        return value;
      } else if (this.contentRule === "DECIMAL") {
        let decimal = this.value.split(".");
        // console.log(decimal.length, decimal);
        if (decimal.length == 2) {
          let price = this.value;
          if (decimal[1]) {
            price = Number(this.value).toFixed(2);
          }

          return price;
        } else {
          return this.value;
        }
      }

      return this.value;
    },
  },

  watch: {
    filter: {
      // immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          }
        }
      },
    },

    value() {
      this.value1 = this.value;
    },

    field: {
      immediate: true,
      // deep: true,
      handler() {
        if (!this.value) {
          if (this.field.settings.specific.masterFormParentColumn) {
            this.$emit(
              "getParentColumnValue",
              this.field.settings.specific.masterFormParentColumn
            );
          }
        }
      },
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    async getMasterEntriesFilter(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: field.filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          if (options.length === 1) {
            this.handleInput(options[0]);
          } else {
            this.handleInput(0);
          }
          this.$emit("clearFilter");
        } else {
          this.handleInput(0);
          this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    currencyValidation() {
      if (this.contentRule === "COMMAS" || this.contentRule === "BOTH") {
        let amount = this.value.split(".");
        let value = amount[0]
          .replace(new RegExp(",", "g"), "")
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // if()
        // console.log(value);
        if (amount[1]) {
          let value1 = value.replace(new RegExp(",", "g"), "");
          let format = `${Number(value1)}.${amount[1]}`;
          // console.log(format);
          let price = Number(format).toFixed(2);
          let price1 = price
            .replace(new RegExp(",", "g"), "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return price1;
        }
        return value;
      } else if (this.contentRule === "DECIMAL") {
        let decimal = this.value.split(".");
        // console.log(decimal.length, decimal);
        if (decimal.length == 2) {
          let price = this.value;
          if (decimal[1]) {
            price = Number(this.value).toFixed(2);
          }

          return price;
        } else {
          return this.value;
        }
      }

      return this.value;
    },

    handleFocusOut() {
      this.value1 = this.currencyValidation();
    },
  },
};
</script>

<style lang="scss" scoped></style>
