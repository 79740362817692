import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

/**
 * Function to check whether the given string is a valid date string,
 * this function uses the day.js library to parse the string
 *
 * @param date The string to check
 * @returns True if the string is a valid date string else return false
 *
 * eg. 2022-01-26 (valid) / 2022-13-45 (invalid)
 */

export function isValidDate(date) {
  if (!date) {
    return true;
  }

  // should be in the format (yyyy/mm/dd)

  const regEx = /[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/g;
  const isValidFormat = regEx.test(date);

  if (!isValidFormat) {
    console.error(`${date} - date should be in "YYYY-MM-DD" format`);
    return false;
  }

  // should be a valid date

  const isValid = dayjs(date, "YYYY-MM-DD", true).isValid();

  if (!isValid) {
    console.error(`${date} - invalid date`);
    return false;
  }

  return true;
}
