import { axiosCrypto } from "./axios";

export default {
  async getPortalList(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/portal/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching portals";
    }

    return _response;
  },

  async getPortal(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/portal/${payload}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching portal";
    }

    return _response;
  },

  async createPortal(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/portal",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      if (e.response?.status === 406) {
        _response.error = "portal with the given name already exists";
      } else {
        _response.error = "error creating portal";
      }
    }

    return _response;
  },

  async updatePortal(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/portal/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      if (e.response?.status === 404) {
        _response.error = "portal with the given id is not found";
      } else if (e.response?.status === 406) {
        _response.error = "portal with the given name already exists";
      } else {
        _response.error = "error updating portal";
      }
    }

    return _response;
  },
};
