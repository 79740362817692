import LoadingBar from "@/components/common/LoadingBar.vue";

export default {
  install(Vue) {
    let instance = null;

    Vue.prototype.$loadingBar = {
      start() {
        const loadingBarInstance = Vue.extend(LoadingBar);
        instance = new loadingBarInstance();

        instance.$mount(
          document.body.appendChild(document.createElement("div"))
        );
      },

      stop() {
        instance.$destroy();
        document.body.removeChild(document.getElementById("loading-bar"));
      },
    };
  },
};
