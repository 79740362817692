<template>
  <div id="file-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <path
          style="fill: var(--file-bg-color)"
          d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
        />
        <path
          style="fill: var(--file-corner-color)"
          d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
        />
        <path
          :style="{ fill: fileMimeColor }"
          d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
        />
        <svg x="6%" y="47%" width="75%" height="37.5%">
          <g>
            <text
              font-weight="600"
              fill="white"
              x="50%"
              y="50%"
              font-size="128px"
              style="
                dominant-baseline: central;
                text-anchor: middle;
                text-transform: uppercase;
                letter-spacing: 0.05em;
              "
            >
              {{ mimeType }}
            </text>
          </g>
        </svg>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "FileIcon",

  props: {
    mimeType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      color: {
        pdf: "red",
        doc: "blue",
        docx: "blue",
        xls: "teal",
        xlsx: "teal",
        ppt: "deep-orange",
        pptx: "deep-orange",
        tiff: "indigo",
        tif: "indigo",
        jpg: "cyan",
        jpeg: "cyan",
        png: "green",
      },
    };
  },

  computed: {
    fileMimeColor() {
      const color = this.color[this.mimeType] || "secondary";
      return `var(--${color})`;
    },
  },
};
</script>

<style lang="scss" scoped>
#file-icon {
  height: 100%;
  width: 100%;
}
</style>
