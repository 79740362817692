import { axiosCrypto } from "./axios";

export default {
  async getWorkflow(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/workflow/${payload}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow";
    }

    return _response;
  },

  async getWorkflows(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/workflow/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflows";
    }

    return _response;
  },

  async createWorkflow(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/workflow",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);

      if (e.response?.status === 406) {
        _response.error = "workflow with the given name already exists";
      } else {
        _response.error = "error creating workflow";
      }
    }

    return _response;
  },

  async updateWorkflow(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/workflow/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      if (e.response?.status === 404) {
        _response.error = "workflow with the given id is not found";
      } else if (e.response?.status === 406) {
        _response.error = "workflow with the given name already exists";
      } else {
        _response.error = "error updating workflow";
      }
    }

    return _response;
  },

  async getWorkflowList(criteria = "", value = "") {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/workflow/list",
        JSON.stringify({ criteria, value })
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflows";
    }

    return _response;
  },

  async getUserMyInbox() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/workflow/myInboxCount`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching user workflow";
    }

    return _response;
  },

  async getUserWorkflow() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/workflow/listByUserId`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching user workflow";
    }

    return _response;
  },

  async getUserWorkflowTest() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/workflowTest/listByUserId`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching user workflow";
    }

    return _response;
  },

  async processTransaction(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/workflow/transaction",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error requesting process";
    }

    return _response;
  },

  async getMyInboxList(payload, workflowId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      let functionURL = "";
      if (workflowId) {
        functionURL = `/workflow/myInboxList/${workflowId}`;
      } else {
        functionURL = `/workflow/myInboxList`;
      }
      const response = await axiosCrypto.post(
        functionURL,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching users my inbox";
    }

    return _response;
  },

  async getCommonList(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/runningList/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching common list";
    }

    return _response;
  },

  async getInboxList(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/inboxList/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching inbox list";
    }

    return _response;
  },

  async getInboxListTest(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflowTest/inboxList/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching inbox list";
    }

    return _response;
  },

  async getSentList(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/processList/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching sent list";
    }

    return _response;
  },

  async getSentListTest(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflowTest/processList/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching sent list";
    }

    return _response;
  },

  async getCompletedList(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/completedList/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching completed list";
    }

    return _response;
  },

  async getCompletedListTest(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflowTest/completedList/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching completed list";
    }

    return _response;
  },

  async getPaymentList(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/paymentProcessList/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching payment list";
    }

    return _response;
  },

  async getProcessComments(workflowId, processId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/workflow/comments/${workflowId}/${processId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching comments";
    }

    return _response;
  },

  async insertProcessComment(workflowId, processId, transactionId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/comments/${workflowId}/${processId}/${transactionId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error saving comments";
    }

    return _response;
  },

  async groupRequestAction(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/transactionBulk`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error in group process request";
    }

    return _response;
  },

  async processHistory(workflowId, processId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/workflow/processHistory/${workflowId}/${processId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error in getting process history";
    }

    return _response;
  },

  async overviewChart(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/overview/workflow/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error in overview chart";
    }

    return _response;
  },

  async getAttachments(workflowId, processId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/workflow/attachmentList/${workflowId}/${processId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching attachments";
    }

    return _response;
  },

  async saveProcessSignature(workflowId, processId, transactionId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/signWithProcessId/${workflowId}/${processId}/${transactionId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error saving signature";
    }

    return _response;
  },

  async getProcessSignature(workflowId, processId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/workflow/signWithProcessIdList/${workflowId}/${processId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching signature";
    }

    return _response;
  },

  async getTaskEntries(workflowId, processId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/taskList/${workflowId}/${processId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching task list";
    }

    return _response;
  },

  async insertTaskEntry(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/task`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error saving tasks";
    }

    return _response;
  },

  async overviewTableReport(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/overview/workflowSum/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error in overview report";
    }

    return _response;
  },

  async getMailTransactions(workflowId, processId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/MailSettings/mailTransaction/${workflowId}/${processId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error in getting mail transactions";
    }

    return _response;
  },

  async resendMail(mailId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/MailSettings/reSendMail/${mailId}`
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error in resending email";
    }

    return _response;
  },

  async documentMerge(
    workflowId,
    processId,
    transactionId,
    repositoryId,
    payload
  ) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/file/mergeFiles/${workflowId}/${processId}/${transactionId}/${repositoryId}`,
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error in document merge";
    }

    return _response;
  },

  async insertProcessComment_Jira(issueId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/commentsByissueId/${issueId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error saving comments";
    }

    return _response;
  },

  async getKanbanViewSettings(workflowId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/workflow/kanbanView/${workflowId}`
      );
      const { status, data } = response;

      if (status !== 200 && status !== 204) {
        throw response;
      }
      if (status === 200) _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching kanban view";
    }

    return _response;
  },

  async reopenRequest(workflowId, processId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      let functionURL = "";
      if (processId) {
        functionURL = `/transaction/reOpenTicket/${workflowId}/${processId}`;
      } else {
        functionURL = `/transaction/reOpenTicket/${workflowId}`;
      }
      const response = await axiosCrypto.post(
        functionURL,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      if (e.response.status === 400) {
        _response.error = e.response.data;
      } else {
        console.error(e);
        _response.error = "error in reopen request";
      }
    }

    return _response;
  },

  async getSubWorkflow(workflowId, processId) {
    const _response = {
      payload: "",

      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/workflow/subWorkflow/${workflowId}/${processId}`
      );

      const { status, data } = response;

      if (status !== 200 && status !== 204) {
        throw response;
      }

      if (status === 200) _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);

      _response.error = "error fetching sub workflow list";
    }

    return _response;
  },

  async addSubWorkflow(payload) {
    const _response = {
      payload: "",

      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/workflow/postSubWorkflow`,

        JSON.stringify(payload)
      );

      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);

      _response.error = "error saving  on sub workflow";
    }

    return _response;
  },

  async edtSubWorkflow(id, workflowId, subWorkflowId, payload) {
    const _response = {
      payload: "",

      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/workflow/putSubWorkflow/${id}/${workflowId}/${subWorkflowId}`,

        JSON.stringify(payload)
      );

      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      if (status === 202) _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);

      _response.error = "error saving on sub workflow";
    }

    return _response;
  },

  async updateRequestReserved(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/client/ticketLock`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error updating reserved request";
    }

    return _response;
  },

  async getProcess(workflowId, processId, transactionId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/transaction/rowInfo/${workflowId}/${processId}/${transactionId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching process";
    }

    return _response;
  },

  async getWorkflowRecommendation(workflowId, processId, transactionId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/workflow/workflowSummary/${workflowId}/${processId}/${transactionId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow recommentation";
    }

    return _response;
  },

  async calculateAge(workflowId, processId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/client/calculateAge/${workflowId}/${processId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching calculate age";
    }

    return _response;
  },
};
