const UserRoutes = [
  {
    path: "/users/",
    meta: { menu: "settings" },
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/users/Users.vue"),
    children: [
      {
        path: "overview",
        name: "users-overview",
        meta: { menu: "settings", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "users-overview" */ "@/views/users/views/UsersOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "users-browse",
        meta: { menu: "user", breadcrumb: "browse" },
        props: { mode: "BROWSE" },
        component: () =>
          import(
            /* webpackChunkName: "users-browse" */ "@/views/users/views/users-browse-and-trash/UsersBrowseAndTrash.vue"
          ),
      },
      {
        path: "trash",
        name: "users-trash",
        meta: { menu: "user", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "users-trash" */ "@/views/users/views/users-browse-and-trash/UsersBrowseAndTrash.vue"
          ),
      },
    ],
  },
  {
    path: "/users/builder/:id?",
    name: "user-builder",
    meta: { menu: "settings" },
    props: (route) => ({ userId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "user-builder" */ "@/views/users/views/user-builder/UserBuilder.vue"
      ),
  },
];

export default UserRoutes;
