<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="{ required: !_isReadonly ? isMandatory : false }"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <MultiSelectField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :error="errors[0]"
      :options="options"
      :new-option="allowToAddNewOptions"
      :highlight="highlight"
      @input="handleInput"
      @verify="$emit('verify')"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import { axiosCrypto } from "@/api/axios.js";
import { user, workspace } from "@/api/factory.js";

export default {
  name: "MultiSelectFieldWrapper",

  components: { ValidationProvider, MultiSelectField },

  props: {
    value: {
      type: Array,
      required: true,
    },

    formId: {
      type: String,
      default: "0",
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    formSettings: {
      type: Object,
      required: true,
    },

    filter: {
      type: Number,
      default: 0,
    },

    highlight: {
      type: String,
      default: "",
    },

    parentControl: {
      type: Boolean,
      default: false,
    },

    enableControls: {
      type: Object,
      default: () => {},
    },

    visibleControls: {
      type: Array,
      default: () => [],
    },

    hideControls: {
      type: Array,
      default: () => [],
    },

    fieldsModel: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      options: [],
      selectedValue: [],
      mandatorySettings: [],
      readonlySettings: [],
    };
  },

  computed: {
    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    allowToAddNewOptions() {
      return this.field.settings.specific.allowToAddNewOptions;
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          } else if (this.field.settings.specific.repositoryId) {
            this.getRepositoryDataFilter(this.field);
          }
        }
      },
    },

    value: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.value.length) {
          this.selectedValue = [];
        } else if (this.value) {
          this.value.forEach((item) => {
            let optVal = this.options.find((row) => row.label === item);
            if (!optVal) {
              if (item) {
                this.options.push({
                  id: this.$nano.id(),
                  label: item,
                  value: item,
                });
              }
            }
          });
        }
      },
    },
  },

  created() {
    const optionsType = this.field.settings.specific.optionsType;
    if (optionsType === "CUSTOM") {
      this.options = this.getCustomOptions();
    } else if (
      this.formId &&
      optionsType === "EXISTING" &&
      this.formSettings.publish.publishOption === "PUBLISHED"
    ) {
      this.getOptionFromEntries(this.field);
    } else if (optionsType === "PREDEFINED") {
      if (this.field.settings.specific.predefinedTable === "User") {
        this.getUsers();
      } else if (this.field.settings.specific.predefinedTable === "Workspace") {
        this.getWorkspace();
      }
    } else if (optionsType === "MASTER") {
      if (!this.value.length) {
        this.getMasterEntries(
          this.field.settings.specific.masterFormId,
          this.field.settings.specific.masterFormColumn
        );
      } else {
        if (this.field.settings.specific.masterFormParentColumn) {
          this.$emit(
            "getParentColumnValue",
            this.field.settings.specific.masterFormParentColumn
          );
        } else {
          this.getMasterEntries(
            this.field.settings.specific.masterFormId,
            this.field.settings.specific.masterFormColumn
          );
        }
      }
    } else if (optionsType === "REPOSITORY") {
      this.getRepositoryData(
        this.field.settings.specific.repositoryId,
        this.field.settings.specific.repositoryField
      );
    }

    this.mandatorySettings = this.field.settings.validation.mandatorySettings;
    if (this.mandatorySettings && this.mandatorySettings.length) {
      // if (this.value) {
      this.setMandatory(this.value, "immediate");
      // }
    }

    this.readonlySettings = this.field.settings.validation.readonlySettings;
    if (this.readonlySettings && this.readonlySettings.length) {
      this.setReadOnly(this.value, "immediate");
    }
  },

  methods: {
    handleInput(value, unSelectOption) {
      this.$emit("input", value);
      this.setMandatory(value);
      this.setReadOnly(value);
      if (this.parentControl) {
        this.$emit("filterBy", this.field);
      }
      if (this.enableControls) {
        if (Object.keys(this.enableControls).length) {
          if (this.enableControls.controls.length) {
            let index = this.enableControls.controls.findIndex(
              (row) => row.id === this.field.id
            );
            if (index > -1) {
              let showControls = this.visibleControls;
              if (!value.length) {
                showControls = [];
                this.$emit("update:hideControls", []);
              }

              unSelectOption.forEach((item) => {
                let control = this.enableControls.controls[
                  index
                ].parentControls.find((item1) => item === item1.value);

                control.childControls.forEach((child) => {
                  if (showControls.indexOf(child) > -1) {
                    this.hideControls.push(child);
                    showControls.splice(showControls.indexOf(child), 1);
                  }
                });
              });

              value.forEach((item) => {
                let control = this.enableControls.controls[
                  index
                ].parentControls.find((item1) => item === item1.value);
                if (control) {
                  if (this.selectedValue.indexOf(item) === -1) {
                    this.selectedValue.push(item);
                    showControls.push(...control.childControls);
                  }
                }
              });

              this.$emit("update:hideControls", this.hideControls);
              this.$emit("updateVisibleControls", showControls);
            }
          }
        }
      }
    },

    getCustomOptions() {
      const separator =
        this.field.settings.specific.separateOptionsUsing === "NEWLINE"
          ? "\n"
          : ",";

      return this.field.settings.specific.customOptions
        .split(separator)
        .map((option) => ({
          id: this.$nano.id(),
          label: option.trim(),
          value: option.trim(),
        }));
    },

    async getOptionFromEntries(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${this.formId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.id,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        let optionArray = [];
        options.forEach((option) => {
          let valueArray = JSON.parse(option);
          valueArray.forEach((row) => {
            if (!optionArray.includes(row)) {
              optionArray.push(row);
            }
          });
        });
        this.options = optionArray.map((option) => ({
          id: this.$nano.id(),
          label: option,
          value: option,
        }));
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getUsers() {
      const { error, payload } = await user.getUserList();
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (!payload) {
        return;
      }
      this.options = payload.map((user) => ({
        id: this.$nano.id(),
        label: user.value || user.loginName,
        value: user.value || user.loginName,
      }));
    },

    async getWorkspace() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workspace.getWorkspaceList();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.options = payload.map((workspace) => ({
        id: this.$nano.id(),
        label: workspace.value,
        value: workspace.value,
      }));
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          const parsedArray = [];
          options.forEach((item) => {
            try {
              const jsonString = item.trim();
              const array = JSON.parse(jsonString);
              array.forEach((value) => {
                if (!parsedArray.includes(value)) {
                  parsedArray.push(value);
                }
              });
            } catch (error) {
              if (!parsedArray.includes(item)) {
                parsedArray.push(item);
              }
            }
          });
          this.options = parsedArray.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getMasterEntriesFilter(field) {
      let filterBy = [];
      if (field.filterBy) {
        field.filterBy.forEach((item) => {
          filterBy.push({
            ...item,
            value:
              typeof item.value === "object"
                ? JSON.stringify(item.value)
                : item.value,
            dataType: typeof item.value === "object" ? "SINGLE_SELECT" : "",
          });
        });
      }
      // console.log(filterBy);

      try {
        const response = await axiosCrypto.post(
          `/form/${field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          const parsedArray = [];
          options.forEach((item) => {
            try {
              const jsonString = item.trim();
              const array = JSON.parse(jsonString);
              array.forEach((value) => {
                if (!parsedArray.includes(value)) {
                  parsedArray.push(value);
                }
              });
            } catch (error) {
              if (!parsedArray.includes(item)) {
                parsedArray.push(item);
              }
            }
          });
          this.options = parsedArray.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          if (!this.value || this.field.isManualChange) {
            if (this.options.length === 1) {
              //this.$emit("input", parsedArray);
            } else {
              this.$emit("input", []);
            }
            this.$emit("clearFilter");
          }
        } else {
          if (this.value.length) {
            this.options = [
              {
                id: this.$nano.id(),
                label: this.value,
                value: this.value,
              },
            ];
            this.$emit("input", [this.value[0]]);
            this.$emit("clearFilter");
          } else {
            this.$emit("input", []);
            this.$emit("clearFilter");
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getRepositoryData(repositoryId, field) {
      try {
        const response = await axiosCrypto.post(
          `/repository/${repositoryId}/uniqueColumnValues`,
          JSON.stringify({
            column: field,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getRepositoryDataFilter(field) {
      let filterBy = [];
      if (field.filterBy) {
        field.filterBy.forEach((item) => {
          filterBy.push({
            ...item,
            value:
              typeof item.value === "object"
                ? JSON.stringify(item.value)
                : item.value,
            dataType: typeof item.value === "object" ? "SINGLE_SELECT" : "",
          });
        });
      }

      try {
        const response = await axiosCrypto.post(
          `/repository/${field.settings.specific.repositoryId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.repositoryField,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          if (this.options.length === 1) {
            this.$emit("input", [this.options[0].value]);
          } else {
            this.$emit("input", []);
          }
          this.$emit("clearFilter");
        } else {
          if (this.value.length) {
            this.options = [
              {
                id: this.$nano.id(),
                label: this.value,
                value: this.value,
              },
            ];
            this.$emit("input", [this.value[0]]);
            this.$emit("clearFilter");
          } else {
            this.$emit("input", []);
            this.$emit("clearFilter");
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    setMandatory(value, immediate) {
      if (this.mandatorySettings) {
        if (this.mandatorySettings.length) {
          let hasControls = [];
          if (value.length) {
            for (let option of value) {
              let controls = this.mandatorySettings.find(
                (control) => control.value.trim() === option.trim()
              );
              if (controls) {
                hasControls.push(...controls.controls);
              }
            }
          } else {
            this.$emit(
              "mandatoryControls",
              [],
              this.mandatorySettings,
              immediate,
              this.field.id
            );
          }
          this.$emit(
            "mandatoryControls",
            hasControls,
            this.mandatorySettings,
            immediate,
            this.field.id
          );
        }
      }
    },

    setReadOnly(value, immediate) {
      if (this.readonlySettings) {
        if (this.readonlySettings.length) {
          let hasControls = [];
          if (value.length) {
            for (let option of value) {
              let controls = this.readonlySettings.find(
                (control) => control.value.trim() === option.trim()
              );
              if (controls) {
                // this.$emit(
                //   "readonlyControls",
                //   controls.controls,
                //   this.readonlySettings
                // );
                hasControls.push(...controls.controls);
              } else {
                this.$emit(
                  "readonlyControls",
                  [],
                  this.readonlySettings,
                  immediate,
                  this.field.id
                );
              }
            }
          } else {
            this.$emit(
              "readonlyControls",
              [],
              this.readonlySettings,
              immediate,
              this.field.id
            );
          }
          this.$emit(
            "readonlyControls",
            hasControls,
            this.readonlySettings,
            immediate,
            this.field.id
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
