import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    darkTheme: false,
    identity: null,
    session: null,
    loadingBar: false,
    loadingBarPage: false,
    profileMenus: [],
    defaultView: {},
    preferenceId: 0,
    searchInput: {},
    loadingBarSave: false,
    comment: true,
  },

  mutations: {
    toggleDarkTheme(state) {
      state.darkTheme = !state.darkTheme;
    },

    setIdentity(state, payload) {
      state.identity = payload;
    },

    setSession(state, payload) {
      state.session = payload;
    },

    showLoadingBar(state) {
      state.loadingBar = true;
    },

    hideLoadingBar(state) {
      state.loadingBar = false;
    },

    showLoadingBarPage(state) {
      state.loadingBarPage = true;
    },

    hideLoadingBarPage(state) {
      state.loadingBarPage = false;
    },

    showLoadingBarSave(state) {
      state.loadingBarSave = true;
    },

    hideLoadingBarSave(state) {
      state.loadingBarSave = false;
    },

    setProfileMenu(state, payload) {
      state.profileMenus = payload;
    },

    setDefaultView(state, payload) {
      state.defaultView = payload;
    },

    setPreferenceId(state, payload) {
      state.preferenceId = payload;
    },

    setSearchInput(state, payload) {
      state.searchInput = payload;
    },

    setComment(state) {
      state.comment = false;
    },
  },

  actions: {},

  modules: {},
});
