<template>
  <!-- <FormFieldLabel
    no-margin
    :label="field.settings.general.hideLabel ? '' : field.label"
    :is-mandatory="isMandatory"
    :tooltip="tooltip"
    :class="size"
  /> -->
  <div id="field-url" class="no-margin">
    <div class="label">
      <!-- {{ field.label }} -->
      <a :href="field.settings.general.url" target="_blank">{{
        field.label
      }}</a>
    </div>

    <BaseIcon
      v-if="tooltip"
      v-tooltip.top="tooltip"
      inherit-color
      name="eva-question-mark-circle"
      class="info-icon"
    />
  </div>
</template>

<script>
// import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";

export default {
  name: "UrlFieldWrapper",

  components: {},

  props: {
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    size() {
      return this.field.settings.general.size;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },
  },
};
</script>

<style lang="scss" scoped>
#field-url {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 1.25rem;
  cursor: pointer;

  &.no-margin {
    margin: 0px;
  }

  .label {
    color: var(--icon-color);
    text-decoration: underline;
    font-weight: 500;
    &.transform {
      text-transform: capitalize;
    }
  }

  .optional {
    color: var(--icon-color-inverted);
    margin-left: 3px;
  }

  .required {
    color: red;
    margin-left: 3px;
  }

  .info-icon {
    margin-left: 8px;
    cursor: pointer;
    color: var(--icon-color-inverted);
  }
}
</style>
