<template>
  <div
    id="inline-date-field"
    :class="{
      'is-focused': isFocused,
      'has-error': error,
      'is-readonly': isReadonly,
      'is-disabled': isDisabled,
    }"
  >
    <div class="row items-center col">
      <input
        v-imask="dateMask"
        type="text"
        :value="date"
        :placeholder="placeholder"
        @click.stop=""
        @focus="isFocused = true"
        @blur="isFocused = false"
        @accept="handleAccept"
        @complete="handleComplete"
      />

      <BaseIcon
        name="eva-calendar-outline"
        class="cursor-pointer"
        @click="toggleDatePicker"
      />

      <!-- date picker -->

      <q-menu
        v-model="datePicker"
        no-refocus
        no-parent-event
        transition-show="scale"
        transition-hide="scale"
      >
        <q-date minimal mask="YYYY-MM-DD" :value="value" @input="handleInput" />
      </q-menu>

      <!-- ... -->

      <BaseIcon
        v-if="error"
        v-tooltip:red.top="error"
        color="red"
        name="mdi-information-outline"
        class="cursor-pointer q-ml-sm"
      />
    </div>
  </div>
</template>

<script>
import { IMaskDirective } from "vue-imask";
import dateMask from "@/components/common/form/date-field/date-mask.js";

export default {
  name: "DateField",

  directives: {
    imask: IMaskDirective,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFocused: false,
      datePicker: false,
      date: "",
      dateMask: dateMask,
    };
  },

  computed: {
    placeholder() {
      return this.label || "Date";
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.date = this.value;
      },
    },
  },

  methods: {
    toggleDatePicker() {
      this.datePicker = !this.datePicker;
    },

    handleInput(value) {
      this.$emit("input", value);
    },

    handleAccept(evt) {
      this.date = evt.detail.value;
    },

    handleComplete(evt) {
      this.$emit("input", evt.detail.value);
    },
  },
};
</script>

<style lang="scss" scoped>
#inline-date-field {
  display: inline-flex;
  align-items: center;
  width: 200px;
  margin-left: 8px;
  margin-right: 8px;
  border-bottom: 1px solid var(--border-color);

  input {
    flex: 1;
    color: var(--secondary);
  }

  &.has-error {
    border-bottom: 1px solid var(--red);

    input,
    input::placeholder {
      color: var(--red);
    }
  }

  &.is-focused {
    border-bottom: 1px solid var(--secondary);
  }
}
</style>
