<template>
  <div id="divider" :class="_type"></div>
</template>

<script>
import { lowerCase } from "lodash-es";

export default {
  name: "DividerField",

  props: {
    type: {
      type: String,
      default: "SOLID",
    },
  },

  computed: {
    _type() {
      return lowerCase(this.type);
    },
  },
};
</script>

<style lang="scss" scoped>
#divider {
  margin: 16px 0px;

  &.solid {
    border-top: 1px solid var(--divider-color);
  }

  &.dashed {
    border-top: 1px dashed var(--border-color);
  }

  &.dotted {
    border-top: 1px dotted var(--border-color);
  }

  &.double {
    border-top: 6px double var(--divider-color);
  }
}
</style>
