import { IMask } from "vue-imask";

export default {
  mask: Date,
  lazy: true,
  pattern: "Y-M-D",
  blocks: {
    D: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
    },
    M: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    Y: {
      mask: IMask.MaskedRange,
      from: 1000,
      to: 9999,
    },
  },
  format: function (date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;

    return [year, month, day].join("-");
  },
  parse: function (str) {
    var yearMonthDay = str.split("-");
    return new Date(yearMonthDay[0], yearMonthDay[1] - 1, yearMonthDay[2]);
  },
};
