import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/index.js";
import { getFromLocalStorage } from "../helpers/local-storage.js";

import AuthRoutes from "./auth.js";
import DashboardRoutes from "./dashboard.js";
import WorkspaceRoutes from "./workspace.js";
import RepositoryRoutes from "./repository.js";
import PortalRoutes from "./portal.js";
import TaskRoutes from "./task.js";
import FormRoutes from "./form.js";
import WorkflowRoutes from "./workflow.js";
import UserRoutes from "./user.js";
import GroupRoutes from "./group.js";
import ReportRoutes from "./report.js";
import SettingsRoutes from "./settings.js";
import CaptureRoutes from "./capture.js";
import IntegrationRoutes from "./integration.js";
import CustomRoutes from "./custom.js";
import ConnectRoutes from "./dbconnect.js";
import ProfileRoutes from "./profile.js";
import LeaveRoutes from "./leave.js";
import MobileUploadRoutes from "./mobile-upload.js";

Vue.use(VueRouter);

const routes = [
  ...AuthRoutes,
  ...DashboardRoutes,
  ...WorkspaceRoutes,
  ...RepositoryRoutes,
  ...PortalRoutes,
  ...TaskRoutes,
  ...FormRoutes,
  ...WorkflowRoutes,
  ...UserRoutes,
  ...GroupRoutes,
  ...ReportRoutes,
  ...SettingsRoutes,
  ...CaptureRoutes,
  ...IntegrationRoutes,
  ...CustomRoutes,
  ...ConnectRoutes,
  ...ProfileRoutes,
  ...LeaveRoutes,
  ...MobileUploadRoutes,
  {
    path: "/scratch-pad",
    name: "scratchPad",
    component: () =>
      import(/* webpackChunkName: "scratch-pad" */ "@/views/ScratchPad.vue"),
  },

  {
    path: "/dev-status",
    name: "devStatus",
    component: () =>
      import(
        /* webpackChunkName: "dev-status" */ "@/views/dev-status/DevStatus.vue"
      ),
  },

  {
    path: "/charts",
    name: "charts",
    component: () =>
      import(/* webpackChunkName: "charts" */ "@/views/charts/Charts.vue"),
  },

  {
    path: "/search",
    name: "search",
    // props: (route) => ({
    //   type: route.query.type,
    //   name: route.query.name,
    // }),
    props: true,
    component: () =>
      import(/* webpackChunkName: "search" */ "@/views/search/Search.vue"),
  },
  {
    path: "/reports/data-capture",
    name: "repositories-ocr-report",
    meta: { menu: "folders", breadcrumb: "Data Capture" },
    component: () =>
      import(
        /* webpackChunkName: "ocr-reports" */ "@/views/repositories/views/repositories-ocr-report/RepositoriesOcrReport.vue"
      ),
  },
  {
    path: "/reports/activity",
    name: "activity-reports",
    meta: { menu: "folders", breadcrumb: "activity" },
    props: { mode: "" },
    component: () =>
      import(
        /* webpackChunkName: "activity-reports" */ "@/views/repositories/views/repositories-activity-report/RepositoriesActivityReport.vue"
      ),
  },
  {
    path: "/reports/signature",
    name: "repositories-signature-report",
    meta: { menu: "folders", breadcrumb: "signature" },
    component: () =>
      import(
        /* webpackChunkName: "signature-reports" */ "@/views/repositories/views/repositories-signature-report/SignatureReport.vue"
      ),
  },
  {
    path: "/slack/authenticate",
    name: "slack-authenticate",
    meta: { menu: "connector", breadcrumb: "slack" },
    props: { mode: "" },
    component: () =>
      import(
        /* webpackChunkName: "slack-authenticate" */ "@/views/connector/SlackConnection.vue"
      ),
  },
  {
    path: "/getAToken",
    name: "teams-authenticate",
    meta: { menu: "connector", breadcrumb: "teams" },
    props: { mode: "" },
    component: () =>
      import(
        /* webpackChunkName: "teams-authenticate" */ "@/views/connector/TeamsConnection.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// route gaurd

router.beforeEach((to, _, next) => {
  if (
    to.name === "form-entries-view" ||
    to.name === "form-view" ||
    to.name === "connect"
  ) {
    next();
  } else if (to.name === "portals-signin" || to.name === "portals-access") {
    next();
  } else if (
    to.name === "external-request" ||
    to.name === "external-request-history" ||
    to.name === "external-preview" ||
    to.name === "mobile-upload"
  ) {
    next();
  } else {
    const identity = getFromLocalStorage("identity");
    const session = getFromLocalStorage("session");

    if (identity && session && session.loginType !== "Portal") {
      store.commit("setIdentity", identity);
      store.commit("setSession", session);
      // console.log(store.state.session.role);
      if (to.name === "signIn" || to.name === "signup") {
        if (store.state.session.role === "ADMINISTRATOR") {
          // console.log(store.state.profileMenus);
          if (store.state.profileMenus.length) {
            if (store.state.profileMenus[1].workspaces) {
              next("workspaces/overview");
            } else {
              next("dashboard");
            }
          } else {
            next("workspaces/overview");
          }
        } else {
          next("dashboard");
        }
      } else {
        next();
      }
    } else {
      to.name === "signIn"
        ? next()
        : to.name === "signup"
        ? next()
        : next({ name: "signIn" });
    }
  }
});

export default router;
