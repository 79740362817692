const TaskRoutes = [
  {
    path: "/tasks",
    name: "tasks",
    meta: { menu: "tasks" },
    component: () =>
      import(/* webpackChunkName: "tasks" */ "@/views/tasks/Tasks.vue"),
    children: [
      {
        path: "overview",
        name: "tasks-overview",
        meta: { menu: "tasks", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "tasks-overview" */ "@/views/tasks/views/TasksOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "tasks-browse",
        meta: { menu: "tasks", breadcrumb: "browse" },
        props: { mode: "BROWSE" },
        component: () =>
          import(
            /* webpackChunkName: "tasks-browse" */ "@/views/tasks/views/tasks-browse-and-trash/TasksBrowseAndTrash.vue"
          ),
      },
      {
        path: "trash",
        name: "tasks-trash",
        meta: { menu: "tasks", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "tasks-trash" */ "@/views/tasks/views/tasks-browse-and-trash/TasksBrowseAndTrash.vue"
          ),
      },
    ],
  },
];

export default TaskRoutes;
