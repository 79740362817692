/**
 * Function to validate the given color string
 * is a valid quasar color
 *
 * @param color
 * @returns true if the color exists else false
 */

export default function colorValidator(color) {
  return color.match(/[0-9]/g) ? true : true; // no shades
  // return color.match(/[0-9]/g) ? false : true; // no shades
}
