<template>
  <ValidationProvider v-slot="{ errors }" :name="field.label" class="col-12">
    <MatrixField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :error="errors[0]"
      :matrix-columns="matrixColumns"
      :matrix-rows="matrixRows"
      :matrix-type="matrixType"
      :matrix-type-settings="matrixTypeSettings"
      :secondary-panels="secondaryPanels"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import MatrixField from "@/components/common/form/matrix-field/MatrixField.vue";

export default {
  name: "MatrixFieldWrapper",

  components: { ValidationProvider, MatrixField },

  props: {
    value: {
      type: Array,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    isReadonly() {
      return this.field.settings.general.visibility === "READ_ONLY";
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    matrixColumns() {
      return this.field.settings.specific.matrixColumns;
    },

    matrixRows() {
      return this.field.settings.specific.matrixRows;
    },

    matrixType() {
      return this.field.settings.specific.matrixType;
    },

    matrixTypeSettings() {
      return this.field.settings.specific.matrixTypeSettings;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
