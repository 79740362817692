const UserRoutes = [
  {
    path: "/leave/",
    meta: { menu: "settings" },
    component: () =>
      import(/* webpackChunkName: "leave" */ "@/views/leave/Leave.vue"),
    children: [
      {
        path: "overview",
        name: "leave-overview",
        meta: { menu: "settings", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "leave-overview" */ "@/views/leave/views/LeaveOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "leave-browse",
        meta: { menu: "user", breadcrumb: "browse" },
        props: { mode: "BROWSE" },
        component: () =>
          import(
            /* webpackChunkName: "leave-browse" */ "@/views/leave/views/leave-browse-and-trash/LeaveBrowseAndTrash.vue"
          ),
      },
      {
        path: "trash",
        name: "leave-trash",
        meta: { menu: "user", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "leave-trash" */ "@/views/leave/views/leave-browse-and-trash/LeaveBrowseAndTrash.vue"
          ),
      },
    ],
  },
];

export default UserRoutes;
