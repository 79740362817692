const SettingsRoutes = [
  {
    path: "/settings",
    name: "settings",
    meta: { menu: "settings" },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/Settings.vue"
      ),
    children: [],
  },
];

export default SettingsRoutes;
