<template>
  <div id="loading" class="flex-center">
    <q-spinner color="secondary" size="3em" :thickness="5" />
  </div>
</template>

<script>
export default {
  name: "SheetLoading",

  props: {},

  computed: {},
};
</script>

<style lang="scss" scoped>
#loading {
  align-items: center;
  cursor: pointer;
  position: absolute;
  justify-content: center;
  background: white;
  opacity: 0.7;
  display: flex;
  align-content: center;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
</style>
