import { IMask } from "vue-imask";

export default {
  mask: "H{:}M",
  blocks: {
    H: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
      maxLength: 2,
    },
    M: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      maxLength: 2,
    },
    // A: {
    //   mask: IMask.MaskedEnum,
    //   enum: ["AM", "am", "PM", "pm", "aM", "Am", "pM", "Pm"],
    // },
  },
};
