<template>
  <ValidationProvider v-slot="{ errors }" :name="field.label" class="col-12">
    <MobileAuthentication
      :value="value"
      :label="field.label"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :otp-verify="false"
      :error="errors[0]"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import MobileAuthentication from "@/components/common/form/mobile-authentication-field/MobileAuthentication.vue";

export default {
  name: "MobileAuthenticationFieldWrapper",

  components: { ValidationProvider, MobileAuthentication },

  props: {
    value: {
      type: Object,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
