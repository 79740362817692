<template>
  <div id="clear-button" v-on="$listeners">
    <BaseIcon name="eva-close" inherit-color class="icon" />
  </div>
</template>

<script>
export default {
  name: "ClearButton",
};
</script>

<style lang="scss" scoped>
#clear-button {
  height: 36px;
  display: flex;
  align-items: center;
  margin-right: 8px;

  .icon {
    color: var(--icon-color-inverted);
  }

  &:hover {
    cursor: pointer;

    .icon {
      color: var(--red);
    }
  }
}
</style>
