import { axiosCrypto } from "./axios";

export default {
  async testdatabaseconnection(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/testdatabaseconnection",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching test database connection";
    }

    return _response;
  },

  async addConnector(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching add new connection";
    }

    return _response;
  },

  async getConnection(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching connection";
    }

    return _response;
  },

  async updateConnector(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/connector/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error updating connection channel";
    }

    return _response;
  },

  async getDatabaseList(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/gethubpointlist",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      } else {
        throw response;
      }
    } catch (e) {
      console.error(e);
      if (e.response?.status === 503) {
        // _response.error =
        //   "There are a lot of tables in this letter. So you type another letter";
      } else {
        _response.error = "error fetching get database list";
      }
    }

    return _response;
  },

  async getColumns(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/getindatapointlist",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching get columns";
    }

    return _response;
  },

  async testQuery(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/getdatabasehublinkcallTest",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching testing query";
    }

    return _response;
  },

  async connectLookUp(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/connectorHub",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching connect lookup";
    }

    return _response;
  },

  async updateLookUp(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/connector/connectorHub/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      if (e.response?.status === 404) {
        _response.error = "lookup with the given id is not found";
      } else if (e.response?.status === 406) {
        _response.error = "given lookup id is not acceptable";
      } else {
        _response.error = "error updating lookup";
      }
    }

    return _response;
  },

  async getLookUp(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/connectorHub/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching get lookup";
    }

    return _response;
  },

  async testoauth2Connection(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/testoauth2Connection",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching test api connection";
    }

    return _response;
  },

  async addHubLink(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/hubLink",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching add new lookup";
    }

    return _response;
  },

  async getHubLink(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/hubLink/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching get lookup";
    }

    return _response;
  },

  async testApiFunction(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/oauth2TestEndpointCall",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data ? JSON.parse(data) : data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching test api function";
    }

    return _response;
  },

  async getSyncValue(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/getdatabasehublinkcall",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data ? JSON.parse(data) : data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching sync value";
    }

    return _response;
  },

  async getHubLinkDetails(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/connector/hubLink/${id}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data ? JSON.parse(data) : data;
    } catch (e) {
      console.error(e);
      _response.error = "error on connector details";
    }

    return _response;
  },

  async updateHubLink(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/connector/hubLink/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data ? JSON.parse(data) : data;
    } catch (e) {
      console.error(e);
      _response.error = "error updating connector details";
    }

    return _response;
  },

  async addConnection(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/integration",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data ? JSON.parse(data) : data;
    } catch (e) {
      console.error(e);
      _response.error = "error in connection";
    }

    return _response;
  },

  async getChannelList(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/connector/getListbyConnectorId`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data ? JSON.parse(data) : data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching channel list";
    }

    return _response;
  },

  async addChannelInSlack(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/saveChannelList",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data ? JSON.parse(data) : data;
    } catch (e) {
      console.error(e);
      _response.error = "error in saving slack channel";
    }

    return _response;
  },

  async addTeamsChannel(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/connector/TeamDetailsInfo",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error in saveing teams channel";
    }

    return _response;
  },
};
