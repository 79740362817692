<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="{ required: !_isReadonly ? isMandatory : false }"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <RatingField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :rating-icon="ratingIcon"
      :rating-icon-count="ratingIconCount"
      :allow-half-rating="allowHalfRating"
      :error="errors[0]"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import RatingField from "@/components/common/form/rating-field/RatingField.vue";

export default {
  name: "RatingFieldWrapper",

  components: { ValidationProvider, RatingField },

  props: {
    value: {
      type: Number,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    ratingIcon() {
      return this.field.settings.specific.ratingIcon;
    },

    ratingIconCount() {
      return this.field.settings.specific.ratingIconCount;
    },

    allowHalfRating() {
      return this.field.settings.specific.allowHalfRating;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
