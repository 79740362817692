<template>
  <div id="popup-field">
    <!-- trigger button -->

    <BaseButton
      v-if="popupTriggerType === 'BUTTON'"
      label="open popup"
      color="secondary"
      @click="showModal"
    />

    <!-- ... -->

    <!-- trigger link -->

    <div v-if="popupTriggerType === 'LINK'" class="link" @click="showModal">
      {{ label }}
    </div>

    <!-- ... -->

    <!-- modal -->

    <Modal v-model="isModalVisible" :has-footer="!isReadonly" width="820px">
      <!-- title -->

      <template #title>{{ panelTitle }}</template>

      <!-- ... -->

      <template #default>
        <div v-if="!panels.length" class="no-panel">
          No secondary panel is linked
        </div>

        <div v-else-if="!fields.length" class="no-panel">No fields found</div>

        <ValidationObserver v-else ref="form">
          <RenderPanel
            no-padding
            :fields="fields"
            :fields-model="fieldsModel"
            :form-settings="formSettings"
            :is-readonly="isReadonly"
          />
        </ValidationObserver>
      </template>

      <!-- footer -->

      <template v-if="!isReadonly" #footer>
        <BaseButton
          is-flat
          label="cancel"
          class="q-mr-sm"
          @click="closeModal"
        />

        <BaseButton label="save" @click="save" />
      </template>

      <!-- ... -->
    </Modal>

    <!-- ... -->
  </div>
</template>

<script>
import Modal from "@/components/common/popup/Modal.vue";
import { ValidationObserver } from "vee-validate";
import FieldsModelMixin from "@/components/common/render-form/mixins/fields-model.js";
import { isEmpty, merge } from "lodash-es";

export default {
  name: "PopupField",

  components: { Modal, ValidationObserver },

  mixins: [FieldsModelMixin],

  props: {
    value: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    popupTriggerType: {
      type: String,
      default: "BUTTON",
    },

    secondaryPanel: {
      type: String,
      default: "",
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    mappedFieldId: {
      type: String,
      default: "",
    },

    mappedColumnId: {
      type: String,
      default: "",
    },

    formSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isModalVisible: false,
      fieldsModel: {},
    };
  },

  computed: {
    panels() {
      if (!this.secondaryPanel || isEmpty(this.secondaryPanels)) return [];

      const panel = this.secondaryPanels.find(
        (panel) => panel.id === this.secondaryPanel
      );

      if (!panel) {
        return [];
      }

      return [panel];
    },

    panelTitle() {
      if (isEmpty(this.panels)) {
        return "Panel title";
      }

      return this.panels[0].settings.title || "Panel title";
    },

    fields() {
      if (isEmpty(this.panels)) {
        return [];
      }

      return this.panels[0].fields;
    },

    label() {
      if (!this.mappedFieldId) return "Open popup";

      const mappedField = this.fields.find(
        (field) => field.id === this.mappedFieldId
      );

      if (mappedField.type === "TABLE") {
        if (isEmpty(this.value)) return 0;
        if (!this.mappedColumnId) return 0;

        return this.value[this.mappedFieldId].reduce(
          (acc, cur) => acc + cur[this.mappedColumnId],
          0
        );
      }

      if (mappedField.type === "NESTED_LIST") {
        if (isEmpty(this.value)) return 0;

        return this.value[this.mappedFieldId].reduce(
          (acc, cur) => acc + cur.value,
          0
        );
      }

      return this.fieldsModel[this.mappedFieldId];
    },
  },

  created() {
    this.fieldsModel = merge(this.fieldsModel, this.value);
  },

  methods: {
    showModal() {
      this.isModalVisible = true;
    },

    closeModal() {
      this.isModalVisible = false;
    },

    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.$emit("input", this.fieldsModel);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
#popup-field {
  .link {
    color: var(--secondary);
    font-weight: 500;
    text-decoration: underline;
    text-transform: capitalize;
    cursor: pointer;
  }
}

.no-panel {
  color: var(--icon-color);
  text-align: center;
  padding-top: 16px;
}
</style>
