const mobileUploadRoutes = [
  {
    path: "/mobileUpload/:tid/:uid/:id",
    name: "mobile-upload",
    props: (route) => ({
      tenantId: route.params.tid,
      userId: route.params.uid,
      uniqueId: route.params.id,
    }),
    component: () => import("@/views/mobile-upload/MobileUpload.vue"),
  },
];
export default mobileUploadRoutes;
