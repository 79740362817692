<template>
  <div id="rating-icon">
    <BaseIcon :name="_icon" size="32px" :color="color" />

    <template v-if="allowHalfRating">
      <div
        v-tooltip.top="label - 0.5"
        class="left"
        @click="handleLeftClick"
      ></div>
      <div v-tooltip.top="label" class="right" @click="handleRightClick"></div>
    </template>

    <div v-else v-tooltip.top="label" class="full" @click="handleClick"></div>
  </div>
</template>

<script>
import { lowerCase } from "lodash-es";

export default {
  name: "RatingIcon",

  props: {
    label: {
      type: Number,
      required: true,
    },

    selectedValue: {
      type: Number,
      required: true,
    },

    icon: {
      type: String,
      default: "STAR",
      validator(value) {
        return ["STAR", "HEART", "SHIELD"].includes(value);
      },
    },

    color: {
      type: String,
      default: "amber",
      validator(value) {
        return ["amber", "red", "green"].includes(value);
      },
    },

    allowHalfRating: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _icon() {
      if (this.label === this.selectedValue + 0.5) {
        return `mdi-${lowerCase(this.icon)}-half-full`;
      }

      if (this.label <= this.selectedValue) {
        return `mdi-${lowerCase(this.icon)}`;
      }

      return `mdi-${lowerCase(this.icon)}-outline`;
    },
  },

  methods: {
    handleLeftClick() {
      this.$emit("input", this.label - 0.5);
    },

    handleRightClick() {
      this.$emit("input", this.label);
    },

    handleClick() {
      this.$emit("input", this.label);
    },
  },
};
</script>

<style lang="scss" scoped>
#rating-icon {
  cursor: pointer;
  position: relative;

  .full {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .left,
  .right {
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }
}
</style>
