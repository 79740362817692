const AuthRoutes = [
  {
    path: "/",
    name: "signIn",
    component: () =>
      import(/* webpackChunkName: "sign-in" */ "@/views/sign-in/SignIn.vue"),
    children: [],
  },
  {
    path: "/signUp",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "sign-in" */ "@/views/sign-up/SignUp.vue"),
    children: [],
  },
];

export default AuthRoutes;
