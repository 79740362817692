<template>
  <div
    id="app"
    :class="{ blurred: isOverlayVisible, 'disable-security': checkTenant() }"
  >
    <div class="sensitive-content">
      <!-- <LoadingBar v-if="loadingBar" /> -->

      <router-view v-if="isSupportedDevice" />

      <UnsupportedDevice v-else />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UnsupportedDevice from "@/components/common/state/UnsupportedDevice.vue";
// import LoadingBar from "@/components/common/LoadingBar.vue";

export default {
  name: "App",

  components: {
    UnsupportedDevice,
    //  LoadingBar
  },

  data() {
    return {
      isOverlayVisible: false,
      isRecording: false,
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    isSupportedDevice() {
      // if (process.env.NODE_ENV === "production") {
      //   const isDesktop = this.$q.platform.is.desktop;
      //   const deviceWidth = this.$q.screen.width;
      //   const deviceHeight = this.$q.screen.height;

      //   return isDesktop && deviceWidth >= 1280 && deviceHeight >= 720;
      // }

      return true; // debug
    },
  },

  watch: {
    darkTheme: {
      immediate: true,
      handler() {
        const html = document.querySelector("html");
        html.classList.remove("theme-dark");
        html.classList.add("theme-light");

        const body = document.querySelector("body");
        body.classList.remove("theme-dark");
        body.classList.add("theme-light");

        if (this.darkTheme) {
          html.classList.remove("theme-light");
          html.classList.add("theme-dark");

          body.classList.remove("theme-light");
          body.classList.add("theme-dark");
        }
      },
    },
  },

  mounted() {
    document.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("blur", this.handleBlur);
    window.addEventListener("focus", this.handleFocus);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("blur", this.handleBlur);
    window.removeEventListener("focus", this.handleFocus);
  },

  methods: {
    checkTenant() {
      if (this.$store.state.session) {
        let origin = location.origin;
        if (
          (origin === "https://trial.ezofis.com1" ||
            origin === "http://localhost:80801") &&
          this.$store.state.session.tenantId === 1
        ) {
          return true;
        }
      }
      return false;
    },

    handleKeyUp(event) {
      if (this.checkTenant()) {
        if (event.key === "PrintScreen" || event.keyCode === 44) {
          navigator.clipboard.writeText("");
          this.$alert.warning("Taking screenshot is not allowed by the app");
        } else if (
          event.keyCode === 91 ||
          event.keyCode === 93 ||
          event.keyCode === 44
        ) {
          //91/93 - windows key
          //44 - print screen
          this.isOverlayVisible = true;
          //this.$alert.warning("plz dont take screen shot");
        } else if (event.key == "Escape") {
          this.isOverlayVisible = false;
        }
      }
    },

    handleBlur() {
      if (this.checkTenant() && this.$route.name !== "signIn") {
        const sensitiveContent = document.querySelector(".sensitive-content");
        if (sensitiveContent) {
          setTimeout(() => {
            this.isOverlayVisible = true;
          }, 300);
        }
      }
    },

    handleFocus() {
      const sensitiveContent = document.querySelector(".sensitive-content");
      if (sensitiveContent) {
        setTimeout(() => {
          this.isOverlayVisible = false;
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss">
#app.disable-security {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.blurred {
  filter: blur(15px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  z-index: 1000;
}

@media print {
  #app.disable-security {
    display: none;
  }
}
</style>
