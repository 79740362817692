<template>
  <div id="state-wrapper">
    <!-- illustration -->

    <div id="illustration" class="slow-up-down">
      <div id="shape-1"></div>
      <div id="shape-2"></div>
      <div id="shape-3">
        <BaseIcon :name="icon" size="80px" class="icon" />
      </div>
    </div>

    <!-- ... -->

    <!-- title -->

    <div class="title-1">
      {{ title }}
    </div>

    <!-- ... -->

    <!-- description -->

    <div class="description">
      {{ description }}
    </div>

    <!-- ... -->

    <!-- action -->

    <BaseButton
      v-if="action"
      :icon="actionIcon"
      :label="action"
      class="q-mt-lg"
      @click="$emit('action')"
    />

    <!-- ... -->
  </div>
</template>

<script>
export default {
  name: "StateWrapper",

  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    action: {
      type: String,
      default: "",
    },

    actionIcon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
#state-wrapper {
  margin: auto;
  margin-top: 64px !important;
  width: 99%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
}

#illustration {
  min-height: 160px;
  min-width: 160px;
  position: relative;
  margin-bottom: 36px;

  #shape-1,
  #shape-2,
  #shape-3 {
    height: 100%;
    width: 100%;
    position: absolute;
  }

  #shape-1 {
    top: 16px;
    left: 12px;
    background-color: var(--primary);
    border-radius: 60px 60px 30px 60px;
  }

  #shape-2 {
    top: -16px;
    left: -12px;
    background-color: var(--secondary);
    border-radius: 30px 60px 60px 60px;
  }

  #shape-3 {
    top: 0;
    left: 0;
    background-color: var(--component-bg-color);
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .animation {
      animation: rotate 3s linear infinite;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slow-up-down {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.slow-up-down {
  animation: slow-up-down 4s ease-in-out infinite;
}
</style>
