<template>
  <div class="q-mt-sm" :class="size">
    <TabsField
      :value="value"
      :tabs="tabs"
      :is-readonly="isReadonly"
      :secondary-panels="secondaryPanels"
      :form-settings="formSettings"
      @input="handleInput"
    />
  </div>
</template>

<script>
import TabsField from "@/components/common/form/tabs-field/TabsField.vue";

export default {
  name: "TabsFieldWrapper",

  components: { TabsField },

  props: {
    value: {
      type: Object,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    size() {
      return this.field.settings.general.size;
    },

    tabs() {
      return this.field.settings.specific.tabList;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
