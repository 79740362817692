import { axios } from "./axios";

export default {
  async getStatus() {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status, data } = await axios.get("/common/roadMap/3");

      if (status !== 200) {
        throw "invalid status code";
      }

      response.data = JSON.parse(data.content);
    } catch (e) {
      console.error(e);
      response.error = "error fetching status";
    }

    return response;
  },

  async saveStatus(payload) {
    const response = {
      data: "",
      error: "",
    };

    try {
      const { status } = await axios.put("/common/roadMap/3", payload);

      if (status !== 200) {
        throw "invalid status code";
      }
    } catch (e) {
      console.error(e);
      response.error = "error saving status";
    }

    return response;
  },
};
