<template>
  <!-- <q-linear-progress id="loading-bar" size="4px" indeterminate /> -->
  <!-- <div>
    <q-spinner id="loading-bar" color="primary" size="3em" :thickness="2" />
  </div> -->

  <!-- <div id="loading-bar">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div class="text title-1">Please wait.... loading data</div>
  </div> -->

  <div id="loading-bar" class="z-max no-pointer-events loader">
    <!-- window-height window-width fixed-center -->
    <div class="row justify-center fixed-center">
      <div class="">
        <q-spinner color="secondary" size="3em" :thickness="4" />
      </div>

      <div class="text-center title-2 q-mt-lg">
        <!-- Please wait... progressing data -->
        Please wait... Getting things ready
      </div>
    </div>
  </div>

  <!-- <div class="loader-wrapper">
    <div class="loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p>Please wait.... loading data</p>
  </div> -->

  <!-- <q-linear-progress id="loading-bar" size="4px" indeterminate class="z-top" /> -->
</template>

<script>
export default {
  name: "LoadingBar",
};
</script>

<style lang="scss" scoped>
// #loading-bar {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
// }

#loading-bar1 {
  justify-content: center;
  align-items: center;

  position: absolute;
  // height: calc(100vh - 100px);
  // background: var(--body-bg-color);
  z-index: 1;
  // width: 100%;
  // height: 100%;

  width: 96%;
  height: 70%;
  // left: 53px;
  // top: 190px;
  padding: 150px;
}

#loading-bar1 div:not(.text) {
  box-sizing: border-box;
  display: block;
  position: absolute;
  justify-content: center;
  //  display: flex;
  text-align: center;
  align-items: center;
  width: 75px;
  height: 75px;
  left: calc(100vh - 22px);
  top: calc(100vh - 366px);
  margin: 8px;
  border: 6px solid var(--secondary);
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--secondary) transparent transparent transparent;
}

.text {
  @extend .text-lg;
  font-weight: bold;
  color: var(--title-1-color);
  // color: var(--secondary);
  box-sizing: border-box;
  display: block;
  position: absolute;
  justify-content: center;
  //  display: flex;
  text-align: center;
  align-items: center;
  width: 238px;
  /* height: 95px; */
  left: calc(100vh - 94px);
  top: calc(100vh - 252px);
}
#loading-bar1 div:nth-child(1) {
  animation-delay: -0.45s;
}
#loading-bar1 div:nth-child(2) {
  animation-delay: -0.3s;
}
#loading-bar1 div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  justify-content: center;
  align-items: center;
  position: absolute;
  background: var(--body-bg-color);
  width: 100%;
  height: 100vh;
  // background: rgba(0, 0, 0, 0.3);
}

.title-2 {
  margin-left: -110px;
  margin-top: 62px;
}

.fixed-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(0%, 0%) !important;
}
</style>
