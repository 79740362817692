<template>
  <div id="loading-bar" class="flex-center">
    <!-- window-height window-width fixed-center z-max no-pointer-events loader 
    row justify-center fixed-center-->
    <div class="text-center">
      <q-spinner color="secondary" size="3em" :thickness="4" />

      <div class="text-center title-2 q-mt-lg">Please wait... loading data</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingBarSheet",
};
</script>

<style lang="scss" scoped>
#loading-bar {
  // position: absolute;
  // background: rgba(0, 0, 0, 0.3);
  background: #fff;
  opacity: 0.7;
  position: fixed;
  justify-content: center;
  z-index: 9999;
  display: flex;
  width: -webkit-fill-available;
  height: -webkit-fill-available;

  // top: 0;
  // left: 0;
  // width: 100vw;
  // position: fixed;
  // left: 0px;
  // top: 0px;
  // width: 100%;
  // height: 100%;
  // z-index: 9999;
}

.loader {
  justify-content: center;
  align-items: center;
  position: absolute;
  background: var(--body-bg-color);
  width: 100%;
  height: 100vh;
}

// .title-2 {
//   margin-left: -110px;
//   margin-top: 62px;
// }

// .fixed-center {
//   top: 50% !important;
//   left: 50% !important;
//   transform: translate(0%, 0%) !important;
// }
</style>
