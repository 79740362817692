const WorkflowRoutes = [
  {
    path: "/workflows/",
    meta: { menu: "workflows" },
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/Workflows.vue"
      ),
    children: [
      {
        path: "overview",
        name: "workflows-overview",
        meta: { menu: "workflows", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "workflows-overview" */ "@/views/workflows/views/WorkflowsOverview.vue"
          ),
      },
      {
        path: "inbox",
        name: "workflows-inbox",
        meta: { menu: "workflows", breadcrumb: "inbox" },
        component: () =>
          import(
            /* webpackChunkName: "workflows-inbox" */ "@/views/workflows/views/workflows-inbox/WorkflowInbox.vue"
          ),
      },
      {
        path: "browse",
        name: "workflows-browse",
        meta: { menu: "workflows", breadcrumb: "browse" },
        props: { mode: "BROWSE" },
        component: () =>
          import(
            /* webpackChunkName: "workflows-browse" */ "@/views/workflows/views/workflows-browse-and-trash/WorkflowsBrowseAndTrash.vue"
          ),
      },
      {
        path: "trash",
        name: "workflows-trash",
        meta: { menu: "workflows", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "workflows-trash" */ "@/views/workflows/views/workflows-browse-and-trash/WorkflowsBrowseAndTrash.vue"
          ),
      },
      {
        path: "reports",
        name: "workflows-report",
        meta: { menu: "workflows", breadcrumb: "report" },
        props: { mode: "" },
        component: () =>
          import(
            /* webpackChunkName: "repositories-reports" */ "@/views/workflows/views/workflows-report/WorkflowsReport.vue"
          ),
      },
    ],
  },

  {
    path: "/workflows/builder/:id?",
    name: "workflow-builder",
    meta: { menu: "workflows" },
    props: (route) => ({ workflowId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "workflow-builder" */ "@/views/workflows/views/workflow-builder-wrapper/WorkflowBuilderWrapper.vue"
      ),
  },
  {
    path: "/workflows/:tid/:uid/:wid/:pid",
    name: "external-request",
    props: (route) => ({
      tenantId: route.params.tid,
      userId: route.params.uid,
      workflowId: route.params.wid,
      processId: route.params.pid,
    }),
    component: () =>
      import(
        /* webpackChunkName: "external-request" */ "@/views/workflows/views/workflow-external/ProcessRequest.vue"
      ),
  },
  {
    path: "/workflows/history/:tid/:uid/:wid/:pid",
    name: "external-request-history",
    props: (route) => ({
      tenantId: route.params.tid,
      userId: route.params.uid,
      workflowId: route.params.wid,
      processId: route.params.pid,
    }),
    component: () =>
      import(
        /* webpackChunkName: "external-request" */ "@/views/workflows/views/workflow-external/History.vue"
      ),
  },
  {
    path: "/workflows/preview/:tid/:uid/:wid/:pid",
    name: "external-preview",
    props: (route) => ({
      tenantId: route.params.tid,
      userId: route.params.uid,
      workflowId: route.params.wid,
      processId: route.params.pid,
    }),
    component: () =>
      import(
        /* webpackChunkName: "external-preview" */ "@/views/workflows/views/workflow-external/Preview.vue"
      ),
  },
];

export default WorkflowRoutes;
