<template>
  <div id="time-picker">
    <!-- time -->

    <div class="row q-mb-sm">
      <div class="hour">{{ time.hour || "--" }}</div>
      <div class="minute">{{ time.minute }}</div>
      <div v-if="timeFormat === '12'" class="meridian">{{ time.meridian }}</div>
    </div>

    <!-- ... -->

    <div class="units">
      <!-- hours -->

      <div class="hours">
        <BaseScrollbar height="216px" class="q-pr-sm">
          <div
            v-for="hour in hours"
            :key="hour.id"
            class="unit"
            :class="{ 'is-selected': time.hour === hour.label }"
            @click="time.hour = hour.label"
          >
            {{ hour.label }}
          </div>
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <!-- minutes -->

      <div class="minutes">
        <BaseScrollbar height="216px" class="q-pr-sm">
          <div
            v-for="minute in minutes"
            :key="minute.id"
            class="unit"
            :class="{ 'is-selected': time.minute === minute.label }"
            @click="time.minute = minute.label"
          >
            {{ minute.label }}
          </div>
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <!-- minutes -->

      <div v-if="timeFormat === '12'" class="meridians">
        <div
          v-for="meridian in meridians"
          :key="meridian"
          class="unit"
          :class="{ 'is-selected': time.meridian === meridian }"
          @click="time.meridian = meridian"
        >
          {{ meridian }}
        </div>
      </div>

      <!-- ... -->
    </div>

    <!-- now -->

    <!-- <div class="now q-mt-sm" @click="setNow">now</div> -->

    <BaseButton
      label="now"
      color="secondary"
      class="full-width q-mt-sm"
      @click="setNow"
    />

    <!-- ... -->
  </div>
</template>

<script>
export default {
  name: "TimePicker",

  props: {
    value: {
      type: String,
      default: "",
    },

    timeFormat: {
      type: String,
      default: "12",
    },
  },

  data() {
    return {
      hours: [],
      minutes: [],
      meridians: ["AM", "PM"],
      time: {
        hour: "",
        minute: "00",
        meridian: "AM",
      },
    };
  },

  watch: {
    time: {
      deep: true,
      handler() {
        if (this.timeFormat === "12") {
          this.$emit(
            "input",
            `${this.time.hour}:${this.time.minute} ${this.time.meridian}`
          );
        } else {
          this.$emit("input", `${this.time.hour}:${this.time.minute}`);
        }
      },
    },
  },

  created() {
    this.populateHours();
    this.populateMinutes();
    this.setTime(this.value);
  },

  methods: {
    appendZeroIfNeeded(unit) {
      if (unit === 0) {
        return "00";
      }
      return unit < 10 ? `0${unit}` : `${unit}`;
    },

    populateHours() {
      let hours = this.timeFormat === "12" ? 12 : 23;
      let time = this.timeFormat === "12" ? 1 : 0;

      for (let hour = time; hour <= hours; hour++) {
        this.hours.push({
          id: this.$nano.id(),
          label: this.appendZeroIfNeeded(hour),
        });
      }
    },

    populateMinutes() {
      for (let minute = 0; minute <= 59; minute++) {
        this.minutes.push({
          id: this.$nano.id(),
          label: this.appendZeroIfNeeded(minute),
        });
      }
    },

    setTime(value) {
      if (value) {
        const [time, meridian] = value.split(" ");
        const [hour, minute] = time.split(":");

        this.time.hour = hour;
        this.time.minute = minute;
        if (this.timeFormat === "12") {
          this.time.meridian = meridian;
        }
      }
    },

    setNow() {
      if (this.timeFormat === "12") {
        this.setTime(this.$day.customFormat("hh:mm A"));
      } else {
        this.setTime(this.$day.customFormat("HH:mm"));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#time-picker {
  width: 238px;
  padding: 16px;

  .hour,
  .minute,
  .meridian,
  .now {
    flex: 1;
    padding: 8px;
    text-align: center;
    color: $white;
    border-radius: 4px;
    background-color: var(--primary);
    text-transform: capitalize;
    font-weight: 500;
  }

  .now {
    background-color: var(--secondary);
    cursor: pointer;
  }

  .hour,
  .minute {
    margin-right: 8px;
  }

  .units {
    display: flex;
    padding: 8px 0px;
    border-top: 1px solid var(--divider-color);
    border-bottom: 1px solid var(--divider-color);
  }

  .hours {
    width: 78px;
  }

  .minutes {
    width: 78px;
  }

  .meridians {
    width: 70px;
  }

  .unit {
    padding: 8px;
    user-select: none;
    text-align: center;
    border-radius: 4px;
    transition: all 0.25s ease;

    &:hover {
      cursor: pointer;
      background-color: var(--hover-bg-color);
    }

    &.is-selected {
      background-color: var(--hover-bg-color);
      color: var(--secondary);
    }
  }
}
</style>
