import { render, staticRenderFns } from "./CloseButton.vue?vue&type=template&id=99825c2e&scoped=true&"
import script from "./CloseButton.vue?vue&type=script&lang=js&"
export * from "./CloseButton.vue?vue&type=script&lang=js&"
import style0 from "./CloseButton.vue?vue&type=style&index=0&id=99825c2e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99825c2e",
  null
  
)

export default component.exports
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'directives', {Ripple});
