<template>
  <CalculatedField :value="value" :placeholder="placeholder" :error="error" />
</template>

<script>
import CalculatedField from "@/components/common/form/calculated-field/CalculatedField.vue";

export default {
  name: "CalculatedFieldWrapper",

  components: { CalculatedField },

  props: {
    value: {
      type: Number,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },
  },

  computed: {
    placeholder() {
      return this.field.settings.general.placeholder;
    },
  },
};
</script>

<style lang="scss" scoped></style>
