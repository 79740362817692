<template>
  <div id="counter-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <FormFieldWrapper
      :is-focused="isFocused"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      :highlight="highlight"
    >
      <div class="row items-center">
        <div class="col">
          <input
            type="number"
            :value="value"
            class="text"
            :placeholder="placeholder"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @input.stop="handleInput"
          />
        </div>

        <div class="counter">
          <div class="up" @click="increase">
            <BaseIcon name="eva-chevron-up" />
          </div>

          <BaseSeparator />

          <div class="down" @click="decrease">
            <BaseIcon name="eva-chevron-down" />
          </div>
        </div>
      </div>
    </FormFieldWrapper>

    <template v-if="highlight === 'orange1'">
      <BaseButton
        label="Confirm"
        color="orange"
        class="q-mt-md"
        @click="$emit('verify')"
      />
    </template>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "CounterField",

  components: { FormFieldLabel, FormFieldWrapper, FormFieldError },

  props: {
    value: {
      type: Number,
      default: 0,
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    highlight: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isFocused: false,
      openLabelInput: false,
      labelError: "",
    };
  },

  methods: {
    handleInput(evt) {
      this.$emit("input", Number(evt.target.value));
    },

    decrease() {
      if (this.value === 0) {
        return;
      }

      this.$emit("input", this.value - 1);
    },

    increase() {
      this.$emit("input", this.value + 1);
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#counter-field {
  input.text {
    min-height: 46px;
    padding-left: 8px;
  }

  .counter {
    width: 46px;
    height: 46px;
    display: flex;
    flex-direction: column;
    background-color: var(--body-bg-color-light);
    border-left: 1px solid var(--divider-color);

    .up,
    .down {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
        background-color: var(--hover-bg-color);
      }
    }
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }

  // .tweak-btn {
  //   height: 46px;
  //   width: 46px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background-color: var(--body-bg-color-light);

  //   &.left {
  //     border-right: 1px solid var(--body-bg-color);
  //   }

  //   &.right {
  //     border-left: 1px solid var(--body-bg-color);
  //   }

  //   &:hover {
  //     cursor: pointer;
  //     background-color: var(--hover-bg-color);
  //   }
  // }
}
</style>
