<template>
  <div :class="{ 'is-readonly': isReadonly }">
    <Tabs v-model="activeTab" :tabs="tabs" color="secondary" />

    <div v-if="!panels.length" class="no-panel">
      No secondary panel is linked
    </div>

    <div v-else-if="!fields.length" class="no-panel">No fields found</div>

    <RenderPanel
      v-else
      no-padding
      :fields="fields"
      :fields-model="fieldsModel"
      :form-settings="formSettings"
      class="q-mt-lg"
    />
  </div>
</template>

<script>
import Tabs from "@/components/common/tabs/Tabs2.vue";
import FieldsModelMixin from "@/components/common/render-form/mixins/fields-model.js";
import { isEmpty } from "lodash-es";

export default {
  name: "TabsField",

  components: { Tabs },

  mixins: [FieldsModelMixin],

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    tabs: {
      type: Array,
      default: () => [],
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeTab: "",
      fieldsModel: {},
    };
  },

  computed: {
    panels() {
      if (isEmpty(this.tabs) || isEmpty(this.secondaryPanels)) {
        return [];
      }

      const tabPanels = this.tabs.map((tab) => tab.panel);
      return this.secondaryPanels.filter((panel) =>
        tabPanels.includes(panel.id)
      );
    },

    fields() {
      if (!this.activeTab || isEmpty(this.panels)) {
        return [];
      }

      const activeTab = this.tabs.find((tab) => tab.value === this.activeTab);
      if (!activeTab.panel) {
        return [];
      }

      const tabPanel = this.panels.find(
        (panel) => panel.id === activeTab.panel
      );
      if (!tabPanel) {
        return [];
      }

      return tabPanel.fields;
    },
  },

  watch: {
    fieldsModel: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("input", this.fieldsModel);
      },
    },
  },

  created() {
    if (isEmpty(this.tabs)) {
      return;
    }

    this.activeTab = this.tabs[0].value;
  },
};
</script>

<style lang="scss" scoped>
.no-panel {
  color: var(--icon-color);
  text-align: center;
  padding-top: 16px;
}
</style>
