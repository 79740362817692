<template>
  <div class="col-12">
    <DividerField :type="field.settings.general.dividerType" />
  </div>
</template>

<script>
import DividerField from "@/components/common/form/divider-field/DividerField.vue";

export default {
  name: "DividerFieldWrapper",

  components: { DividerField },

  props: {
    field: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
