<template>
  <div id="login-authentication-field">
    <template v-if="loginType === 'EZOFIS_LOGIN'">
      <div id="loginName">
        <FormFieldLabel
          v-if="label"
          label="username"
          is-mandatory="true"
          :tooltip="tooltip"
        />

        <FormFieldWrapper
          :is-focused="isFocused"
          :is-clearable="_isClearable"
          :is-readonly="isReadonly"
          :is-disabled="isDisabled"
          :has-error="!!error"
          class="q-mb-md"
          @clear="handleClear"
        >
          <input
            type="text"
            :value="loginUsername"
            :autofocus="autoFocus"
            :placeholder="placeholder"
            class="q-px-sm"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @input.stop="handleInput"
            @keypress.enter="$emit('enter')"
          />
        </FormFieldWrapper>
        <FormFieldError v-if="error" :error="error" />
      </div>
      <div id="password">
        <FormFieldLabel
          v-if="label"
          label="password"
          is-mandatory="true"
          :tooltip="tooltip"
        />

        <FormFieldWrapper
          :is-focused="isFocused"
          :is-clearable="_isClearable"
          :is-readonly="isReadonly"
          :is-disabled="isDisabled"
          :has-error="!!error"
          @clear="handleClear"
        >
          <input
            type="password"
            :value="loginPassword"
            :autofocus="autoFocus"
            :placeholder="placeholder"
            class="q-px-sm"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @input.stop="handleInput"
            @keypress.enter="$emit('enter')"
          />
        </FormFieldWrapper>
        <FormFieldError v-if="error" :error="error" />
      </div>
    </template>
    <template v-if="loginType === 'EMAIL_LOGIN'">
      <EmailAuthentication label="Email" />
    </template>
    <template v-if="loginType === 'MOBILE_LOGIN'">
      <MobileAuthentication label="Mobile" :otp-verify="false" />
    </template>
    <template v-if="additionalLoginTypes.length">
      <OrDivider />
      <div class="social-auth-btns">
        <template v-for="btn in additionalLoginTypes">
          <div v-if="btn === 'GOOGLE_LOGIN'" :key="btn" class="social-auth-btn">
            <img
              :src="require(`@/assets/auth/google.png`)"
              :alt="btn.label"
              height="20"
              width="20"
              class="q-mr-sm"
            />
            <div>google</div>
          </div>
          <div
            v-if="btn === 'MICROSOFT_LOGIN'"
            :key="btn"
            class="social-auth-btn"
          >
            <img
              :src="require(`@/assets/auth/microsoft.png`)"
              :alt="btn.label"
              height="20"
              width="20"
              class="q-mr-sm"
            />
            <div>microsoft</div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import EmailAuthentication from "@/components/common/form/email-authentication-field/EmailAuthentication.vue";
import MobileAuthentication from "@/components/common/form/mobile-authentication-field/MobileAuthentication.vue";
import OrDivider from "@/components/common/OrDivider.vue";

export default {
  name: "LoginAuthenticationField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    EmailAuthentication,
    MobileAuthentication,
    OrDivider,
  },

  props: {
    loginUsername: {
      type: String,
      default: "",
    },

    loginPassword: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    autoFocus: {
      type: Boolean,
      default: false,
    },

    loginType: {
      type: String,
      default: "",
    },

    additionalLoginTypes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isFocused: false,
      clockTick: false,
    };
  },

  computed: {
    _isClearable() {
      if (!this.isClearable) {
        return false;
      }

      return !!this.value;
    },
  },

  methods: {
    handleInput(evt) {
      this.$emit("input", evt.target.value);
    },

    handleClear() {
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped>
#login-authentication-field {
  input {
    min-height: 46px;
  }

  .social-auth-btns {
    display: flex;
    align-items: center;
  }

  .social-auth-btn {
    flex: 1;
    height: 44px;
    // padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--component-bg-color-inferior);
    color: var(--icon-color) !important;
    font-weight: 600;
    text-transform: capitalize;
    cursor: pointer;

    &:nth-child(2) {
      margin-left: 8px;
    }
  }
}
</style>
