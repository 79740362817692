// const ReportRoutes = [
//   {
//     path: "/reports",
//     name: "reports",
//     meta: { menu: "reports" },
//     component: () =>
//       import(/* webpackChunkName: "reports" */ "@/views/reports/Reports.vue"),
//     children: [],
//   },
// ];

const ReportRoutes = [
  {
    path: "/reports",
    name: "reports",
    meta: { menu: "Reports" },
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/reports/Reports.vue"),
    children: [
      {
        path: "overview",
        name: "reports-overview",
        meta: { menu: "Reports", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "users-overview" */ "@/views/reports/views/ReportsOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "reports-browse",
        meta: { menu: "reports", breadcrumb: "browse" },
        props: (route) => ({
          code: route.query.code,
          mode: "BROWSE",
        }),
        component: () =>
          import(
            /* webpackChunkName: "users-browse" */ "@/views/reports/views/reports-browse-and-trash/ReportsBrowseAndTrash.vue"
          ),
      },
      {
        path: "trash",
        name: "reports-trash",
        meta: { menu: "reports", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "users-trash" */ "@/views/reports/views/reports-browse-and-trash/ReportsBrowseAndTrash.vue"
          ),
      },
      {
        path: "list",
        name: "reports-list",
        meta: { menu: "reports", breadcrumb: "browse" },
        props: (route) => ({
          code: route.query.code,
          mode: "REPORTS",
        }),
        component: () =>
          import(
            /* webpackChunkName: "users-browse" */ "@/views/reports/views/reports-browse-and-trash/components/ReportsList.vue"
          ),
      },
      {
        path: "add",
        name: "reports-add",
        meta: { menu: "reports", breadcrumb: "browse" },
        props: (route) => ({
          code: route.query.code,
          mode: "REPORTS",
        }),
        component: () =>
          import(
            /* webpackChunkName: "users-browse" */ "@/views/reports/views/reports-browse-and-trash/components/AddReports.vue"
          ),
      },
    ],
  },
  {
    path: "/reports/builder/:id?",
    name: "reports-builder",
    meta: { menu: "reports" },
    props: (route) => ({ formId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "form-builder" */ "@/views/reports/views/reports-builder-wrapper/ReportsBuilderWrapper.vue"
      ),
  },
];

export default ReportRoutes;
