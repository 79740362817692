const ConnectRoutes = [
  {
    path: "/db-connect",
    name: "connect",
    component: () =>
      import(
        /* webpackChunkName: "connect" */ "@/views/db-connect/Connection.vue"
      ),
  },
];

export default ConnectRoutes;
