<template>
  <div id="image-field">
    <FormFieldLabel
      v-if="label"
      :label="label"
      :is-mandatory="isMandatory"
      :tooltip="tooltip"
    />

    <FormFieldWrapper
      :is-focused="isDragging"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!_error"
      class="q-px-sm"
    >
      <!-- image preview -->

      <div v-if="imageSrc" class="image-preview">
        <div class="image">
          <img :src="imageSrc" alt="image" />
        </div>

        <!-- actions -->

        <div v-if="!isReadonly && !isDisabled" class="actions">
          <BaseActionButton
            v-tooltip.bottom="'edit image'"
            is-flat
            icon="eva-edit-outline"
            class="q-mr-sm"
            @click="editImage"
          />

          <BaseActionButton
            v-tooltip.bottom="'delete image'"
            is-flat
            icon="eva-trash-2-outline"
            @click="removeImage"
          />
        </div>

        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- image uploader -->

      <div v-else class="dropzone">
        <div class="hint">
          <BaseIcon
            name="eva-image-outline"
            size="72px"
            inherit-color
            class="icon"
          />

          <div class="title">
            <span class="link">Drop</span>
            or
            <span class="link">Browse</span>
            your image
          </div>
          <div class="description">We accept PNG, JPG & JPEG</div>
        </div>

        <input
          ref="dropzone"
          type="file"
          accept=".jpg,.jpeg,.png"
          @dragenter="isDragging = true"
          @dragleave="isDragging = false"
          @drop="isDragging = false"
          @input="handleInput"
        />
      </div>

      <!-- ... -->
    </FormFieldWrapper>

    <FormFieldError v-if="_error" :error="_error" />

    <!-- image cropper -->

    <ImageCropper
      v-model="isImageCropperVisible"
      :image="imageSrc"
      @save="saveImage"
    />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import ImageCropper from "./ImageCropper.vue";
import { lowerCase } from "lodash-es";

export default {
  name: "ImageField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    ImageCropper,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    maxFileSize: {
      type: Number,
      default: 0.5,
    },
  },

  data() {
    return {
      isFocused: false,
      isDragging: false,
      imageSrc: "",
      imageError: "",
      isImageCropperVisible: false,
    };
  },

  computed: {
    _error() {
      return this.imageError || this.error;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.imageSrc = this.value;
        }
      },
    },
  },

  methods: {
    handleInput(e) {
      const file = e.target.files[0];
      const fileType = file.type.split("/")[1];
      const fileSize = file.size;

      const isSupportedFileType = ["png", "jpg", "jpeg"].includes(
        lowerCase(fileType)
      );

      if (!isSupportedFileType) {
        this.imageError = "unsupported file type";
        return;
      }

      const maxFileSizeInBytes = this.maxFileSize * 1024 * 1024;

      if (fileSize > maxFileSizeInBytes) {
        this.imageError = `file size should be less than or equals ${this.maxFileSize} mb`;
        return;
      }

      this.imageError = "";

      const reader = new FileReader();
      reader.onload = (event) => {
        this.imageSrc = event.target.result;
        this.$emit("input", this.imageSrc);
        this.editImage();
      };
      reader.readAsDataURL(file);
    },

    editImage() {
      this.isImageCropperVisible = true;
    },

    saveImage(croppedImage) {
      this.imageSrc = croppedImage;
      this.isImageCropperVisible = false;

      this.$emit("input", croppedImage);
    },

    removeImage() {
      this.imageSrc = "";
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped>
#image-field {
  .image-preview {
    padding: 8px 0px;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      img {
        border-radius: 4px;
        width: 100%;
        height: auto;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }
  }

  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 16px;

    &.is-dragging {
      border: 1px dashed var(--secondary);

      .icon {
        color: var(--secondary);
      }
    }

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      cursor: pointer;
    }

    .hint {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        color: var(--body-text-color-inverted);
        margin-bottom: 16px;
      }

      .title {
        font-weight: 600;
        margin-bottom: 4px;
        text-align: center;

        .link {
          color: var(--secondary);
        }
      }

      .description {
        @extend .text-sm;
        color: var(--icon-color-inverted);
        text-align: center;
      }
    }
  }
}
</style>
