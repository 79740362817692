<template>
  <div id="feature-lock">
    <div class="is-disabled">
      <slot></slot>
    </div>

    <BaseIcon
      v-tooltip:secondary.top="'Please upgrade to enable this feature'"
      name="eva-lock"
      color="secondary"
      class="lock-icon"
      :style="_style"
    />
  </div>
</template>

<script>
export default {
  name: "FeatureLock",

  props: {
    position: {
      type: String,
      default: "TOP_RIGHT",
      validator(value) {
        const validValues = [
          "TOP_RIGHT",
          "TOP_LEFT",
          "CENTER_RIGHT",
          "CENTER_LEFT",
          "BOTTOM_RIGHT",
          "BOTTOM_LEFT",
        ];
        return validValues.includes(value);
      },
    },
  },

  computed: {
    _style() {
      switch (this.position) {
        case "TOP_RIGHT":
          return { top: 0, right: 0 };
        case "TOP_LEFT":
          return { top: 0, left: 0 };
        case "CENTER_RIGHT":
          return { top: "calc(50% - 10px)", right: 0 };
        case "CENTER_LEFT":
          return { top: "calc(50% - 10px)", left: 0 };
        case "BOTTOM_RIGHT":
          return { bottom: 0, right: 0 };
        case "BOTTOM_LEFT":
          return { bottom: 0, left: 0 };
        default:
          return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#feature-lock {
  position: relative;

  .is-disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    display: block !important;
  }

  .lock-icon {
    position: absolute;
    cursor: pointer;
  }
}
</style>
