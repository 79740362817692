<template>
  <div id="field-error">
    {{ error }}
  </div>
</template>

<script>
export default {
  name: "FormFieldError",

  props: {
    error: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#field-error {
  @extend .text-sm;
  color: var(--red);
  margin-top: 8px;
}
</style>
