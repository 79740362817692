<template>
  <div id="action-button" v-on="$listeners">
    <BaseIcon :name="icon" inherit-color class="icon" />
  </div>
</template>

<script>
export default {
  name: "ActionButton",

  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#action-button {
  height: 36px;
  display: flex;
  align-items: center;
  margin-right: 8px;

  .icon {
    color: var(--icon-color);
  }

  &:hover {
    cursor: pointer;

    .icon {
      color: var(--secondary);
    }
  }
}
</style>
