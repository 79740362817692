const IntegrationRoutes = [
  {
    path: "/integration/",
    meta: { menu: "settings" },
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/integration/Integration.vue"
      ),
    children: [
      {
        path: "overview",
        name: "integration-overview",
        meta: { menu: "settings", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "integration-overview" */ "@/views/integration/views/IntegrationOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "integration-browse",
        meta: { menu: "settings", breadcrumb: "browse" },
        props: (route) => ({
          code: route.query.code,
          mode: "BROWSE",
        }),
        component: () =>
          import(
            /* webpackChunkName: "integration-browse" */ "@/views/integration/views/integration-browse/IntegrationBrowse.vue"
          ),
      },
    ],
  },
  {
    path: "/integration/builder",
    name: "integration-builder",
    meta: { menu: "settings" },
    props: (route) => ({
      app: route.query.app,
    }),
    component: () =>
      import(
        /* webpackChunkName: "integration-builder" */ "@/views/integration/views/integration-builder/IntegrationBuilder.vue"
      ),
  },

  {
    path: "/integration/builder/browse",
    name: "sql-browse",
    meta: { menu: "settings", breadcrumb: "browse" },
    props: (route) => ({
      id: route.query.id,
      mode: "BROWSE",
    }),
    component: () =>
      import(
        /* webpackChunkName: "sql-browse" */ "@/views/integration/views/integration-builder/components/components/SQLLookUpBrowse.vue"
      ),
  },

  {
    path: "/integration/builder/api-browse",
    name: "api-browse",
    meta: { menu: "settings", breadcrumb: "browse" },
    props: (route) => ({
      id: route.query.id,
      mode: "BROWSE",
    }),
    component: () =>
      import(
        /* webpackChunkName: "api-browse" */ "@/views/integration/views/integration-builder/components/components/APILookUpBrowse.vue"
      ),
  },

  {
    path: "/integration/builder/slack-browse",
    name: "slack-browse",
    meta: { menu: "settings", breadcrumb: "browse" },
    props: (route) => ({
      id: route.query.id,
      mode: "BROWSE",
    }),
    component: () =>
      import(
        /* webpackChunkName: "slack-browse" */ "@/views/integration/views/integration-builder/components/components/SlackBrowse.vue"
      ),
  },

  {
    path: "/integration/builder/teams-browse",
    name: "teams-browse",
    meta: { menu: "settings", breadcrumb: "browse" },
    props: (route) => ({
      id: route.query.id,
      mode: "BROWSE",
    }),
    component: () =>
      import(
        /* webpackChunkName: "teams-browse" */ "@/views/integration/views/integration-builder/components/components/TeamsBrowse.vue"
      ),
  },

  {
    path: "/integration/builder/whatsapp-browse",
    name: "whatsapp-browse",
    meta: { menu: "settings", breadcrumb: "browse" },
    props: (route) => ({
      id: route.query.id,
      mode: "BROWSE",
    }),
    component: () =>
      import(
        /* webpackChunkName: "teams-browse" */ "@/views/integration/views/integration-builder/components/components/WhatsappBrowse.vue"
      ),
  },
];

export default IntegrationRoutes;
