import { render, staticRenderFns } from "./MultiSelectFieldWrapper.vue?vue&type=template&id=4dcf07df&scoped=true&"
import script from "./MultiSelectFieldWrapper.vue?vue&type=script&lang=js&"
export * from "./MultiSelectFieldWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dcf07df",
  null
  
)

export default component.exports