import { axiosCrypto } from "./axios";

export default {
  async getWorkflowCount(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/dashboard/workflow/${id}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow count";
    }

    return _response;
  },

  async getSignodeChart(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/dashboard/workflowCount/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow count";
    }

    return _response;
  },

  async getSignodeChart_Stock(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/dashboard/stockCount/${id}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching stock count";
    }

    return _response;
  },

  async getTaskCount(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/dashboard/report/taskCount/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching task count";
    }

    return _response;
  },

  async getTaskList(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/dashboard/report/taskList/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching task list";
    }

    return _response;
  },

  async getTaskChart(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/dashboard/report/taskChart/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching task chart";
    }

    return _response;
  },

  async getWorkflowData(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };
    try {
      const response = await axiosCrypto.post(
        `/report/workflowAuditWithSlaFields/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow report";
    }

    return _response;
  },

  async getRepositoryData(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/repository/documentActivity/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching document history";
    }

    return _response;
  },

  async getOverallCount(workflowId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/ticketCount/${workflowId}`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching overall workflow count";
    }

    return _response;
  },

  async getAllWorkflowCountsAndName() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/report/workflow/allworkflowCountsByName`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow count";
    }

    return _response;
  },

  async getAllWorkflowChart(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/allWorkflowTicketCountGraph/`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow chart";
    }

    return _response;
  },

  async getWorkflowTicketCount(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/ticketCount/${id}`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow count";
    }

    return _response;
  },

  async getWorkflowChart(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/ticketCountGraph/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow chart";
    }

    return _response;
  },

  async getChartByColumns(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/getCountsByFormFields/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow chart by columns";
    }

    return _response;
  },

  async getCountsByStage(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/getCountsByStage/${id}`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow count";
    }

    return _response;
  },

  async getUserWiseCounts(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/userWiseSlaCounts/${id}`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow count";
    }

    return _response;
  },

  async getProgressTiming(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/slaProgressTiming/${id}`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow progess timing";
    }

    return _response;
  },

  async getWorkflowTicketCountByUserId(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/ticketCountByUserId/${id}`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow count";
    }

    return _response;
  },

  async getWorkflowChartByUserId(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/ticketCountGraphByUserId/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow chart";
    }

    return _response;
  },

  async getChartByColumnsByUserId(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/getCountsByFormFieldsByUserId/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflow chart by columns";
    }

    return _response;
  },

  async getSlaPercentageByUserId(id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/slaPercentageByUserId/${id}`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching sla percentage";
    }

    return _response;
  },

  async getRepositoryFieldwiseTicketCount(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/repositoryfieldwiseTicketCount/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error =
        "error fetching document wise ticket count for each department";
    }

    return _response;
  },

  async getDocumentBasedTicketReport(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/documentBasedTicketReport/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching document wise ticket details";
    }

    return _response;
  },

  async getDocumentWiseTicketCount(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/documentWiseTicketCount/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching document wise ticket counts report";
    }

    return _response;
  },

  async getAllFormCounts() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/report/form/allFormCounts`);
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching all form counts";
    }

    return _response;
  },

  async getAllFormtypeEntryGraph(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/report/form/allformTypeEntryGraph/`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form chart";
    }

    return _response;
  },

  async getUserwiseAllFormEntryCounts() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/report/form/userwiseAllFormEntryCounts`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching all form userwise counts";
    }

    return _response;
  },

  async getAllFormActionTracking(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/form/allFormActionTracking/`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form action tracking chart";
    }

    return _response;
  },

  async getAllFormRecentEntries(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/report/form/allFormRecentEntries/`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form action tracking chart";
    }

    return _response;
  },

  async getFormCounts() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/report/form/userwiseFormCounts`);
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form counts";
    }

    return _response;
  },

  async getFormEntryGraph(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/form/formEntryGraph/`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form chart";
    }

    return _response;
  },

  async getUserwiseFormEntryCounts() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/report/form/userwiseFormEntrycounts`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form userwise counts";
    }

    return _response;
  },

  async getFormRecentEntries(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/report/form/formRecentEntries/`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form recent entries";
    }

    return _response;
  },

  async getUserwiseFormEntryGraph(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/report/form/userwiseFormEntryGraph/`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching userwise form chart";
    }

    return _response;
  },

  async getUserwiseFormRecentEntries(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/report/form/allFormRecentEntries/`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching userwise form recent entries";
    }

    return _response;
  },

  async getOverallAndRecommendation(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/overallAndRecommendation/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error =
        "error fetching cloudability and overall recommendation";
    }

    return _response;
  },

  async getTotalSpendAndSavings(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/totalSpendAndSavings/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching cloudability total spend and savings";
    }

    return _response;
  },

  async getCloudabilityByTitle(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/workflow/ec2CloudabilityCountsByTitle/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching cloudability counts";
    }

    return _response;
  },

  async getMasterFileProcess(workflowId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/report/masterFileProcessCount/${workflowId}`
      );
      const { status, data } = response;

      if (status === 200) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching master file process count";
    }

    return _response;
  },

  async getOcrReportData(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/ocr/list/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200 && data) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching Ocr Report";
    }

    return _response;
  },

  async getSignReport(rId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/signReport/${rId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200 && data) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching sign Report";
    }

    return _response;
  },

  async activityList(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/master/activityList`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 200 && data) {
        _response.payload = JSON.parse(data);
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching avctivity list";
    }

    return _response;
  },
};
