<template>
  <Modal
    :value="value"
    has-footer
    width="480px"
    height="auto"
    @input="closeModal"
  >
    <!-- title -->

    <template #title>Crop Image</template>

    <!-- ... -->

    <template #default>
      <!-- cropper -->

      <div id="image-cropper">
        <VueCropper
          ref="cropper"
          drag-mode="move"
          :src="image"
          :aspect-ratio="1"
          :container-style="{ height: '240px', width: '100%' }"
        />

        <!-- ... -->

        <!-- cropper actions -->

        <div class="cropper-actions">
          <BaseActionButton
            v-tooltip.bottom="'square crop'"
            is-flat
            icon="mdi-crop-square"
            class="q-mr-sm"
            @click="changeApectRatio(1)"
          />

          <BaseActionButton
            v-tooltip.bottom="'portrait crop'"
            is-flat
            icon="mdi-crop-portrait"
            class="q-mr-sm"
            @click="changeApectRatio(9 / 16)"
          />

          <BaseActionButton
            v-tooltip.bottom="'landscape crop'"
            is-flat
            icon="mdi-crop-landscape"
            class="q-mr-sm"
            @click="changeApectRatio(16 / 9)"
          />

          <BaseActionButton
            v-tooltip.bottom="'zoom out'"
            is-flat
            icon="eva-minimize-outline"
            class="q-mr-sm"
            @click="zoomOut"
          />

          <BaseActionButton
            v-tooltip.bottom="'zoom in'"
            is-flat
            icon="eva-maximize-outline"
            class="q-mr-sm"
            @click="zoomIn"
          />

          <BaseActionButton
            v-tooltip.bottom="'rotate left'"
            is-flat
            icon="mdi-rotate-left"
            class="q-mr-sm"
            @click="rotateLeft"
          />

          <BaseActionButton
            v-tooltip.bottom="'rotate right'"
            is-flat
            icon="mdi-rotate-right"
            class="q-mr-sm"
            @click="rotateRight"
          />

          <BaseActionButton
            v-tooltip.bottom="'flip horizontal'"
            is-flat
            class="q-mr-sm"
            icon="eva-flip-2"
            @click="flipX"
          />

          <BaseActionButton
            v-tooltip.bottom="'flip vertical'"
            is-flat
            icon="eva-flip"
            class="q-mr-sm"
            @click="flipY"
          />

          <BaseActionButton
            v-tooltip.bottom="'reset'"
            is-flat
            icon="eva-refresh"
            @click="reset"
          />
        </div>

        <!-- ... -->
      </div>
    </template>

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" @click="save" />
    </template>
  </Modal>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Modal from "@/components/common/popup/Modal.vue";

export default {
  name: "ImageCropper",

  components: { VueCropper, Modal },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    image: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      flippedX: false,
      flippedY: false,
    };
  },

  methods: {
    changeApectRatio(value) {
      this.$refs.cropper.setAspectRatio(value);
    },

    zoomIn() {
      this.$refs.cropper.relativeZoom(0.1);
    },

    zoomOut() {
      this.$refs.cropper.relativeZoom(-0.1);
    },

    rotateLeft() {
      this.$refs.cropper.rotate(-90);
    },

    rotateRight() {
      this.$refs.cropper.rotate(90);
    },

    flipX() {
      this.$refs.cropper.scaleX(this.flippedX ? 1 : -1);
      this.flippedX = !this.flippedX;
    },

    flipY() {
      this.$refs.cropper.scaleY(this.flippedY ? 1 : -1);
      this.flippedY = !this.flippedY;
    },

    reset() {
      this.flippedX = false;
      this.flippedY = false;
      this.$refs.cropper.reset();
    },

    closeModal() {
      this.reset();
      this.$emit("input", false);
    },

    save() {
      const cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$emit("save", cropedImage);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
#image-cropper {
  .cropper-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
}
</style>
