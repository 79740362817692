const UserRoutes = [
  {
    path: "/profile/",
    meta: { menu: "settings" },
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/profile/Profile.vue"),
    children: [
      {
        path: "overview",
        name: "profile-overview",
        meta: { menu: "settings", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "users-overview" */ "@/views/profile/views/ProfileOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "profile-browse",
        meta: { menu: "profile", breadcrumb: "browse" },
        props: { mode: "BROWSE" },
        component: () =>
          import(
            /* webpackChunkName: "users-browse" */ "@/views/profile/views/profile-browse-and-trash/ProfileBrowseAndTrash.vue"
          ),
      },
      {
        path: "trash",
        name: "profile-trash",
        meta: { menu: "profile", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "users-trash" */ "@/views/profile/views/profile-browse-and-trash/ProfileBrowseAndTrash.vue"
          ),
      },
    ],
  },
  {
    path: "/profile/builder/:id?",
    name: "profile-builder",
    meta: { menu: "settings" },
    props: (route) => ({ profileId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "user-builder" */ "@/views/profile/views/profile-builder/ProfileBuilder.vue"
      ),
  },
];

export default UserRoutes;
