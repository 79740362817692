<template>
  <div
    id="inline-time-field"
    :class="{
      'is-focused': isFocused,
      'has-error': error,
      'is-readonly': isReadonly,
      'is-disabled': isDisabled,
    }"
  >
    <div class="row items-center col">
      <input
        v-imask="timeMask"
        type="text"
        :value="time"
        :placeholder="placeholder"
        @click.stop=""
        @focus="isFocused = true"
        @blur="isFocused = false"
        @accept="handleAccept"
        @complete="handleComplete"
      />

      <BaseIcon
        name="eva-clock-outline"
        class="cursor-pointer"
        @click="toggleTimePicker"
      />

      <!-- time picker -->

      <q-menu
        v-model="timePicker"
        no-refocus
        no-parent-event
        transition-show="scale"
        transition-hide="scale"
      >
        <TimePicker :value="value" @input="handleInput" />
      </q-menu>

      <!-- ... -->

      <BaseIcon
        v-if="error"
        v-tooltip:red.top="error"
        color="red"
        name="mdi-information-outline"
        class="cursor-pointer q-ml-sm"
      />
    </div>
  </div>
</template>

<script>
import { IMaskDirective } from "vue-imask";
import timeMask from "@/components/common/form/time-field/time-mask.js";
import TimePicker from "@/components/common/form/time-field/TimePicker.vue";

export default {
  name: "TimeField",

  components: { TimePicker },

  directives: {
    imask: IMaskDirective,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFocused: false,
      timePicker: false,
      time: "",
      timeMask: timeMask,
    };
  },

  computed: {
    placeholder() {
      return this.label || "Time";
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.time = this.value;
      },
    },
  },

  methods: {
    toggleTimePicker() {
      this.timePicker = !this.timePicker;
    },

    handleInput(value) {
      this.$emit("input", value);
    },

    handleAccept(evt) {
      this.time = evt.detail.value;
    },

    handleComplete(evt) {
      this.$emit("input", evt.detail.value);
    },
  },
};
</script>

<style lang="scss" scoped>
#inline-time-field {
  display: inline-flex;
  align-items: center;
  width: 200px;
  margin-left: 8px;
  margin-right: 8px;
  border-bottom: 1px solid var(--border-color);

  input {
    flex: 1;
    color: var(--secondary);
  }

  &.has-error {
    border-bottom: 1px solid var(--red);

    input,
    input::placeholder {
      color: var(--red);
    }
  }

  &.is-focused {
    border-bottom: 1px solid var(--secondary);
  }
}
</style>
