import { render, staticRenderFns } from "./TextColor.vue?vue&type=template&id=c1912f44&scoped=true&"
import script from "./TextColor.vue?vue&type=script&lang=js&"
export * from "./TextColor.vue?vue&type=script&lang=js&"
import style0 from "./TextColor.vue?vue&type=style&index=0&id=c1912f44&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1912f44",
  null
  
)

export default component.exports
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMenu});
