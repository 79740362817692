<template>
  <div id="nested-list-field" :class="{ 'is-readonly': isReadonly }">
    <FormFieldLabel
      v-if="label"
      :label="label"
      :is-mandatory="isMandatory"
      :tooltip="tooltip"
    />

    <NestedListTable
      v-if="showEntries"
      v-model="nestedListEntries"
      :entries="nestedListEntries"
      :nested-list-field-type="nestedListFieldType"
      @removeEntry="removeEntry"
    />

    <!-- ... -->

    <NestedList
      :nested-list="nestedList"
      :nested-list-field-type="nestedListFieldType"
      :nested-list-type-settings="nestedListTypeSettings"
      :nested-list-items-per-line="nestedListItemsPerLine"
      :show-entries="showEntries"
      @addEntry="addEntry"
      @updateEntry="updateEntry"
      @toggleEntries="toggleEntries"
    />

    <!-- ... -->

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import NestedListTable from "./components/NestedListTable";
import NestedList from "./components/NestedList.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "NestedListField",

  components: { FormFieldLabel, NestedList, NestedListTable, FormFieldError },

  props: {
    value: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    nestedList: {
      type: Array,
      default: () => [],
    },

    nestedListItemsPerLine: {
      type: Array,
      default: () => [],
    },

    nestedListFieldType: {
      type: String,
      default: "SHORT_TEXT",
    },

    nestedListTypeSettings: {
      type: Object,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      nestedListEntries: [],
      showEntries: false,
    };
  },

  watch: {
    nestedListEntries: {
      deep: true,
      handler() {
        this.$emit("input", this.nestedListEntries);
      },
    },
  },

  created() {
    this.nestedListEntries = cloneDeep(this.value);
  },

  methods: {
    addEntry(entry) {
      this.nestedListEntries.push(entry);
    },

    updateEntry(entry) {
      const entryIndex = this.nestedListEntries.findIndex(
        (_entry) => _entry.id === entry.id
      );
      this.nestedListEntries[entryIndex] = entry;
    },

    removeEntry(entryId) {
      const entryIndex = this.nestedListEntries.findIndex(
        (entry) => entry.id === entryId
      );
      this.nestedListEntries.splice(entryIndex, 1);
    },

    toggleEntries(visibility) {
      this.showEntries = visibility;
    },
  },
};
</script>

<style lang="scss" scoped></style>
