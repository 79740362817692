import { axiosCrypto } from "./axios";

export default {
  async getProfileList(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/profile/${payload}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status === 204) {
        _response.payload = null;
      } else if (status === 200) {
        _response.payload = JSON.parse(data);
      } else if (status !== 200) {
        throw response;
      }

      // _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching profile";
    }

    return _response;
  },

  async getProfile(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/profile/all`,
        JSON.stringify(payload)
      );
      const { status, data } = response;
      if (status === 204) {
        _response.payload = null;
      } else if (status === 200) {
        _response.payload = JSON.parse(data);
      } else if (status !== 200) {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error fetching profile";
    }

    return _response;
  },

  async addProfile(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/profile`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching add profile";
    }

    return _response;
  },

  async updateProfile(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/profile/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching edit profile";
    }

    return _response;
  },

  async deleteProfile(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.delete(
        `/profile/${payload}?profileId=${payload}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching delete profile";
    }

    return _response;
  },

  async addProfilePreference(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/profilePreference`,
        JSON.stringify(payload)
      );
      const { status, data } = response;
      if (status !== 201) {
        throw response;
      }
      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error adding profile preference";
    }

    return _response;
  },

  async updateProfilePreference(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/profilePreference/${id}`,
        JSON.stringify(payload)
      );
      const { status } = response;
      if (status !== 201) {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error updating profile preference";
    }

    return _response;
  },

  async getProfilePreference(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/profilePreference/${payload}`);
      const { status, data } = response;

      if (status === 204) {
        _response.payload = null;
      } else if (status === 200) {
        _response.payload = JSON.parse(data);
      } else if (status !== 200) {
        throw response;
      }

      // _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching profile preference";
    }

    return _response;
  },
};
