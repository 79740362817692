<template>
  <ValidationProvider v-slot="{ errors }" :name="field.label" class="col-12">
    <LoginAuthenticationField
      :value="value"
      :label="field.label"
      :error="errors[0]"
      :login-type="field.settings.specific.loginType"
      :additional-login-types="field.settings.specific.additionalLoginTypes"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import LoginAuthenticationField from "@/components/common/form/login-authentication-field/LoginAuthenticationField.vue";

export default {
  name: "LoginAuthenticationFieldWrapper",

  components: { ValidationProvider, LoginAuthenticationField },

  props: {
    value: {
      type: Object,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},

  watch: {
    field: {
      immediate: true,
      handler: function () {
        console.log(1);
        console.log(this.field);
      },
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
