<template>
  <button
    id="action-btn"
    v-ripple="{ color: isFlat ? _color : 'white' }"
    :class="[bgColor, { 'is-bordered': !noBorder, 'is-disabled': isDisabled }]"
    v-on="isDisabled ? {} : $listeners"
  >
    <BaseIcon
      :name="icon"
      :is-white="!isFlat"
      :color="color"
      :inherit-color="inheritColor"
      :size="size"
      :rotate-class="rotateClass"
    />
    <slot name="default"></slot>
  </button>
</template>

<script>
import { mapState } from "vuex";

import colorValidator from "@/helpers/color-validator.js";

export default {
  name: "BaseActionButton",

  props: {
    color: {
      type: String,
      default: "gray",
      validator: colorValidator,
    },

    icon: {
      type: String,
      required: true,
    },

    isFlat: {
      type: Boolean,
      default: false,
    },

    noBorder: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    inheritColor: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: "20px",
    },

    rotateClass: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState(["darkTheme"]),

    bgColor() {
      if (this.isFlat) {
        return "";
      }

      return this.darkTheme
        ? `bg-${this.color}-5`
        : `bg-${this.color} shadow-2`;
    },

    _color() {
      if (this.inheritColor) {
        return "";
      }

      return this.darkTheme ? `${this.color}-5` : `${this.color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
#action-btn {
  border: none;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // position: relative;
  background-color: transparent;

  &.is-bordered {
    border: 1px solid var(--divider-color);
  }

  &.is-disabled {
    display: block !important;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not(.is-disabled):hover {
    cursor: pointer;
    background-color: var(--hover-bg-color);
  }
}
</style>
