import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./quasar";
import "./styles/app.scss";
import "./helpers/validation-rules.js";

Vue.config.productionTip = false;

// Google Login
import GAuth from "vue-google-oauth2";
const gauthOption = {
  clientId:
    "955661279397-nad8cpvh1u4a8ihqkn3hd6auvehpitu5.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
};
Vue.use(GAuth, gauthOption);
// Google Login

/* register base components */

import { registerBaseComponents } from "@/helpers/register-base-components.js";
registerBaseComponents(Vue);

/* ... */

/* register global components */

import RenderPanel from "./components/common/render-form/components/RenderPanel.vue";
Vue.component("RenderPanel", RenderPanel);

/* ... */

/* register directives */

import "./directives/index";

/* ... */

/* register plugins */

import "./plugins/index";

/* ... */

/* signature pad */

import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);

/* ... */

/* apex charts */

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

/* ... */

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
