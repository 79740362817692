<template>
  <q-icon
    id="icon"
    :name="name"
    :size="size"
    :color="_color"
    :class="rotateClass"
    v-on="$listeners"
  >
    <slot></slot>
  </q-icon>
</template>

<script>
import { mapState } from "vuex";
import colorValidator from "@/helpers/color-validator.js";

export default {
  name: "BaseIcon",

  props: {
    name: {
      type: String,
      required: true,
    },

    size: {
      type: String,
      default: "20px",
    },

    inheritColor: {
      type: Boolean,
      default: false,
    },

    isWhite: {
      type: Boolean,
      default: false,
    },

    isInactive: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: "gray",
      validator: colorValidator,
    },

    rotateClass: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState(["darkTheme"]),

    _color() {
      if (this.isInactive) {
        return this.darkTheme ? "gray-8" : "gray-3";
      }

      if (this.isWhite) {
        return "white";
      }

      if (this.inheritColor || !this.color) {
        return "";
      }

      return this.darkTheme ? `${this.color}-5` : `${this.color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
// #icon {
//   position: initial;
// }
</style>
