<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="validationRules"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <DateTimeField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :tooltip="tooltip"
      :time-format="timeFormat"
      :error="errors[0]"
      :highlight="highlight"
      @input="handleInput"
      @verify="$emit('verify')"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";

export default {
  name: "DateTimeFieldWrapper",

  components: { ValidationProvider, DateTimeField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this.isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      const minDateTime = this.field.settings.validation.minimum;
      if (minDateTime) {
        rules.minDate = minDateTime;
      }

      const maxDateTime = this.field.settings.validation.maximum;
      if (maxDateTime) {
        rules.maxDate = maxDateTime;
      }

      return rules;
    },

    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    timeFormat() {
      return this.field.settings.validation.timeFormat;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
