const RepositoryRoutes = [
  {
    path: "/repositories/",
    meta: { menu: "folders" },
    component: () =>
      import(
        /* webpackChunkName: "repositories" */ "@/views/repositories/Repositories.vue"
      ),
    children: [
      {
        path: "overview",
        name: "repositories-overview",
        meta: { menu: "folders", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "repositories-overview" */ "@/views/repositories/views/RepositoriesOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "repositories-browse",
        meta: { menu: "folders", breadcrumb: "browse", subMenu: "files" },
        props: (route) => ({
          workspaceId: Number(route.query.workspaceId),
          repositoryId: Number(route.query.repositoryId),
          mode: "BROWSE",
        }),
        component: () =>
          import(
            /* webpackChunkName: "repository-browse" */ "@/views/repositories/views/RepositoriesBrowseAndTrashWrapper.vue"
          ),
      },
      {
        path: "trash",
        name: "repositories-trash",
        meta: { menu: "folders", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "repositories-trash" */ "@/views/repositories/views/repositories-browse-and-trash/RepositoriesBrowseAndTrash.vue"
          ),
      },
      {
        path: "file-trash",
        name: "file-trash",
        meta: { menu: "folders", breadcrumb: "delete files" },
        props: { mode: "" },
        component: () =>
          import(
            /* webpackChunkName: "file-trash" */ "@/views/repositories/views/repositories-browse-and-trash/FileTrash.vue"
          ),
      },
      {
        path: "reports",
        name: "repositories-reports-overview",
        meta: { menu: "folders", breadcrumb: "reports" },
        props: { mode: "" },
        component: () =>
          import(
            /* webpackChunkName: "repositories-reports-overview" */ "@/views/repositories/views/repositories-reports-overview/RepositoriesReportOverview.vue"
          ),
      },
    ],
  },
  {
    path: "/repositories/builder/:id?",
    name: "repository-builder",
    meta: { menu: "folders" },
    props: (route) => ({ repositoryId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "repository-builder" */ "@/views/repositories/views/repository-builder/RepositoryBuilder.vue"
      ),
  },
  {
    path: "/upload-and-index/",
    name: "uploadAndIndex",
    props: (route) => ({
      workspaceId: Number(route.query.workspaceId),
      repositoryId: Number(route.query.repositoryId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "upload-and-index" */ "@/views/upload-and-index/UploadAndIndex.vue"
      ),
  },
  {
    path: "/repositories/security/:id?",
    name: "repository-secure",
    meta: { menu: "folders" },
    props: (route) => ({ repositoryId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "repository-secure" */ "@/views/repositories/views/repository-secure/RepositorySecure.vue"
      ),
  },
];

export default RepositoryRoutes;
