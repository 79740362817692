export const passwordHint = `
<div class="q-pa-sm">
  <div class="q-mt-sm">password should</div>
  <ul>
      <li>contain minimum 8 characters</li>
      <li>contain maximum 16 characters</li>
      <li>contain at least 1 number</li>
      <li>contain at least 1 uppercase character</li>
      <li>contain at least 1 lowercase character</li>
      <li>contain at least 1 special character (#, $, ^, +, =, !, *, (, ), @, %, &)</li>
  </ul>
</div>`;
