/**
 * Function to save the given data to the local storage
 *
 * @param data
 * @param key
 * @param dataType
 *
 * 1. Convert the data to string if necessary
 * 2. Convert the string to base64 string
 * 3. Save the base64 string to the local storage
 */

export function setToLocalStorage(data, key, dataType = "OBJECT") {
  if (!data || !key) {
    return;
  }

  if (dataType === "OBJECT") {
    data = JSON.stringify(data);
  }

  const payload = Buffer.from(data, "utf8").toString("base64");
  window.localStorage.setItem(key, payload);
}

/**
 * Function to get the data from the the local storage
 *
 * @param key
 * @param dataType
 *
 * 1. Get the base64 string from the local storage
 * 2. Parse the base64 string to string
 * 3. Parse the string if necessary
 *
 * @returns the data if exists else null
 */

export function getFromLocalStorage(key, dataType = "OBJECT") {
  if (!key) {
    return;
  }

  const data = window.localStorage.getItem(key);

  if (!data) {
    return;
  }

  const payload = Buffer.from(data, "base64").toString("utf8");

  if (dataType === "OBJECT") {
    return JSON.parse(payload);
  }

  return payload;
}
