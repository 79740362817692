export function pdfSupport(ext) {
  let extension = ext.toLowerCase();
  if (
    location.origin === "http://52.172.32.88" ||
    location.origin === "http://localhost:80801"
  ) {
    return false;
  }
  if (
    location.origin === "https://trial.ezofis.com" ||
    location.origin === "http://localhost:8080"
  ) {
    return true;
  }
  switch (extension) {
    case "pdf":
    case "doc":
    case "docx":
    case "ppt":
    case "pptx":
      return true;
    default:
      return false;
  }
}

export function htmlSupport(ext) {
  if (location.origin === "http://52.172.32.88") {
    return false;
  }
  switch (ext.toLowerCase()) {
    case "xls":
    case "xlsx":
    case "png":
    case "jpg":
    case "jpeg":
      return true;
    default:
      return false;
  }
}

export function fileSupport(ext) {
  let extension = ext.toLowerCase();
  if (
    extension === "exe" ||
    extension === "zip" ||
    extension === "rar" ||
    extension === "json" ||
    extension === "xml"
  ) {
    return false;
  }
  return true;
  // switch (ext.toLowerCase()) {
  //   case "pdf":
  //   case "doc":
  //   case "docx":
  //   case "ppt":
  //   case "pptx":
  //   case "xls":
  //   case "xlsx":
  //   case "txt":
  //   case "png":
  //   case "jpg":
  //   case "jpeg":
  //   case "dwg":
  //   case "dot":
  //   case "dxf":
  //   case "mp3":
  //   case "mp4":
  //   case "dcm":
  //     return true;
  //   default:
  //     return false;
  // }
}

export function otherFilePDFSupport(ext) {
  let extension = ext.toLowerCase();
  if (extension === "dcm") {
    return true;
  }
  return false;
}
