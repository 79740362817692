import { axiosCrypto } from "./axios";

export default {
  async getTicketListByUserId(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/transaction/reassignTicketList/${payload}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching ticket list";
    }

    return _response;
  },

  async addLeaveEntries(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/transaction/reassignRequest",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching add leave entries";
    }

    return _response;
  },

  async getLeaveEntries(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/transaction/reassignRequest/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching get leave entries";
    }

    return _response;
  },

  async updateLeaveEntries(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/transaction/reassignRequest/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);

      if (e.response?.status === 404) {
        _response.error = "repository with the given id is not found";
      } else if (e.response?.status === 406) {
        _response.error = "given leave id is not acceptable";
      } else {
        _response.error = "error updating leave entries";
      }
    }

    return _response;
  },

  async updateSubWorkflowProcess(wId, tId, swId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/transaction/updateSubWorkflow/${wId}/${tId}/${swId}`
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);

      _response.error = "error updating sub workflow";
    }

    return _response;
  },
};
