<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="{ required: !_isReadonly ? isMandatory : false }"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <SelectField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :error="errors[0]"
      :options="options"
      :new-option="allowToAddNewOptions"
      :highlight="highlight"
      :qr-value="qrValue"
      @input="handleInput($event, 'handle')"
      @verify="$emit('verify')"
      @scanQRCode="scanQRCode"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { axiosCrypto } from "@/api/axios.js";
import { user, workspace } from "@/api/factory.js";

export default {
  name: "SingleSelectFieldWrapper",

  components: { ValidationProvider, SelectField },

  props: {
    value: {
      type: String,
      required: true,
    },

    formId: {
      type: String,
      default: "0",
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    formSettings: {
      type: Object,
      required: true,
    },

    parentControl: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: Number,
      default: 0,
    },

    enableControls: {
      type: Object,
      default: () => {},
    },

    visibleControls: {
      type: Array,
      default: () => [],
    },

    highlight: {
      type: String,
      default: "",
    },

    hideControls: {
      type: Array,
      default: () => [],
    },

    parentSelectOptions: {
      type: Boolean,
      default: false,
    },

    fields: {
      type: Array,
      default: () => [],
    },

    parentFieldMasterControls: {
      type: Array,
      default: () => [],
    },

    parentFieldFolderControls: {
      type: Array,
      default: () => [],
    },

    updateValueFromSearch: {
      type: String,
      default: "",
    },

    hasInbox: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: [],
      mandatorySettings: [],
      readonlySettings: [],
    };
  },

  computed: {
    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    allowToAddNewOptions() {
      return this.field.settings.specific.allowToAddNewOptions;
    },

    qrValue() {
      return this.field.settings.specific.qrValue;
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          } else if (this.field.settings.specific.repositoryId) {
            this.getRepositoryDataFilter(this.field);
          }
        }
      },
    },

    value: {
      immediate: true,
      deep: true,
      handler(oldVal, newVal) {
        if (this.value) {
          let optVal = this.options.find((row) => row.label === this.value);
          if (!optVal) {
            if (this.value) {
              this.options.push({
                id: this.$nano.id(),
                label: this.value,
                value: this.value,
              });
            }
          }
          if (newVal !== undefined) {
            if (this.parentControl && !newVal && oldVal) {
              this.$emit("filterBy", this.field, "handle");
            }
          }
          if (this.hasInbox) {
            //this.handleInput(this.value);
          }
        } else {
          if (newVal && !oldVal) {
            this.handleInput(this.value);
          }
        }
      },
    },

    updateValueFromSearch: {
      // immediate: true,
      handler() {
        if (this.updateValueFromSearch === this.value) {
          this.handleInput(this.value);
        }
      },
    },
  },

  created() {
    const optionsType = this.field.settings.specific.optionsType;
    if (optionsType === "CUSTOM") {
      this.options = this.getCustomOptions();
      if (this.value) {
        let optVal = this.options.find((row) => row.label === this.value);
        if (!optVal) {
          if (this.value) {
            this.options.push({
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            });
          }
        }
      }
    } else if (
      this.formId &&
      optionsType === "EXISTING" &&
      this.formSettings.publish.publishOption === "PUBLISHED"
    ) {
      this.getOptionFromEntries(this.field);
    } else if (optionsType === "PREDEFINED") {
      if (this.field.settings.specific.predefinedTable === "User") {
        this.getUsers();
      } else if (this.field.settings.specific.predefinedTable === "Workspace") {
        this.getWorkspace();
      }
    } else if (optionsType === "MASTER") {
      if (!this.value) {
        this.getMasterEntries(
          this.field.settings.specific.masterFormId,
          this.field.settings.specific.masterFormColumn
        );
      } else {
        if (this.field.settings.specific.masterFormParentColumn) {
          this.$emit(
            "getParentColumnValue",
            this.field.settings.specific.masterFormParentColumn
          );
        } else {
          this.getMasterEntries(
            this.field.settings.specific.masterFormId,
            this.field.settings.specific.masterFormColumn
          );
        }
      }
    } else if (optionsType === "REPOSITORY") {
      if (!this.value) {
        this.getRepositoryData(
          this.field.settings.specific.repositoryId,
          this.field.settings.specific.repositoryField
        );
      }
    }
    this.mandatorySettings = this.field.settings.validation.mandatorySettings;
    if (this.mandatorySettings && this.mandatorySettings.length) {
      // if (this.value) {
      this.setMandatory(this.value, "immediate");
      // }
    }

    this.readonlySettings = this.field.settings.validation.readonlySettings;

    if (this.readonlySettings && this.readonlySettings.length) {
      this.setReadOnly(this.value, "immediate");
    }
  },

  methods: {
    handleInput(value, handle) {
      this.$emit("input", value);
      this.setMandatory(value);
      this.setReadOnly(value);
      if (this.parentControl) {
        this.$emit("filterBy", this.field, handle);
      }
      if (this.parentSelectOptions) {
        this.$emit("fillChildDateUsingOptions", value);
      }
      if (this.enableControls) {
        if (Object.keys(this.enableControls).length) {
          if (this.enableControls.controls.length) {
            let index = this.enableControls.controls.findIndex(
              (row) => row.id === this.field.id
            );
            if (index > -1) {
              let showControls = this.visibleControls;
              let enabled = [];
              this.enableControls.controls[index].parentControls.forEach(
                (control) => {
                  if (control.value === value) {
                    showControls.push(...control.childControls);
                    enabled.push(...control.childControls);
                  } else {
                    control.childControls.forEach((child) => {
                      if (
                        showControls.indexOf(child) > -1 &&
                        enabled.indexOf(child) === -1
                      ) {
                        this.hideControls.push(child);
                        showControls.splice(showControls.indexOf(child), 1);
                      }
                    });
                  }
                }
              );
              // console.log(showControls);
              // console.log(this.hideControls);
              this.$emit("update:hideControls", this.hideControls);
              this.$emit("updateVisibleControls", showControls);
            }
          }
        }
      }
    },

    getCustomOptions() {
      const separator =
        this.field.settings.specific.separateOptionsUsing === "NEWLINE"
          ? "\n"
          : ",";

      let customOptions = this.field.settings.specific.customOptions
        .split(separator)
        .map((option) => ({
          id: this.$nano.id(),
          label: option.trim(),
          value: option.trim(),
        }));
      return customOptions;
    },

    async getOptionFromEntries(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${this.formId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.id,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        }
        let optVal = this.options.find((row) => row.label === this.value);
        if (!optVal) {
          if (this.value) {
            this.options.push({
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            });
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getUsers() {
      const { error, payload } = await user.getUserList();
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (!payload) {
        return;
      }
      this.options = payload.map((user) => ({
        id: this.$nano.id(),
        label: user.value || user.loginName,
        value: user.value || user.loginName,
      }));
    },

    async getWorkspace() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workspace.getWorkspaceList();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.options = payload.map((workspace) => ({
        id: this.$nano.id(),
        label: workspace.value,
        value: workspace.value,
      }));
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          const parsedArray = [];
          options.forEach((item) => {
            try {
              const jsonString = item.trim();
              const array = JSON.parse(jsonString);
              array.forEach((value) => {
                if (!parsedArray.includes(value)) {
                  parsedArray.push(value);
                }
              });
            } catch (error) {
              if (!parsedArray.includes(item)) {
                parsedArray.push(item);
              }
            }
          });
          this.options = parsedArray.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        }
        let optVal = this.options.find((row) => row.label === this.value);
        if (!optVal) {
          if (this.value) {
            this.options.push({
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            });
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getMasterEntriesFilter(field) {
      let filter = [];
      filter.push(field.filterBy[0]);
      let filterIndex = this.parentFieldMasterControls.findIndex((parent) => {
        return parent.fieldId === field.id;
      });
      for (var i = 0; i < filterIndex; i++) {
        let parent = this.parentFieldMasterControls[i];
        let field = this.fields.find((form) => form.id === parent.fieldId);
        if (field.filterBy && field.filterBy.length) {
          field.filterBy.forEach((filterField) => {
            let filterFound = filter.filter((filterRow) =>
              filterRow.criteria.indexOf(filterField.criteria)
            );
            if (filterFound.length) {
              filter.push(field.filterBy[0]);
            }
          });
        }
      }
      let filterBy = [];
      if (filter.length) {
        filter.forEach((item) => {
          filterBy.push({
            ...item,
            value:
              typeof item.value === "object"
                ? JSON.stringify(item.value)
                : item.value,
            dataType: typeof item.value === "object" ? "SINGLE_SELECT" : "",
          });
        });
      }
      try {
        const response = await axiosCrypto.post(
          `/form/${field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          const parsedArray = [];
          options.forEach((item) => {
            try {
              const jsonString = item.trim();
              const array = JSON.parse(jsonString);
              array.forEach((value) => {
                if (!parsedArray.includes(value)) {
                  parsedArray.push(value);
                }
              });
            } catch (error) {
              if (!parsedArray.includes(item)) {
                parsedArray.push(item);
              }
            }
          });

          this.options = parsedArray.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          if (!this.value || this.field.isManualChange) {
            if (this.options.length === 1) {
              this.$emit("input", this.options[0].value);
              this.handleInput(
                this.options[0].value,
                this.field.isManualChange
              );
            } else {
              this.handleInput("");
            }
            this.$emit("clearFilter");
          }
        } else {
          if (this.value) {
            this.options = [
              {
                id: this.$nano.id(),
                label: this.value,
                value: this.value,
              },
            ];
          }
          this.handleInput("");
          this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getRepositoryData(repositoryId, field) {
      try {
        const response = await axiosCrypto.post(
          `/repository/${repositoryId}/uniqueColumnValues`,
          JSON.stringify({
            column: field,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        } else {
          if (this.value) {
            this.options = [
              {
                id: this.$nano.id(),
                label: this.value,
                value: this.value,
              },
            ];
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getRepositoryDataFilter(field) {
      let filter = [];

      filter.push(field.filterBy[0]);

      let filterIndex = this.parentFieldFolderControls.findIndex((parent) => {
        return parent.fieldId === field.id;
      });

      for (var i = 0; i < filterIndex; i++) {
        let parent = this.parentFieldFolderControls[i];
        let field = this.fields.find((form) => form.id === parent.fieldId);
        if (field.filterBy && field.filterBy.length) {
          field.filterBy.forEach((filterField) => {
            let filterFound = filter.filter((filterRow) =>
              filterRow.criteria.indexOf(filterField.criteria)
            );
            if (filterFound.length) {
              filter.push(field.filterBy[0]);
            }
          });
        }
      }

      try {
        const response = await axiosCrypto.post(
          `/repository/${field.settings.specific.repositoryId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.repositoryField,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filter,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          if (this.options.length === 1) {
            // this.$emit("input", this.options[0].value);
            this.handleInput(this.options[0].value);
          } else {
            this.handleInput("");
          }
          this.$emit("clearFilter");
        } else if (this.value) {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
          this.handleInput("");
          this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    setMandatory(value, immediate) {
      if (this.mandatorySettings) {
        if (this.mandatorySettings.length) {
          let controls = this.mandatorySettings.find(
            (control) => control.value.trim() === value.trim()
          );
          if (controls) {
            this.$emit(
              "mandatoryControls",
              controls.controls,
              this.mandatorySettings,
              immediate,
              this.field.id
            );
          } else {
            this.$emit(
              "mandatoryControls",
              [],
              this.mandatorySettings,
              immediate,
              this.field.id
            );
          }
        }
      }
    },

    setReadOnly(value, immediate) {
      if (this.readonlySettings) {
        if (this.readonlySettings.length) {
          let controls = this.readonlySettings.find(
            (control) => control.value.trim() === value.trim()
          );
          if (controls) {
            this.$emit(
              "readonlyControls",
              controls.controls,
              this.readonlySettings,
              immediate,
              this.field.id
            );
          } else {
            this.$emit(
              "readonlyControls",
              [],
              this.readonlySettings,
              immediate,
              this.field.id
            );
          }
        }
      }
    },

    scanQRCode() {
      this.$emit("scanQRCode", this.field);
    },
  },
};
</script>

<style lang="scss" scoped></style>
