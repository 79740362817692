<template>
  <div id="separator" :class="{ 'is-vertical': isVertical }" :style="_inset" />
</template>

<script>
export default {
  name: "BaseSeparator",

  props: {
    isVertical: {
      type: Boolean,
      default: false,
    },

    hasLeftInset: {
      type: Boolean,
      default: false,
    },

    hasRightInset: {
      type: Boolean,
      default: false,
    },

    hasInset: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _inset() {
      if (this.hasLeftInset) {
        return "margin-left: 16px";
      }

      if (this.hasRightInset) {
        return "margin-right: 16px";
      }

      if (this.hasInset) {
        return this.isVertical
          ? "margin-top: 8px; margin-bottom: 8px;"
          : "margin-left: 16px; margin-right: 16px;";
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
#separator {
  height: 1px;
  border: 0;
  align-self: stretch;
  background-color: var(--divider-color);

  &.is-vertical {
    height: auto;
    width: 1px;
  }
}
</style>
