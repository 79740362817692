import { axiosCrypto } from "./axios";

export default {
  async setRemoveFilePassword(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        "/menu/file/updatePassword",
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      _response.error = "error fetching set or remove password";
    }

    return _response;
  },

  async fileDownload(tenantId, userId, repositoryId, id) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/menu/file/download/${tenantId}/${userId}/${repositoryId}/${id}/2`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error downloading file";
    }

    return _response;
  },

  async validatePassword(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        "/menu/file/validatePassword",
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      // console.log(e);
      if (e.response.status == 404) {
        _response.error = "password is wrong";
      } else {
        _response.error = "error fetching validate password";
      }
    }

    return _response;
  },

  async filePrintSession(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/report/updateDocumentActivity`,
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      _response.error = "error in file print session";
    }

    return _response;
  },

  async documentShare(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/MailSettings/ShareMailWithAttachments`,
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      _response.error = "error in document share";
    }

    return _response;
  },

  async getHideColumns(workflowId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/menu/workflow/hideColumn/${workflowId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error in getting hide columns";
    }

    return _response;
  },

  async updateHideColumns(workflowId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/menu/workflow/hideColumn/${workflowId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = Number(data);
    } catch (e) {
      console.error(e);
      _response.error = "error in updating hide columns";
    }

    return _response;
  },

  async documentShareLink(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/file/sharelink`,
        JSON.stringify(payload)
      );
      const { status } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = response;
    } catch (e) {
      console.error(e);
      _response.error = "error in document share";
    }

    return _response;
  },
};
