import Vue from "vue";

import nanoid from "./nanoid";
Vue.use(nanoid);

import day from "./day";
Vue.use(day);

import alert from "./alert";
Vue.use(alert);

import loadingBar from "./loading-bar";
Vue.use(loadingBar);
