<template>
  <button id="close-btn" v-ripple="{ color: 'gray' }" v-on="$listeners">
    <BaseIcon name="eva-close" />
  </button>
</template>

<script>
export default {
  name: "CloseButton",
};
</script>

<style lang="scss" scoped>
#close-btn {
  border: none;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid var(--divider-color);
  transition: all ease 0.25s;

  &:hover {
    cursor: pointer;
    background-color: var(--hover-bg-color);
    transform: rotate(90deg);
  }
}
</style>
