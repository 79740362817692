<template>
  <!-- modal -->

  <Modal :value="value" width="30vw" height="62vh" @input="closeModal">
    <!-- title -->

    <template #title>SCAN QR</template>

    <!-- ... -->

    <template #default>
      <div v-if="error" class="text-caption text-red q-mb-lg">
        {{ error }}
      </div>

      <qrcode-stream @decode="onDecode" @init="onInit" />

      <!-- <TextField
        v-model="barcodeValue"
        placeholder="Enter Value"
        class="q-mt-md"
      /> -->
    </template>
  </Modal>

  <!-- ... -->
</template>
<script>
import Modal from "@/components/common/popup/Modal.vue";
import { QrcodeStream } from "vue-qrcode-reader";
// import TextField from "@/components/common/form/text-field/TextField.vue";
export default {
  name: "QRReader",

  components: {
    Modal,
    QrcodeStream,
    // TextField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      qrData: "",
      error: "",
      barcodeValue: "",
    };
  },

  methods: {
    reset() {
      this.qrData = "";
      this.error = "";
      this.barcodeValue = "";
    },

    closeModal() {
      this.$emit("input", this.qrData);
      this.reset();
    },

    onDecode(data) {
      this.qrData = data;
      this.closeModal();
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "Error: User denied camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.errerroror = "ERROR: No camera found";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: Secure connection required (HTTPS)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: Camera is already in use";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: No camera found";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Browser is not supported";
        } else {
          this.error = "";
        }
      }
    },
  },
};
</script>
<style scoped></style>
