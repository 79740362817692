const GroupRoutes = [
  {
    path: "/groups/",
    meta: { menu: "settings" },
    component: () =>
      import(/* webpackChunkName: "groups" */ "@/views/groups/Groups.vue"),
    children: [
      {
        path: "overview",
        name: "groups-overview",
        meta: { menu: "settings", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "groups-overview" */ "@/views/groups/views/GroupsOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "groups-browse",
        meta: { menu: "group", breadcrumb: "browse" },
        props: { mode: "BROWSE" },
        component: () =>
          import(
            /* webpackChunkName: "groups-browse" */ "@/views/groups/views/groups-browse-and-trash/GroupsBrowseAndTrash.vue"
          ),
      },
      {
        path: "trash",
        name: "groups-trash",
        meta: { menu: "group", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "groups-trash" */ "@/views/groups/views/groups-browse-and-trash/GroupsBrowseAndTrash.vue"
          ),
      },
    ],
  },
  {
    path: "/groups/builder/:id?",
    name: "group-builder",
    meta: { menu: "settings" },
    props: (route) => ({ groupId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "group-builder" */ "@/views/groups/views/group-builder/GroupBuilder.vue"
      ),
  },
];

export default GroupRoutes;
