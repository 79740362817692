import { axiosCrypto } from "./axios";
import axios from "axios";
import store from "../store/index.js";

//const newWorkspaceURL = "http://20.116.18.211/EzofisWorkspaceAPI/api";
const newWorkspaceURL = "https://trialapi.ezofis.com/EzofisWorkspaceAPI/api";

export default {
  async getWorkspaceList() {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `${newWorkspaceURL}/workspace/inboxCount`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workspaces";
    }

    return _response;
  },

  async getChatHistory(workspaceId, currentPage, itemsPerPage, unread = false) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `${newWorkspaceURL}/workspace/getMessages/${workspaceId}/${
          unread ? 2 : 1
        }`,
        JSON.stringify({
          currentPage,
          itemsPerPage,
        })
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching chat history";
    }

    return _response;
  },

  async sendMessage(workspaceId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `${newWorkspaceURL}/workspace/newMessage/${workspaceId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error sending message";
    }

    return _response;
  },

  async uploadFile(file, workspace) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("workspaceId", workspace.id);
    formData.append("repositoryId", workspace.repositoryId);
    formData.append("filename", file.name);

    const response = await axios({
      url: `${newWorkspaceURL}/workspace/fileUpload`,
      method: "POST",
      data: formData,
      headers: {
        Token: store.state.identity.token,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });

    const { status } = response;
    if (status !== 200) {
      throw "Invalid response";
    }
  },

  async getActivityFeed(workspaceId, type) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const _type = type === "Following" ? 2 : 1;
      const response = await axiosCrypto.post(
        `${newWorkspaceURL}/workspace/activityFeed/${workspaceId}/${_type}`,
        JSON.stringify({
          criteria: "createdBy",
          value: store.state.session.email,
          currentPage: 1,
          itemsPerPage: 100,
        })
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching activity feed";
    }

    return _response;
  },

  async getFileList(workspaceId, currentPage, itemsPerPage) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `${newWorkspaceURL}/workspace/loadItemsByFilter/${workspaceId}`,
        JSON.stringify({
          type: "file",
          currentPage,
          itemsPerPage,
        })
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching files";
    }

    return _response;
  },

  async getTaskList(workspaceId, currentPage, itemsPerPage) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `${newWorkspaceURL}/workspace/loadItemsByFilter/${workspaceId}`,
        JSON.stringify({
          type: "task",
          currentPage,
          itemsPerPage,
        })
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching tasks";
    }

    return _response;
  },

  async deleteChatItem(workspaceId, itemId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `${newWorkspaceURL}/workspace/deleteMessage/${workspaceId}`,
        JSON.stringify({
          itemId,
          isDeleted: 1,
        })
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error deleting item";
    }

    return _response;
  },
};
